import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { NgProgressModule } from 'ngx-progressbar';

import { LoadingService } from './loading.service';
import { LoadingInterceptor } from './loading.interceptor';
import { LoadingComponent } from './loading.component';

@NgModule({
    imports: [
        CommonModule,
        NgProgressModule
    ],
    providers: [
        LoadingService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoadingInterceptor,
            multi: true
        }
    ],
    declarations: [
        LoadingComponent
    ],
    exports: [
        LoadingComponent
    ],
})
export class LoadingModule {}
