import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthenticationModule, LoadingModule, FileUploadService } from './shared';
import { NgEventBus } from '../lib/eventbus';

import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,

        /* App Specifics */
        AppRoutingModule,
        LoadingModule,
        AuthenticationModule,
    ],
    providers: [
    /* App Specifics */
        NgEventBus,
        FileUploadService,
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {
                appearance: 'fill',
                hideRequiredMarker: true,
            }
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
