import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { mergeMap } from 'rxjs/operators';

import { EVENT_BUS_EVENTS, formatDate } from '@app/shared';
import { Note } from '@app/shared/models';
import { NotificationService } from '../../dialog';
import { NotesService } from './notes.service';
import { AuthenticationService } from '../../authentication';
import { NgEventBus } from '@lib/eventbus';
import { Subscription } from 'rxjs';


@Component({
    selector: 'aw-notes',
    templateUrl: './notes.component.html',
    styles: [`
    mat-card-content {
      white-space: pre-wrap;
    }
  `]
})
export class NotesComponent implements OnInit, OnDestroy {
  @Input() model: Note;
  date = new Date();
  modelName: string;
  notes: Note[] = [];
  text: string;
  isLoading: boolean;
  reloadNotesSub: Subscription;

  constructor(
    private notesService: NotesService,
    private authenticationService: AuthenticationService,
    private notificationService: NotificationService,
    private eventBus: NgEventBus
  ) {}

  ngOnInit(): void {
      this.modelName = this.model.resourceName;
      this.isLoading = true;

      this.notesService.findByItemId(this.modelName, (this.model.resourceName !== 'fraudAlerts') ? this.model.id : this.model.sk)
          .subscribe((notes: Note[]) => {
              this.notes = notes;
              this.isLoading = false;
              this.listenReloadNotesEvent();
          });
  }

  formatDate(date: Date): string {
      return formatDate(date);
  }

  listenReloadNotesEvent(): void {
      if (this.reloadNotesSub) return;

      this.reloadNotesSub = this.eventBus.on(EVENT_BUS_EVENTS.RELOAD_NOTES).subscribe(({ data }) => {
          this.ngOnInit();
      });
  }

  ngOnDestroy(): void {
      this.reloadNotesSub.unsubscribe();
  }

  createNote(text): void {
      this.authenticationService.getCognitoSub()
          .pipe(
              mergeMap(sub => this.notesService.create({
                  text,
                  userId: sub,
                  modelName: this.modelName,
                  itemId: (this.model.resourceName !== 'fraudAlerts') ? this.model.id : this.model.sk
              }))
          )
          .subscribe(
              (note: Note) => {
                  this.notes.unshift(note);
                  this.text = '';
              },
              errorMessage => this.notificationService.openWarn(errorMessage)
          );
  }
}
