<form class="dynamic-form row align-items-center" *ngIf="filterFields && filterFields.length" [formGroup]="filterForm">

    <ng-container *ngFor="let field of filterFields"
                  awDynamicField
                  [field]="field"
                  [group]="filterForm">
    </ng-container>

    <ng-content select=".list-export-button"></ng-content>

    <a (click)="onReset()" *ngIf="isFiltered && visibleFieldNames.length" class="mat-mdc-button mat-button-light-border">
      Reset
    </a>

    <ng-content select=".list-refresh-button"></ng-content>
    <ng-content select=".list-total-count"></ng-content>

</form>
