import {
    FIRM_STATUSES,
    getFirmFieldDecrypted,
    MARKETPLACE_ORDER_STATUSES
} from '../constants';
import { Model, Resource, modelConfig, modelRelations, Field } from '../data';
import { BankingPartnersRow } from '../services/banking-partners.service';
import { MarketplaceOrder } from './marketplace-order.model';

type PrimaryAttorney = {
    id: string;
    firstName: string;
    lastName: string;
    dob?: string; // we don't return DOB from backend for readonly users
    dobIsProvided: boolean;
    ssn?: string; // we don't return SSN from backend for readonly users
    ssnIsProvided: boolean;
}
export class Firm extends Model {
    resourceName: Resource = 'firm';

    accountType: string;
    addressCity: string;
    addressState: string;
    addressStreet: string;
    addressZip: string;
    archived: boolean;
    archivedDate: string;
    businessType: string;
    businessSubType: string;
    incorporationDate: string;
    incorporationState: string;
    companyTaxId?: string; // we don't return companyTaxId from backend for readonly users
    companyTaxIdIsProvided: boolean;
    didAgreeToTos: boolean;
    dob: string;
    email: string;
    website: string;
    featureSet: string;
    firmInsights: object;
    id: string;
    logo: string;
    name: string;
    dbaName: string;
    notifications: object;
    ownerId: string;
    partnerId: string;
    partnerName: string;
    bankingPartnerName: string;
    paymentPlatformCredentials: object;
    paymentPlatformStatuses: object;
    phone: string;
    primaryAttorneyId: string;
    referredByFirmId: string;
    registrationDate: string;
    ssn: object;
    status: string;
    type: string;
    averageTransactionAmountInCents: string;
    highestTransactionAmountInCents: string;
    monthlyTransactionVolumeInCents: string;

    extraSettings: {
        id: string;
        rapidconnectMid: string;
    };

    primaryAttorney: PrimaryAttorney;

    createdAt: Date;
    updatedAt: Date;
    approvedOn: Date;

    // computed field
    latestMarketplaceOrders: Record<string, MarketplaceOrder>;
    marketplaceOrdersRequestFailed: boolean;

    // deprecated fields (not displayed)
    membershipId: number;
    bankingPartnerList: BankingPartnersRow[];

    constructor(attributes = {}) {
        super(attributes);
        super.update(attributes);

        this.computedFields = [
            'latestMarketplaceOrder',
            'marketplaceOrdersRequestFailed'
        ];

        this.overviewFieldsFn = () => [
            'id',
            'accountType',
            {
                label: 'Partner',
                name: 'partnerName',
                transformer: <Firm>(value, model) => `<a href='/crm/partners/${model.partnerId}'>${model.partnerName}</a>`
            },
            {
                label: 'Firm Name',
                name: 'name'
            },
            {
                label: 'DBA Name',
                name: 'dbaName'
            },
            'email',
            {
                label: 'Business Phone',
                name: 'phone'
            },
            {
                label: 'Projected Monthly Credit Card Transaction Volume',
                name: 'monthlyTransactionVolumeInCents',
                transformer: (value) => `$${value}`
            },
            {
                label: 'Projected Largest Individual Credit Card Transaction Size',
                name: 'highestTransactionAmountInCents',
                transformer: (value) => `$${value}`
            },
            {
                label: 'Projected Average Individual Credit Card Transaction Size',
                name: 'averageTransactionAmountInCents',
                transformer: (value) => `$${value}`
            },
            {
                name: 'website'
            },
            {
                name: 'fullAddress',
                transformer: 'address'
            },
            {
                label: 'Business Type',
                name: 'businessType',
                cssClasses: 'margin-top-30'
            },
            'businessSubType',
            {
                label: 'Date of incorporation',
                name: 'incorporationDate'
            },
            {
                label: 'State of incorporation',
                name: 'incorporationState',
            },
            {
                label: 'Firm Size',
                name: 'firmInsights',
                transformer: (value) => value && value.firmSize,
                cssClasses: 'margin-top-30'
            },
            {
                label: 'Firm Industry',
                name: 'industry',
            },
            {
                label: 'Practice Area',
                name: 'firmInsights',
                transformer: (value) => value && value.practiceArea
            },
            {
                label: 'Monthly Invoice',
                name: 'firmInsights',
                transformer: (value) => value && value.monthlyInvoice
            },
            {
                label: 'Primary Attorney',
                name: 'primaryAttorney',
                cssClasses: 'margin-top-30',
                transformer(primaryAttorney) {
                    const primaryAttorneyName = (primaryAttorney.firstName || primaryAttorney.lastName)
                        ? `${primaryAttorney.firstName} ${primaryAttorney.lastName}`
                        : `${primaryAttorney.id}`;

                    return `<a href='/crm/profiles/${primaryAttorney.id}'>${primaryAttorneyName}</a>`;
                }
            },
            {
                label: 'Tax ID/EIN',
                name: 'companyTaxId',
                stayVisible: true,
                transformer: <Firm>(companyTaxId, firm) => {
                    if (firm.companyTaxId || firm.companyTaxIdIsProvided) return 'PROVIDED';
                    return '-';
                }
            },
            'status',
            'createdAt',
            'didAgreeToTos',
            {
                label: 'Banking Partners:',
                isSectionHeader: true,
                cssClasses: 'underline',
                name: 'banking-partners'
            },
            ...this.getBankingPartnersFields(),
            {
                label: 'Marketplace Application Status',
                name: 'marketplaceOrdersRequestFailed',
                hideLabel: true,
                transformer(_value) {
                    if (_value !== true) return;

                    return `<div class="error-message">
                    Cannot show Marketplace Application Status because Marketplace API request failed.
                    Please, contact the Engineering team if the issue persists.
                    </div>`;
                }
            },
            {
                label: 'Relations:',
                isSectionHeader: true,
                cssClasses: 'underline',
                name: ''
            },
        ];

        this.config = modelConfig([
            {
                type: 'input',
                name: 'id',
                readonly: true
            },
            {
                type: 'input',
                name: 'partnerId',
                readonly: true
            },
            {
                label: 'Firm Name',
                type: 'input',
                name: 'name'
            },
            {
                label: 'DBA Name',
                type: 'input',
                name: 'dbaName'
            },
            {
                type: 'select',
                name: 'accountType',
                options: ['NORMAL', 'TEST']
            },
            {
                type: 'input',
                name: 'email'
            },
            {
                label: 'Business Phone',
                type: 'input',
                name: 'phone'
            },
            {
                type: 'input',
                name: 'website'
            },
            {
                label: 'Firm Projected Monthly Credit Card Transaction Volume (1000.00 - 10,000,000)',
                type: 'input',
                name: 'monthlyTransactionVolumeInCents',
                pattern: '^(([1-9]{1,1}[0-9]{3,6}([.][0-9]{1,2})?)|10000000)$',
                prefix: 'USD'
            },
            {
                label: 'Firm Projected Largest Individual Credit Card Transaction Size (1000.00 - 100,000)',
                type: 'input',
                name: 'highestTransactionAmountInCents',
                pattern: '^(([1-9]{1,1}[0-9]{3,4}([.][0-9]{1,2})?)|100000)$',
                prefix: 'USD'
            },
            {
                label: 'Firm Projected Average Individual Credit Card Transaction Size (1.00 - 99,999)',
                type: 'input',
                name: 'averageTransactionAmountInCents',
                pattern: '^(?!99999[.])[1-9][0-9]{0,4}(?:[.][0-9]{0,2})?$',
                prefix: 'USD'
            },
            {
                label: 'Street',
                type: 'input',
                name: 'addressStreet',
                cssClasses: 'margin-top-30'
            },
            {
                label: 'City',
                type: 'input',
                name: 'addressCity'
            },
            {
                label: 'State',
                type: 'input',
                name: 'addressState'
            },
            {
                label: 'Zip',
                type: 'input',
                name: 'addressZip'
            },
            // {
            //   type: 'select',
            //   name: 'type',
            //   options: ['ATTORNEY', 'CLIENT']
            // },
            {
                type: 'select',
                name: 'businessType',
                options: ['PERSONAL', 'BUSINESS']
            },
            {
                type: 'select',
                name: 'businessSubType',
                options: [
                    'SOLE_PROPRIETORSHIP',
                    'LP',
                    'LLP',
                    'LLLP',
                    'LLC',
                    'PLC',
                    'PLLC',
                    'CORPORATION',
                    'PC',
                    'C_CORPORATION',
                    'S_CORPORATION',
                    'B_CORPORATION'
                ]
            },
            {
                type: 'select',
                name: 'status',
                options: Object.keys(FIRM_STATUSES),
                cssClasses: 'margin-top-30'
            },
            {
                type: 'input',
                name: 'referredByFirmId',
                readonly: true
            },
            {
                label: 'Incorporation date (YYYY-MM or YYYY-MM-DD)',
                type: 'input',
                name: 'incorporationDate',
                cssClasses: 'margin-top-30'
            },
            {
                type: 'input',
                name: 'incorporationState'
            },
            {
                type: 'select',
                name: 'industry',
                options: {
                    url: '/firms/industries'
                }
            },
            {
                type: 'encrypted',
                name: 'companyTaxId',
                label: 'Tax ID/EIN',
                getDecrypted: this.getCompanyTaxIdDecryptedAsync
            },
            {
                label: 'Primary Attorney',
                type: 'select',
                name: 'primaryAttorneyId',
                options: {
                    url: `/profiles/names?firmId=${this.id}&allowArchived=true`
                },
                hint: `To view or edit primary attorney SSN and DOB visit the attorney page`
            },
            // this is not used:
            // {
            //   type: 'datepicker',
            //   name: 'registrationDate'
            // },
            // {
            //   type: 'select',
            //   name: 'featureSet',
            //   options: ['FAST_PAY', 'FULL']
            // },
            {
                type: 'json-group',
                name: 'firmInsights',
                group: [
                    {
                        type: 'input',
                        name: 'firmSize',
                        cssClasses: 'col-sm-4',
                    },
                    {
                        label: 'Firm Size (for Salesforce)',
                        type: 'select',
                        name: 'firmSizeSalesforce',
                        cssClasses: 'col-sm-4',
                        options: [
                            '1',
                            '2-9',
                            '10-29',
                            '30-49',
                            '50-99',
                            '100+'
                        ]
                    },
                    {
                        type: 'input',
                        name: 'practiceArea',
                        cssClasses: 'col-sm-4',
                    },
                    {
                        type: 'select',
                        name: 'monthlyInvoice',
                        cssClasses: 'col-sm-4',
                        options: [
                            'Under $10,000 / month',
                            '$10,000 - $25,000 / month',
                            '$25,000 - $50,000 / month',
                            '$50,000 - $100,000 / month',
                            'Over $100,000 / month'
                        ]
                    },
                ]
            },
            {
                type: 'checkbox',
                name: 'didAgreeToTos',
            },
            {
                type: 'json',
                name: 'notifications',
                readonly: true,
                editable: false,
                additional: true
            },
            {
                type: 'json',
                name: 'paymentPlatformCredentials',
                editable: false,
                additional: true
            },
            {
                type: 'json',
                name: 'paymentPlatformStatuses',
                editable: false,
                additional: true
            },
            // these are not used anymore:
            // {
            //   type: 'select',
            //   name: 'saasPlanType',
            //   options: ['FREE', 'MONTHLY', 'ANNUAL', 'FOUNDER']
            // },
            {
                type: 'input',
                name: 'createdAt',
                readonly: true,
                additional: true
            },
            {
                type: 'input',
                name: 'updatedAt',
                readonly: true,
                additional: true
            },
            {
                type: 'input',
                name: 'approvedOn',
                readonly: true,
                additional: true
            },
            {
                type: 'input',
                name: 'archivedDate',
                readonly: true,
                additional: true
            }
        ]);
        this.relations = modelRelations([
            {
                crmModule: 'clients',
                params: { firmId: this.id },
                resource: 'client'
            },
            // {
            //   crmModule: 'invoices',
            //   params: { firmId: this.id },
            //   resource: 'invoice'
            // },
            {
                crmModule: 'transactions',
                params: { firmId: this.id },
                resource: 'transaction'
            },
            {
                crmAlias: 'Batch Transactions',
                crmModule: 'nacha-transactions',
                params: { firmId: this.id },
                resource: 'nachaTransaction'
            },
            {
                crmModule: 'profiles',
                crmAlias: 'Attorneys',
                params: { firmId: this.id },
                resource: 'profile'
            },
            {
                crmModule: 'profiles',
                crmAlias: 'Primary Attorney',
                params: this.primaryAttorneyId,
                resource: 'profile'
            },
            {
                crmModule: 'banks',
                params: { firmId: this.id },
                resource: 'bank'
            },
            {
                crmModule: 'firm-documents',
                crmAlias: 'Documents',
                params: { firmId: this.id },
                resource: 'firmDocument'
            },
            {
                crmModule: 'firm-promos',
                params: { firmId: this.id },
                resource: 'firmPromo'
            },
            {
                crmModule: 'firm-fees',
                params: { firmId: this.id },
                resource: 'firmFee'
            },
            {
                crmModule: 'firm-settings',
                params: this.extraSettings ? this.extraSettings.id : null,
                hidden: !this.extraSettings,
                resource: 'firmSettings'
            },
            {
                crmModule: 'recurring-payments',
                params: { firmId: this.id },
                resource: 'recurringPayment'
            },
            {
                crmModule: 'audit-logs',
                params: { entity_type: 'firm', entity_id: this.id },
                resource: 'auditLog'
            },
            {
                crmModule: 'marketplace-orders',
                params: { firmId: this.id },
                resource: 'marketplaceOrder'
            },
            {
                crmModule: 'firm-issues',
                params: { firmId: this.id },
                resource: 'firmIssue'
            },
            {
                crmModule: 'firstdata-transactions',
                crmAlias: 'Firstdata Transactions',
                params: {
                    merchant_id: this.extraSettings && this.extraSettings.rapidconnectMid
                },
                resource: 'firstdataTransaction',
                hidden: () => !(this.extraSettings && this.extraSettings.rapidconnectMid)
            },
            {
                crmModule: 'card-profiles',
                params: { firmId: this.id },
                resource: 'cardProfile'
            },
            {
                crmModule: 'fraud-alerts',
                params: { firmId: this.id },
                resource: 'fraudAlerts'
            },
            {
                crmAlias: 'Bank Transactions',
                crmModule: 'headnote-banks/all/transactions',
                params: { firm_id: this.id },
                resource: 'headnoteBanks',
            },
            {
                crmAlias: 'SVB Reconciliation Report',
                crmModule: 'headnote-banks/svb_operating_fbo/reconciliation-report',
                params: { firm_id: this.id, headnote_bank_account_id: 'svb_operating_fbo' },
                resource: 'reconciliationReport',
            }

        ]);
        this.primaryRelations = [
            'bank',
            'profile',
            'client',
            'transaction',
            'firmSettings',
            'recurringPayment',
        ];
    }

    get detailsDisplayName(): string {
        return `Firm: ${this.name}`;
    }

    get fullAddress(): string {
        return `${this.addressStreet || ''} ${this.addressCity || ''}` +
            `${this.addressState ? `, ${this.addressState}` : ''}` +
            `${this.addressZip ? ` ${this.addressZip}` : ''}`;
    }

    get getCompanyTaxIdDecryptedAsync(): any {
        return (token) => getFirmFieldDecrypted(token, this.id, 'companyTaxId');
    }

    /**
     * Returns list of fields to see status of banking partners
     */
    getBankingPartnersFields() {
        return this.bankingPartnerList.map(bankingPartner => ({
            // remove "(Globally Disabled)" because it will be in right column
            label: bankingPartner.label.replace(' (Globally Disabled)', ''),
            name: 'banking-partner-' + bankingPartner.value,
            stayVisible: true,
            transformer: (_value, firmModel) => {

                const latestOrder = (firmModel.latestMarketplaceOrders?.[bankingPartner.value] || [])[0];

                if (firmModel.marketplaceOrdersRequestFailed) return;

                if (!bankingPartner.globally_enabled) {
                    const link = `
                        <a href='/crm/marketplace-orders;firmId=${this.id};bankingPartnerId=${bankingPartner.value}'>
                            (Globally Disabled)
                        </a>
                        `;
                    const innerContent = latestOrder ? link : '(Globally Disabled)';

                    return `
                        ${innerContent}
                    `;
                }

                // we don't show create button if latestMarketplaceOrder exists and is not CANCELED
                const orderExists = (latestOrder && latestOrder.order_status !== MARKETPLACE_ORDER_STATUSES.CANCELED);

                if (orderExists) {
                    return `
                        <div>
                            <a href='/crm/marketplace-orders/${latestOrder.id}'>${latestOrder.order_status}</a>
                        </div>
                    `;
                }

                const createNewOrderButton = `
                    <a
                        href="/crm/marketplace-orders/create/${firmModel.id}?bankingPartnerId=${bankingPartner.value}"
                        class="mat-mdc-button mat-button-dark-border mat-mdc-button__link"
                        tabindex="0"
                    >Create Application
                    </a>`;

                return `<div><span>${createNewOrderButton}</span></div>`;
            }
        }));
    }

    static filterFields(): Field[] {
        return modelConfig([
            {
                type: 'select',
                name: 'partnerId',
                label: 'Filter by Partner',
                cssClasses: 'col-sm-4 min-width-200',
                options: {
                    url: '/partners/names',
                    default: {'': 'All Partners'}
                }
            },
            {
                type: 'select',
                name: 'status',
                label: 'Filter firms by status',
                cssClasses: 'col-sm-6 min-width-400',
                options: FIRM_STATUSES
            },
            {
                type: 'datepicker',
                name: 'createdFrom',
                cssClasses: 'filter-date-input',
            },
            {
                type: 'datepicker',
                name: 'createdTo',
                cssClasses: 'filter-date-input'
            }
        ]);
    }
}
