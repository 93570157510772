import { Model, Resource, modelConfig, modelRelations } from '../data';

export class ClientBankAccount extends Model {
    resourceName: Resource = 'clientBankAccount';

    id: string;
    clientId: string;
    archived: boolean;
    name: string;
    last4: string;
    createdAt: Date;
    updatedAt: Date;

    credentials: object;

    client: {
        id: string;
        firstName: string;
        lastName: string;
    };

    constructor(attributes = {}) {
        super(attributes);
        super.update(attributes);


        this.overviewFields = [
            'id',
            {
                label: 'Client Name',
                name: 'name',
                transformer: <ClientBankAccount>(value, model) => `<a href='/crm/clients/${model.client.id}'>` +
                        ` ${model.client.firstName} ${model.client.lastName}</a>`
            },
            'name',
            'last4',
            'archived',
            'createdAt',
            'updatedAt'
        ];
        this.config = modelConfig([
            {
                type: 'input',
                name: 'id',
                readonly: true
            },
            {
                type: 'input',
                name: 'name',
                readonly: true
            },
            {
                type: 'input',
                name: 'last4',
                readonly: true
            },
            {
                type: 'checkbox',
                name: 'archived',
                readonly: true
            },
            {
                type: 'json',
                name: 'credentials',
                editable: false,
                additional: true
            },
            {
                type: 'input',
                name: 'createdAt',
                readonly: true,
                additional: true
            },
            {
                type: 'input',
                name: 'updatedAt',
                readonly: true,
                additional: true
            }
        ]);
        this.relations = modelRelations([
            {
                crmModule: 'client-bank-accounts',
                params: { clientId: this.clientId },
                resource: 'clientBankAccount'
            },
            {
                crmModule: 'audit-logs',
                params: { entity_type: 'client_bank_account', entity_id: this.id },
                resource: 'auditLog'
            },
            {
                crmModule: 'transactions',
                params: { clientBankAccountId: this.id },
                resource: 'transaction'
            },
        ]);
    }

    get detailsDisplayName(): string {
        return `Client Bank Account`;
    }
}
