import { Model, Resource, modelConfig, modelRelations, Field } from '../data';
import { ALLOWED_DOCUMENT_EXTENSIONS } from '../constants';
import { getCurrentTimezone} from '@app/shared';

export class FirmDocument extends Model {
    resourceName: Resource = 'firmDocument';

    id: string;
    firmId: string;
    bankId: string;
    profileId: string;
    filename: string;
    originalFilename: string;
    category: string;

    documentViewUrl: string;

    createdBy: string;
    archived: boolean;
    createdAt: Date;
    updatedAt: Date;

    firm: {
        id: string,
        name: string,
        email: string,
        partnerId: string
        partnerName: string;
    };

    profile?: {
        firstName: string;
        lastName: string;
    };

    bank?: {
        friendlyName: string;
    };

    categories?: {
        [key: string]: string;
    };

    get detailsDisplayName(): string {
        return `Firm "${this.firm.name}" Document`;
    }

    get fileType(): string {
        return this.filename.toUpperCase().split('.').pop();
    }

    constructor(attributes = {}) {
        super(attributes);
        super.update(attributes);

        this.overviewFields = [
            'id',
            {
                label: 'Firm',
                name: 'firmId',
                transformer: (value, model: any) => `<a href='/crm/firms/${model.firm.id}'>${model.firm.name}</a>`
            },
            {
                label: 'Profile',
                name: 'profileId',
                transformer: (value, model: any) => {
                    if (!model.profileId) return;
                    if (!model.profile) return;
                    return `<a href='/crm/profiles/${model.profileId}'>${model.profile.firstName} ${model.profile.lastName}</a>`;
                }
            },
            {
                label: 'Bank',
                name: 'bankId',
                transformer: (value, model: any) => {
                    if (!model.bankId) return;
                    if (!model.bank) return;
                    return `<a href='/crm/banks/${model.bankId}'>${model.bank.friendlyName}</a>`;
                }
            },
            {
                name: 'category',
                transformer: (value, model: any) => {
                    if (model.categories && model.categories[value]) {
                        return model.categories[value];
                    }
                    return value;
                }
            },
            'fileType',
            {
                name: 'filename',
                transformer: (value, model: any) => {
                    if (!model.documentViewUrl) {
                        return model.filename;
                    }
                    return `<a href='${model.documentViewUrl}' target="_blank">${model.filename}</a>`;
                }
            },
            'originalFilename',
            'createdBy',
            {
                name: 'archived',
                transformer: (archived) => archived ? 'YES' : '-'
            },
            'createdAt',
            {
                name: 'updatedAt',
                transformer: 'formatDate'
            },
            {
                label: 'Preview',
                name: 'documentViewUrl',
                hideLabel: true,
                isFilePreview: true,
                hide: (model: any) => !model.documentViewUrl
            }
        ];
        this.config = modelConfig([
            {
                type: 'input',
                name: 'id',
                readonly: true
            },
            {
                type: 'input',
                name: 'firmId',
                readonly: true
            },
            {
                type: 'select',
                name: 'profileId',
                options:  {
                    url: `/profiles/names?firmId=${this.firmId}&allowArchived=true`,
                    default: {'null': '---'}
                }
            },
            {
                type: 'select',
                name: 'bankId',
                options: {
                    url: `/banks/names?firmId=${this.firmId}&allowArchived=true&userTimezone=` + getCurrentTimezone(),
                    default: {'null': '---'}
                }
            },
            {
                type: 'select',
                name: 'category',
                options: {
                    url: '/firm-documents/categories'
                }
            },
            {
                type: 'input',
                name: 'filename',
                readonly: true
            },
            {
                type: 'input',
                name: 'originalFilename'
            },
            {
                type: 'select',
                name: 'createdBy',
                options: ['partner', 'admin']
            },
            {
                type: 'checkbox',
                name: 'archived'
            },
            {
                type: 'input',
                name: 'createdAt',
                readonly: true,
                additional: true
            },
            {
                type: 'input',
                name: 'updatedAt',
                readonly: true,
                additional: true
            }
        ]);
        this.configCreate = modelConfig([
            {
                type: 'input',
                name: 'firmId',
                readonly: true,
                required: true
            },
            {
                type: 'select',
                name: 'profileId',
                options: {
                    url: `/profiles/names?firmId=${this.firmId}&allowArchived=true`,
                    default: {'null': '---'}
                }
            },
            {
                type: 'select',
                name: 'bankId',
                options: {
                    url: `/banks/names?firmId=${this.firmId}&allowArchived=true&userTimezone=` + getCurrentTimezone(),
                    default: {'null': '---'}
                }
            },
            {
                type: 'select',
                name: 'category',
                options: {
                    url: '/firm-documents/categories'
                },
                required: true
            },
            {
                type: 'file',
                name: 'file',
                maxFileSizeInBytes: 40 * 1024 * 1024,
                allowedExtensions: ALLOWED_DOCUMENT_EXTENSIONS,
                hint: `Max file size: 40MB. Allowed: ${ALLOWED_DOCUMENT_EXTENSIONS}`,
                required: true
            }
        ]);
        this.relations = modelRelations([
            {
                crmModule: 'firms',
                crmAlias: 'firm',
                params: this.firmId,
                resource: 'firm'
            },
            {
                crmModule: 'firm-documents',
                params: { firmId: this.firmId },
                resource: 'firmDocument'
            },
            {
                crmModule: 'audit-logs',
                params: { entity_type: 'firm_document', entity_id: this.id },
                resource: 'auditLog'
            },
        ]);
    }

    static filterFields(): Field[] {
        return modelConfig([
            {
                type: 'select',
                name: 'category',
                label: 'Category',
                cssClasses: 'col-sm-4 min-width-200',
                options: {
                    url: '/firm-documents/categories',
                    default: {'': 'All'}
                }
            },
            {
                type: 'select',
                name: 'createdBy',
                label: 'Created By',
                cssClasses: 'col-sm-4 min-width-200',
                options: {
                    '': 'Everyone',
                    'admin': 'admin',
                    'partner': 'partner'
                }
            },
        ]);
    }
}
