import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';

import { PageHeadingModule } from '../page-heading';
import { ListComponent } from './list.component';
import { ListFilterComponent } from './list-filter/list-filter.component';
import { DynamicFormModule } from '../dynamic-form';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        MatTableModule,
        MatPaginatorModule,
        MatInputModule,
        MatCardModule,
        MatProgressSpinnerModule,
        PageHeadingModule,
        DynamicFormModule,
        FormsModule,
        ReactiveFormsModule,
        MatTooltipModule
    ],
    declarations: [
        ListFilterComponent,
        ListComponent
    ],
    exports: [ListFilterComponent, ListComponent],
})
export class ListModule { }
