/**
 * Format amount or total of transaction or recurring payment
 * If currency is USD it returns $12.34
 * If currency is some other, for example CAD, it returns CAD 12.34
 */
export function formatAmount(value: number | string, currencyCode: string): string {
    if (value === '0.00') return value;
    if (value === '') return value;

    const amount = (typeof value === 'string') ? parseFloat(value.replace(',', '')) : value;
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: currencyCode }).format(amount);
}

/**
 * Convert transaction total to USD
 *
 * @param total - decimal, like 100.12
 * @param usdExchangeRate - decimal, like 0.87563
 */
export function convertToUSD(total, usdExchangeRate) {
    const totalInCents = total * 100;
    const totalInUSDCents = Math.round(totalInCents / usdExchangeRate);
    return (totalInUSDCents / 100).toFixed(2);
}

/**
 * Convert amount_in_cents to main currency
 * If currency is 1234 USD it returns $12.34
 * If currency is some other, for example 123 UAH, it returns UAH 1.23
 */
export function convertFromCents(value: number | string, currencyCode: string = 'USD'): string {
    if (value === '0.00') return value;
    if (value === '') return value;

    const amount = (typeof value === 'string') ? parseInt(value, 10) : value;
    return formatAmount(amount / 100, currencyCode);
}

/**
 * Returns amount formatted as "$(123,45)" because it's negative
 */
export function formatNegativeAmount(value: string): string {
    return `$(${value.substring(1)})`;
}
