import { Model, Resource, modelConfig, modelRelations, Field } from '../data';
import { INetworkTokenEnrollmentAttemptResponse } from '../interfaces';

interface NetworkTokenEnrollmentResponse {
    card: {
        bin: string;
        last4: string;
    };
    network_token: {
        id: string;
        state: string;
        created_at: string;
        expires_at: string;
        updated_at: string;
    };
}

export class CardProfile extends Model {
    resourceName: Resource = 'cardProfile';

    id: string;
    clientId: string;
    cardholderName: string;
    status: string;
    reason: string;
    cardType: string;
    cardDebitCredit: string;
    cardExpDate: string;
    cardBin: string;
    cardLast4: string;
    cardZipcode: string;
    uniqueId: string;

    client?: {
        id: string;
        firstName: string;
        lastName: string;
    };

    network_token_enrollment_status: string;
    network_token_enrollment_attempt_count: number;
    network_token_enrollment_error: string;
    network_token_enrollment_last_attempt_date: Date;
    network_token_enrollment_response: NetworkTokenEnrollmentResponse;
    network_token_enrollment_attempts: INetworkTokenEnrollmentAttemptResponse[];
    createdAt: Date;
    updatedAt: Date;

    get detailsDisplayName() {
        return `Card Profile ${this.id}`;
    }

    constructor(attributes = {}) {
        super(attributes);
        super.update(attributes);
        this.overviewFields = [
            {
                name: 'id'
            },
            {
                label: 'Client',
                name: 'clientId',
                transformer: <CardProfile>(value, model) =>
                    `<a href='/crm/clients/${model.client.id}'>` + ` ${model.client.firstName} ${model.client.lastName}</a>`,
                hide: (model: CardProfile) => !model.client
            },
            {
                label: 'Cardholder Name',
                name: 'cardholderName'
            },
            {
                label: 'Card Brand',
                name: 'cardType'
            },
            {
                name: 'cardDebitCredit',
                label: 'Card Type',
                stayVisible: true,
                cssClasses: 'bold',
                transformer: (value) => value || 'Cannot detect card type. BIN file lookup has failed'
            },
            {
                name: 'cardExpDate',
                transformer: (value) => value.slice(0, 2) + '/' + value.slice(-2)
            },
            'cardBin',
            'cardLast4',
            'cardZipcode',
            {
                name: 'status',
                cssClasses: 'bold'
            },
            {
                name: 'reason'
            },
            'createdAt',
            {
                name: 'updatedAt',
                transformer: 'formatDate'
            },
            {
                label: 'Network Token Enrollment Details',
                isSectionHeader: true,
                name: 'network_token_enrollment'
            },
            {
                name: 'network_token_enrollment_status',
                cssClasses: 'bold'
            },
            {
                name: 'network_token_enrollment_error'
            },
            {
                name: 'network_token_enrollment_attempt_count',
                hide: (model: CardProfile) => model.network_token_enrollment_attempt_count === 0
            },
            {
                name: 'network_token_enrollment_last_attempt_date',
                transformer: 'formatDate'
            },
            {
                label: 'Enrolled Card Number Token',
                name: 'network_token_enrollment_response',
                transformer: (value: NetworkTokenEnrollmentResponse) => value && value.network_token && value.network_token.id,
                hide: (model: CardProfile) => model.network_token_enrollment_status !== 'SUCCESSFUL'
            }
        ];

        this.config = modelConfig([
            {
                type: 'input',
                name: 'id',
                readonly: true
            },
            {
                type: 'input',
                name: 'clientId',
                readonly: true
            },
            {
                type: 'input',
                name: 'cardholderName',
                readonly: true
            },
            {
                type: 'select',
                name: 'status',
                options: {
                    url: '/card-profile/statuses'
                }
            },
            {
                type: 'select',
                name: 'reason',
                options: {
                    url: '/card-profile/reasons'
                }
            },
            {
                type: 'input',
                name: 'cardType',
                label: 'Card Brand',
                readonly: true
            },
            {
                type: 'input',
                name: 'cardDebitCredit',
                label: 'Card Type',
                readonly: true
            },
            {
                type: 'input',
                name: 'cardExpDate',
                readonly: true
            },
            {
                type: 'input',
                name: 'cardLast4',
                readonly: true
            },
            {
                type: 'input',
                name: 'cardZipcode',
                readonly: true
            },
            {
                type: 'input',
                name: 'uniqueId',
                readonly: true
            },
            {
                type: 'input',
                name: 'createdAt',
                readonly: true
            },
            {
                type: 'input',
                name: 'updatedAt',
                readonly: true
            },
            {
                label: 'Network Token Enrollment Details',
                type: 'section-header',
                name: 'network_token_enrollment'
            },
            {
                type: 'select',
                name: 'network_token_enrollment_status',
                options: {
                    url: '/card-profile/network-token-enrollment-statuses'
                }
            },
            {
                type: 'input',
                name: 'network_token_enrollment_error'
            },
            {
                type: 'input',
                name: 'network_token_enrollment_attempt_count'
            },
            {
                type: 'input',
                name: 'network_token_enrollment_last_attempt_date'
            },
            {
                type: 'json',
                name: 'network_token_enrollment_response',
                editable: true
            },
            {
                type: 'json',
                name: 'network_token_enrollment_attempts',
                editable: true
            }
        ]);
        this.relations = modelRelations([
            {
                crmModule: 'audit-logs',
                params: { entity_type: 'card_profile', entity_id: this.id },
                resource: 'auditLog'
            },
            {
                crmModule: 'transactions',
                params: { cardProfileId: this.id },
                resource: 'transaction'
            },
            {
                crmModule: 'client-cards',
                params: { cardProfileId: this.id },
                resource: 'clientCard'
            },
            {
                crmAlias: 'client',
                crmModule: 'clients',
                params: this.clientId,
                resource: 'client'
            },
            {
                crmAlias: 'Similar Card Profiles',
                crmModule: 'card-profiles',
                params: {
                    uniqueId: '%:' + this.uniqueId.replace(this.clientId + ':', '')
                },
                resource: 'cardProfile'
            }
        ]);
    }

    static filterFields(statuses = {}, reasons = {}, cardTypes = {}): Field[] {
        return modelConfig([
            {
                type: 'select',
                name: 'cardDebitCredit',
                label: 'Card Type',
                cssClasses: 'col-sm-2 min-width-150',
                options: {
                    '': 'All',
                    CREDIT: 'CREDIT',
                    DEBIT: 'DEBIT',
                    null: 'BIN Lookup Failed'
                }
            },
            {
                type: 'select',
                name: 'cardType',
                label: 'Card Brand',
                cssClasses: 'col-sm-2 min-width-150',
                options: {
                    default: { '': 'All' },
                    url: '/card-profile/cardTypes'
                }
            },
            {
                type: 'select',
                name: 'status',
                label: 'Status',
                cssClasses: 'col-sm-2 min-width-150',
                options: {
                    default: { '': 'All' },
                    url: '/card-profile/statuses'
                }
            },
            {
                type: 'select',
                name: 'reason',
                label: 'Reason',
                cssClasses: 'col-sm-4 min-width-150',
                options: {
                    default: { '': 'All' },
                    url: '/card-profile/reasons'
                },
                hideIf: (values) => values.status !== 'INACTIVE'
            },
            {
                label: 'NT Enrollment Status',
                type: 'select',
                name: 'network_token_enrollment_status',
                options: {
                    default: { '': 'All' },
                    url: '/card-profile/network-token-enrollment-statuses'
                }
            },
            {
                type: 'input',
                name: 'cardBin',
                pattern: '^[0-9]{4,8}$',
                cssClasses: 'col-sm-4 min-width-150'
            },
            {
                type: 'input',
                name: 'cardLast4',
                pattern: '^[0-9]{4}$',
                cssClasses: 'col-sm-4 min-width-150'
            },
            {
                label: 'Card Exp Date (MM/YY)',
                type: 'input',
                name: 'cardExpDate',
                pattern: '^[0-9]{2}[/]?[0-9]{2}$',
                cssClasses: 'col-sm-4 min-width-150'
            }
        ]);
    }
}
