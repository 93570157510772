import { NgModule } from '@angular/core';
import {
    AuthModule,
    AUTH_SERVICE,
    PUBLIC_FALLBACK_PAGE_URI,
    PROTECTED_FALLBACK_PAGE_URI
} from 'ngx-auth';

import { AuthenticationService } from './authentication.service';
import { CognitoService } from './cognito.service';
import { ProfileGuard } from './profile.guard';
import { IfPermissionsGuard } from './if-permissions.guard';

export function factory(authenticationService: AuthenticationService) {
    return authenticationService;
}

@NgModule({
    imports: [
        AuthModule
    ],
    providers: [
        { provide: PROTECTED_FALLBACK_PAGE_URI, useValue: '/' },
        { provide: PUBLIC_FALLBACK_PAGE_URI, useValue: '/login' },
        {
            provide: AUTH_SERVICE,
            deps: [AuthenticationService],
            useFactory: factory
        },
        AuthenticationService,
        CognitoService,
        ProfileGuard,
        IfPermissionsGuard
    ]
})
export class AuthenticationModule {}
