import { getProfileFieldDecrypted, ALLOWED_IMAGE_EXTENSIONS } from '../constants';
import { Model, Resource, modelConfig, modelRelations } from '../data';

export class Profile extends Model {
    resourceName: Resource = 'profile';

    id: string;
    ownerId: string;
    firmId: string;
    firstName: string;
    lastName: string;
    dob?: string; // we don't return DOB from backend for readonly users
    dobIsProvided: boolean;
    email: string;
    phone: string;
    addressStreet: string;
    addressCity: string;
    addressState: string;
    addressZip: string;
    paymentPlatformCredentials: object;
    businessType: string;
    type: string;
    roles: object;
    isStakeholder: boolean;
    barNumber: string;
    barState: string;
    barCard: string;
    barCardBack: string;
    archived: boolean;
    archivedDate: Date;
    status: string;
    accessRead: boolean;
    accessWrite: boolean;
    accessDelete: boolean;
    createdBy: string;
    shortId: string;
    createdAt: Date;
    updatedAt: Date;
    ssn?: object; // we don't return SSN from backend for readonly users
    ssnIsProvided: boolean;
    personalPhone: string;
    ownership: number;
    title: string;
    isAuthorizedSignor: boolean;

    firm: {
        id: string,
        name: string,
        email: string,
        partnerId: string,
        partnerName: string
    };

    constructor(attributes = {}) {
        super(attributes);
        super.update(attributes);
        this.overviewFields = [
            'id',
            {
                label: 'Partner',
                name: 'firmId',
                transformer: <Profile>(value, model) => `<a href='/crm/partners/${model.firm.partnerId}'>${model.firm.partnerName}</a>`
            },
            {
                label: 'Firm Name',
                name: 'firmId',
                transformer: <Profile>(value, model) => model.firm.name
            },
            'firstName',
            'lastName',
            {
                label: 'Work Email',
                name: 'email'
            },
            {
                label: 'Work Phone',
                name: 'phone'
            },
            {
                label: 'Type',
                name: 'type'
            },
            {
                label: 'Status',
                name: 'status',
                transformer: (type) => `${type}<br/><br/>`
            },
            'title',
            'ownership',
            'personalPhone',
            {
                label: 'Personal Address',
                name: 'addressStreet',
                transformer: <Profile>(addressStreet, model) => `${model.addressStreet || ''} ${model.addressCity || ''}` +
                        `${model.addressState ? `, ${model.addressState}` : ''}` +
                        `${model.addressZip ? ` ${model.addressZip}` : ''}<br/><br/>`
            },
            {
                label: 'SSN',
                name: 'ssn',
                stayVisible: true,
                transformer: <Profile>(ssn, profile) => {
                    if (profile.ssn || profile.ssnIsProvided) return 'PROVIDED';
                    return '-';
                }
            },
            {
                label: 'Date of birth',
                name: 'dob',
                stayVisible: true,
                transformer: <Profile>(dob, profile) => {
                    if (profile.dob) return profile.dob; // admins and superadmins see DOB
                    if (profile.dobIsProvided) return 'PROVIDED'; // readonly users will see 'PROVIDED'
                    return '-';
                }
            },
            'barNumber',
            'barState',
            {
                name: 'barCard',
                transformer(url) {
                    if (!url) { return; }
                    return `<a target="_blank" href="${url}">View</a>`;
                }
            },
            {
                name: 'barCardExists',
                label: 'Bar Card',
                transformer: (value) => value ? 'PROVIDED' : '-'
            },
            {
                name: 'barCardBack',
                transformer(url) {
                    if (!url) { return; }
                    return `<a target="_blank" href="${url}">View</a>`;
                }
            },
            {
                name: 'barCardBackExists',
                label: 'Bar Card Back',
                transformer: (value) => value ? 'PROVIDED' : '-'
            },
            {
                name: 'isAuthorizedSignor',
                transformer(isAuthorizedSignor) {
                    return !!isAuthorizedSignor;
                }
            },
            {
                name: 'archived'
            },
        ];
        this.config = modelConfig([
            {
                type: 'input',
                name: 'id',
                readonly: true
            },
            {
                type: 'input',
                name: 'shortId'
            },
            {
                type: 'input',
                name: 'ownerId'
            },
            {
                type: 'input',
                name: 'firmId'
            },
            {
                type: 'input',
                name: 'firstName'
            },
            {
                type: 'input',
                name: 'lastName'
            },
            {
                type: 'input',
                name: 'email',
                label: 'Work Email',
            },
            {
                type: 'input',
                name: 'phone',
                label: 'Work Phone',
            },
            {
                type: 'select',
                name: 'type',
                options: ['ATTORNEY', 'CLIENT', 'STAFF']
            },
            {
                type: 'select',
                name: 'status',
                options: ['CREATED', 'INVITE_PENDING', 'INCOMPLETE', 'UNDER_REVIEW', 'VERIFIED', 'CONFLICT']
            },
            {
                type: 'input',
                name: 'title',
                cssClasses: 'margin-top-30'
            },
            {
                label: 'Ownership (0-100)',
                type: 'input',
                name: 'ownership',
                pattern: '^(100|[0-9]{1,2})$'
            },
            {
                type: 'input',
                name: 'personalPhone',
                cssClasses: 'margin-top-30'
            },
            {
                type: 'input',
                name: 'addressStreet',
                label: 'Personal Address Street'
            },
            {
                type: 'input',
                name: 'addressCity',
                label: 'Personal Address City'
            },
            {
                type: 'input',
                name: 'addressState',
                label: 'Personal Address State'
            },
            {
                type: 'input',
                name: 'addressZip',
                label: 'Personal Address ZIP'
            },
            // {
            //   type: 'json',
            //   name: 'paymentPlatformCredentials'
            // },
            {
                type: 'select',
                name: 'businessType',
                options: ['PERSONAL', 'BUSINESS']
            },
            // {
            //   type: 'checkbox',
            //   name: 'isStakeholder'
            // },
            {
                type: 'input',
                name: 'barNumber',
                cssClasses: 'margin-top-30',
            },
            {
                type: 'input',
                name: 'barState'
            },
            {
                type: 'encrypted',
                name: 'ssn',
                getDecrypted: this.getProfileFieldDecryptedAsync
            },
            {
                label: 'Date of birth (YYYY-MM-DD)',
                name: 'dob',
                type: 'input',
                pattern: '^[0-9]{4}-[0-9]{2}-[0-9]{2}$',
            },
            {
                type: 'image',
                name: 'barCard',
                allowedExtensions: ALLOWED_IMAGE_EXTENSIONS,
                width: 50
            },
            {
                type: 'image',
                name: 'barCardBack',
                allowedExtensions: ALLOWED_IMAGE_EXTENSIONS,
                width: 50
            },
            // {
            //   type: 'json',
            //   name: 'roles'
            // },
            // {
            //   type: 'checkbox',
            //   name: 'accessRead'
            // },
            // {
            //   type: 'checkbox',
            //   name: 'accessWrite'
            // },
            // {
            //   type: 'checkbox',
            //   name: 'accessDelete'
            // },
            {
                type: 'input',
                name: 'createdBy',
                readonly: true,
                cssClasses: 'margin-top-30',
                additional: true
            },
            {
                type: 'input',
                name: 'createdAt',
                readonly: true,
                additional: true
            },
            {
                type: 'input',
                name: 'updatedAt',
                readonly: true,
                additional: true
            },
            {
                type: 'checkbox',
                name: 'isAuthorizedSignor'
            },
            {
                type: 'checkbox',
                name: 'archived',
            },
            {
                type: 'datepicker',
                name: 'archivedDate',
                readonly: true,
                cssClasses: 'col-sm-6',
                additional: true
            },
        ]);
        this.relations = modelRelations([
            {
                crmModule: 'firms',
                crmAlias: 'firm',
                params: this.firmId,
                resource: 'firm'
            },
            {
                crmModule: 'firm-documents',
                crmAlias: 'Documents',
                params: { profileId: this.id },
                resource: 'firmDocument'
            },
            {
                crmModule: 'audit-logs',
                params: { entity_type: 'attorney', entity_id: this.id },
                resource: 'auditLog'
            },
        ]);
        this.primaryRelations = [
            'firm',
            'client',
            'invoice'
        ];
    }

    get detailsDisplayName(): string {
        if (this.firstName || this.lastName) {
            return `Attorney: ${this.firstName} ${this.lastName}`;
        }
        return `Attorney: ${this.id}`;
    }

    get name(): string {
        return `${this.firstName} ${this.lastName}`;
    }

    get getProfileFieldDecryptedAsync(): any {
        return getProfileFieldDecrypted;
    }
}
