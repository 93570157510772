import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { AuthenticationService } from './authentication.service';

@Injectable()
export class ProfileGuard implements CanActivate {
    constructor(
    private authenticationService: AuthenticationService,
    private router: Router
    ) { }

    canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.authenticationService.getProfile()
            .pipe(
                map(userProfile => {
                    if (userProfile || state.url === '/profile') {
                        return true;
                    }

                    this.router.navigateByUrl('profile');

                    return false;
                }),
                catchError(err => {
                    this.router.navigateByUrl('not-found');

                    return throwError(() => err);
                })
            );
    }
}
