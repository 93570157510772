import { Model, Resource, modelConfig, Field } from '../data';
import {convertFromCents, formatNegativeAmount} from '@app/shared';

export class ReconciliationReportRecord extends Model {

    resourceName: Resource = 'reconciliationReport';

    id: string;
    firm_id: string;
    firm_name: string;
    firm_bank_account_id: string;
    firm_bank_account_name: string;
    balance_in_cents: number;
    sum_of_credits_in_cents: number;
    sum_of_debits_in_cents: number;
    in_flight_in_cents: number;
    pending_in_cents: number;
    delta_in_cents: number;
    cnt_reconciliation_issues: number;

    constructor(attributes = {}) {
        super(attributes);
        super.update(attributes);
    }

    static filterFields(): Field[] {
        return modelConfig([
            {
                // this field is hidden in filter
                label: 'Firm Id',
                type: 'input',
                name: 'firm_id',
                hidden: true,
                cssClasses: 'hidden'
            },
            {
                // this field is hidden in filter
                label: 'Firm Bank Account Id',
                type: 'input',
                name: 'firm_bank_account_id',
                hidden: true,
                cssClasses: 'hidden'
            },
            {
                label: 'Trans. Date From',
                type: 'datepicker',
                name: 'from_date',
                cssClasses: 'filter-date-input',
            },
            {
                label: 'Trans. Date To',
                type: 'datepicker',
                name: 'to_date',
                cssClasses: 'filter-date-input',
            }
        ]);
    }

    /**
     * Returns amount formatted as $1,234.56 or $(1,234.56) if it's debit
     */
    getFormattedAmount(amount_in_cents: number): string {
        const result = convertFromCents(amount_in_cents);
        return (amount_in_cents < 0) ? formatNegativeAmount(result.substring(1)) : result;
    }
}
