import { DEFAULT_MCC, DEFAULT_ORGANIZATION_TYPE, MARKETPLACE_ORDER_STATUSES as STATUSES } from '../constants';
import { Model, Resource, modelConfig, Field, modelRelations } from '../data';
import { formatDate } from '../utils';

export class MarketplaceOrder extends Model {
    resourceName: Resource = 'marketplaceOrder';
    // Property 'id' is required in type 'DefaultEntity'
    // so this is renamed on the backend <<order_id>> field
    id: string;
    firm_id: string;
    marketplace_order_id: string;
    order_status: string;
    merchant_id: string;
    terminal_id: string;
    most_recent_marketplace_order_status: [];
    submitted_marketplace_order_object: Record<string, unknown>;
    original_application_parameters: Record<string, unknown>;
    merchants: object;
    updated_at: number;
    firm_name: string;

    constructor(attributes = {}) {
        super(attributes);
        super.update(attributes);

        this.overviewFields = [
            'firm_name',
            {
                label: 'Firm ID',
                name: 'firm_id',
                transformer: () => `<a href='/crm/firms/${this.firm_id}'>${this.firm_id}</a>`,
            },
            {
                label: 'Banking Partner ID',
                name: 'banking_partner_id'
            },
            {
                label: 'Application Status',
                name: 'order_status'
            },
            {
                label: 'FirstData Marketplace API Order ID',
                name: 'marketplace_order_id'
            },
            {
                label: 'MID Generated by FirstData',
                name: 'merchant_id',
                stayVisible: true,
                transformer: (value) => value ? value : 'None yet',
            },
            {
                label: 'TID Generated by FirstData',
                name: 'terminal_id',
                stayVisible: true,
                transformer: (value) => value ? value : 'None yet',
            },
            {
                label: 'Created At',
                name: 'id',
                cssClasses: 'bold',
                transformer: (value) => formatDate(
                    parseInt(value, 10),
                    'YYYY-MM-DD HH:mm:ss zz'
                ),
            },
            {
                label: 'Updated At',
                name: 'updated_at',
                transformer: (value) => formatDate(
                    value,
                    'YYYY-MM-DD HH:mm:ss zz'
                ),
            }
        ];

        this.configCreate = modelConfig([
            {
                label: 'Banking Partner',
                type: 'select',
                name: 'banking_partner_id',
                options: {
                    url: '/banking-partners/list',
                }
            },
            {
                label: 'Legal Name',
                type: 'input',
                name: 'legal_name'
            },
            {
                label: 'DBA Name',
                type: 'input',
                name: 'dba_name'
            },
            {
                label: 'Legal Contact Name',
                type: 'input',
                name: 'legal_contact_name'
            },
            {
                label: 'Firm Email',
                type: 'input',
                name: 'firm_email'
            },
            {
                label: 'Firm Address',
                type: 'input',
                name: 'firm_address'
            },
            {
                label: 'Firm City',
                type: 'input',
                name: 'firm_address_city'
            },
            {
                label: 'Firm State',
                type: 'input',
                name: 'firm_address_state'
            },
            {
                label: 'Firm Zip',
                type: 'input',
                name: 'firm_address_zip'
            },
            {
                label: 'Firm Phone',
                type: 'input',
                name: 'firm_phone'
            },
            {
                label: 'Firm Tax ID',
                type: 'input',
                name: 'firm_company_tax_id'
            },
            {
                label: 'Firm Website',
                type: 'input',
                name: 'firm_website'
            },
            {
                label: 'Firm Incorporation Date (YYYY-MM-DD)',
                type: 'input',
                name: 'firm_incorporation_date'
            },
            {
                label: 'Firm Incorporation State',
                type: 'input',
                name: 'state_of_incorporation'
            },
            {
                label: 'Primary Attorney First Name',
                type: 'input',
                name: 'primary_attorney_first_name'
            },
            {
                label: 'Primary Attorney Last Name',
                type: 'input',
                name: 'primary_attorney_last_name'
            },
            {
                label: 'Primary Attorney Date Of Birth (YYYY-MM-DD)',
                type: 'input',
                name: 'primary_attorney_dob'
            },
            {
                label: 'Primary Attorney SSN',
                type: 'input',
                name: 'primary_attorney_ssn'
            },
            {
                type: 'select',
                label: 'MCC Code',
                name: 'mcc',
                defaultValue: DEFAULT_MCC,
                defaultValueLabel: '(Default)',
                options: {
                    url: '/marketplace-orders/mcc-codes'
                }
            },
            {
                type: 'select',
                label: 'Organization Type',
                name: 'organization_type',
                defaultValue: DEFAULT_ORGANIZATION_TYPE,
                defaultValueLabel: '(Default)',
                options: {
                    url: '/marketplace-orders/organization-types'
                }
            },
            {
                label: 'Firm Projected Monthly Credit Card Transaction Volume (1,000 - 10,000,000)',
                type: 'input',
                name: 'monthly_volume_in_usd',
                prefix: 'USD'
            },
            {
                label: 'Firm Projected Largest Individual Credit Card Transaction Size (1,000 - 100,000)',
                type: 'input',
                name: 'highest_ticket_in_usd',
                prefix: 'USD'
            },
            {
                label: 'Firm Projected Average Individual Credit Card Transaction Size (1 - 99,999)',
                type: 'input',
                name: 'average_ticket_in_usd',
                prefix: 'USD'
            }
        ]);
        this.config = modelConfig([
            {
                type: 'input',
                name: 'id',
                readonly: true,
            },
            {
                type: 'input',
                name: 'marketplace_order_id',
                readonly: true,
            },
            {
                type: 'input',
                name: 'firm_id',
                readonly: true,
            },
            {
                type: 'input',
                name: 'firm_name',
                readonly: true,
            },
            {
                type: 'input',
                name: 'banking_partner_id',
                readonly: true,
            },
            {
                type: 'input',
                name: 'merchant_id',
                readonly: true,
            },
            {
                type: 'input',
                name: 'terminal_id',
                readonly: true,
            },
            {
                type: 'input',
                name: 'order_status',
                readonly: true,
            },
            {
                type: 'input',
                name: 'updated_at',
                readonly: true,
            },
            {
                label: 'Submitted Data',
                name: 'submitted_marketplace_order_object',
                type: 'json',
                readonly: true,
                additional: true,
            },
            {
                label: 'Application parameters',
                name: 'original_application_parameters',
                type: 'json',
                readonly: true,
                additional: true,
            },
            {
                label: 'Last Status From FirstData',
                name: 'most_recent_marketplace_order_status',
                type: 'json',
                readonly: true,
                additional: true,
            }
        ]);
        this.relations = modelRelations([
            {
                crmModule: 'firms',
                crmAlias: 'firm',
                params: this.firm_id,
                resource: 'firm',
            },
            {
                crmModule: 'audit-logs',
                params: { entity_type: 'marketplace_order', entity_id: this.id },
                resource: 'auditLog'
            }
        ]);
    }

    // This is details page heading provider
    get detailsDisplayName(): string {
        return `Marketplace Order: ${this.id}`;
    }

    static filterFields(): Field[] {
        return modelConfig([
            {
                // this field is hidden in filter
                type: 'input',
                name: 'firmId',
                hidden: true,
                cssClasses: 'hidden'
            },
            {
                // this field is hidden in filter
                type: 'input',
                name: 'bankingPartnerId',
                hidden: true,
                cssClasses: 'hidden'
            },
            {
                type: 'select',
                label: 'Filter by order status',
                name: 'status',
                cssClasses: 'col-sm-4 min-width-200',
                options: {
                    '': 'All types',
                    ...STATUSES
                },
                defaultValue: STATUSES.COMPLETED,
            },
        ]);
    }
}

