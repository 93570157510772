import { Model, Resource, modelConfig, modelRelations, Field } from '../data';

export class FirmIssue extends Model {
    resourceName: Resource = 'firmIssue';

    id: string;
    uniqueId: string;
    firmId: string;
    transactionId: string;
    type: string;
    message: string;
    comment: string;
    status: string;
    createdAt: Date;
    updatedAt: Date;
    currencyTransferId: string;
    batchTransactionId: string;

    firm: {
        name: string;
    };

    constructor(attributes = {}) {
        super(attributes);
        super.update(attributes);


        this.overviewFields = [
            'id',
            {
                name: 'currencyTransferId',
                transformer: (value) => `<a href='/crm/currency-transfers/${value}'>${value}</a>`
            },
            {
                name: 'batchTransactionId',
                transformer: (value) => `<a href='/crm/nacha-transactions/${value}'>${value}</a>`
            },
            {
                label: 'Issue type',
                name: 'type',
                cssClasses: 'bold'
            },
            {
                label: 'Issue status',
                name: 'status',
                cssClasses: 'bold'
            },
            {
                name: 'firmId',
                transformer: <FirmIssue>(firmId, model) => `<a href='/crm/firms/${firmId}'>${model.firm.name}</a>`
            },
            {
                name: 'transactionId',
                transformer: (value) => `<a href='/crm/transactions/${value}'>${value}</a>`
            },
            'message',
            'comment',
            'createdAt',
            {
                name: 'updatedAt',
                transformer: 'formatDate'
            }
        ];
        this.config = modelConfig([
            {
                type: 'input',
                name: 'id',
                readonly: true
            },
            {
                type: 'input',
                name: 'currencyTransferId',
                readonly: true
            },
            {
                type: 'input',
                name: 'batchTransactionId',
                readonly: true
            },
            {
                type: 'input',
                name: 'firmId',
                readonly: true
            },
            {
                type: 'input',
                name: 'type',
                readonly: true
            },
            {
                type: 'input',
                name: 'message',
                readonly: true
            },
            {
                type: 'select',
                label: 'Status',
                name: 'status',
                options: {
                    ACTIVE: 'ACTIVE',
                    MUTE: 'MUTE',
                    RESOLVED: 'RESOLVED'
                }
            },
            {
                type: 'input',
                name: 'comment'
            },
            {
                type: 'input',
                name: 'createdAt',
                readonly: true,
            },
            {
                type: 'input',
                name: 'updatedAt',
                readonly: true,
            }
        ]);
        this.relations = modelRelations([
            {
                crmModule: 'firms',
                crmAlias: 'firm',
                params: this.firmId,
                resource: 'firm'
            },
            {
                crmModule: 'transactions',
                crmAlias: 'transaction',
                params: this.transactionId,
                resource: 'transaction'
            },
            {
                crmModule: 'currency-transfers',
                crmAlias: 'currencyTransfer',
                params: this.currencyTransferId,
                hidden: () => !this._isCurrencyTransfer(),
                resource: 'currencyTransfer'
            },
            {
                crmModule: 'nacha-transactions',
                crmAlias: 'Batch Transactions',
                params: this.batchTransactionId,
                hidden: () => !this._isBatchTransaction(),
                resource: 'nachaTransaction'
            },
            {
                crmModule: 'audit-logs',
                params: { entity_id: this.id },
                resource: 'auditLog'
            }
        ]);
    }

    _isCurrencyTransfer (): boolean {
        if (this.type === 'CURRENCY_TRANSFER_STUCK_IN_PENDING_STATUS' || this.type === 'ACH_REJECT') return true;
    }

    _isBatchTransaction (): boolean {
        if (this.type === 'BATCH_TRANSACTION_STUCK_IN_PENDING_STATUS' || this.type === 'ACH_REJECT') return true;
    }

    get detailsDisplayName(): string {
        if (this.firm && this.firm.name) {
            return `Firm "${this.firm.name}" issue`;
        }
        return `Firm issue`;
    }

    get firmName(): string {
        return this.firm && this.firm.name;
    }

    static filterFields(): Field[] {
        return modelConfig([
            {
                type: 'input',
                name: 'transactionId',
                hidden: true,
                cssClasses: 'hidden'
            },
            {
                type: 'select',
                label: 'Issue status',
                name: 'status',
                options: {
                    ACTIVE: 'ACTIVE',
                    MUTE: 'MUTE',
                    RESOLVED: 'RESOLVED'
                },
                cssClasses: 'margin-right-20 margin-left-20',
            },
            {
                type: 'select',
                label: 'Issue type',
                name: 'type',
                options: {
                    url: '/firm-issues/issue-types',
                    default: {'': 'All Issue Types'}
                },
                cssClasses: 'margin-right-20',
            },
            {
                type: 'input',
                name: 'search',
                label: 'Search',
                cssClasses: 'col-sm-6 min-width-400 margin-right-20',
            }
        ]);
    }
}
