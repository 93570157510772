<div [class]="'dynamic-field form-input form-fraud-rules mat-form-field-wrapper ' + field.cssClasses "
      [formGroup]="group">
    <h4>Fraud rules override</h4>
    <table [class]="'table-fraud-rules'">
        <tr [class]="'tr-fraud-rules'">
            <th [class]="'th-fraud-rules rule-name'" colspan="2">Rule</th>
            <th [class]="'th-fraud-rules rule-option'">Config</th>
            <th [class]="'th-fraud-rules rule-option'">Value</th>
            <th [class]="'th-fraud-rules rule-option'">Enabled</th>
        </tr>
        <tr *ngFor="let option of defaultValue" [class]="'tr-fraud-rules'">
            <td colspan="2" [class]="'td-fraud-rules rule-name bold-rule'">
                <div [class]="'rule-name-value'">
                    <label>
                        <input matInput
                            [type]="text"
                            [value]="option.ruleName">
                    </label>
                </div>
            </td>
            <td [class]="'td-fraud-rules rule-option bold-rule'">
                <div *ngFor="let configOption of option.config | keyvalue" [class]="'rule-key'">
                    <label>
                        <input matInput
                            [type]="text"
                            [value]="configOption.key">
                    </label>
                    <br/>
                </div>
            </td>
            <td [class]="'td-fraud-rules rule-option'">
                <span *ngFor="let configOption of option.config | keyvalue">
                    <div
                        [class]="'editable-config ' + getEditableClasses(configOption.key)"
                        contenteditable="true"
                        type="number"
                        (keypress)="validateValue($event)"
                        (blur)="updateList($event, option.ruleName, configOption.key)">
                            {{configOption.value}}
                    </div>
                </span>
            </td>
            <td [class]="'td-fraud-rules rule-option'">
                <mat-checkbox [checked]="option.isEnabled" (change)="changeEnabling($event, option.ruleName)">
                </mat-checkbox>
            </td>
        </tr>
    </table>

    <mat-hint class="margin-bottom-20" *ngIf="field.hint">({{ field.hint }})</mat-hint>
    <mat-form-field  class="custom_form__field" [class]="'hidden-state'" >
        <label>
            <input matInput [formControlName]="field.name"/>
        </label>
    </mat-form-field>
    </div>
