import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export enum DynamicFormResetMode {
    IMAGES_ONLY = 'IMAGES_ONLY'
}

@Injectable()
export class DynamicFormService {

    static RESET_MODE = {
        IMAGES_ONLY: DynamicFormResetMode.IMAGES_ONLY
    };

    reset: Subject<DynamicFormResetMode> = new Subject();
    pristine: Subject<any> = new Subject();

    resetForm(mode?: DynamicFormResetMode) {
        this.reset.next(mode);
    }

    setPristine() {
        this.pristine.next(null);
    }
}
