import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';

import { IfPermissionsModule } from '../if-permissions';
import { PageHeadingModule } from '../page-heading';
import { DynamicFormModule } from '../dynamic-form';
import { OverviewModule } from '../overview';
import { NotesModule } from '../notes';
import { DetailsComponent } from './details.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        MatCardModule,
        MatButtonToggleModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        MatMenuModule,
        MatIconModule,
        MatTabsModule,
        IfPermissionsModule,
        PageHeadingModule,
        DynamicFormModule,
        OverviewModule,
        NotesModule
    ],
    declarations: [DetailsComponent],
    exports: [DetailsComponent],
})
export class DetailsModule { }
