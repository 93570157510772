import * as moment from 'moment-timezone';
import * as _ from 'lodash';

export function normalizeLabel(val: string): string {
    return val
        .replace(/(?:-)([A-Za-z])/g, (str, char) => char.toUpperCase())
        .replace(/(?:_)([A-Za-z])/g, (str, char) => char.toUpperCase())
        .replace(/([A-Z]+)/g, ' $1')
        .replace(/^./, str => str.toUpperCase());
}

export function formatDate (value, customFormat = null, timeZone = null): string {
    return moment(value).tz(timeZone || moment.tz.guess()).format(customFormat || 'M/D/YY, HH:mm zz');
}

export function formatUtcDate (value, customFormat = null): string {
    return moment(value).tz('UTC').format(customFormat || 'M/D/YY, HH:mm zz');
}

export function getCurrentDateTime(format): string {
    return moment().tz(moment.tz.guess()).format(format);
}

export function getCurrentTimezone(): string {
    return moment.tz.guess();
}

/**
 * Get file name (test.pdf) from full URL of S3 file like this:
 * https://partners-uploads.s3.amazonaws.com/99368861-3c24-41a2-ae54-ef5da3de2c55/test.pdf?AWSAccessKeyId=AKIAYQOC22
 * If failed - returns full URL as is
 */
export function getFileName(fullURL: string): string {
    if (!fullURL) return fullURL;

    const regex = /([^\/]+\.[a-z]+)\?/;
    const matches = fullURL.match(regex);

    if (matches?.length === 2) {
        return matches[1];
    }
    return fullURL;
}

export function validateNumberValue(event: any) {
    const isNumber = !isNaN(parseInt(String.fromCharCode(event.which), 10));
    const isDot = String.fromCharCode(event.which) === '.';
    const isAlreadyContaisDot = event.target.textContent.includes('.');
    const isSpace = event.which === 32;
    const isEnter = event.which === 13;

    if ((isDot && isAlreadyContaisDot) || isSpace || isEnter || (!isNumber && !isDot)) {
        event.preventDefault();
    }
}

export function copyValue<T>(value: T) {
    return _.cloneDeep<T>(value);
}

export function isModelValueNotEmpty(value: any): boolean {
    return !!value || value === false || value === 0;
}
