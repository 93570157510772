import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';

import { Field } from '../../../../data';
import { Debounce } from '../../../../utils';

@Component({
    selector: 'aw-form-json',
    template: `
    <div class="dynamic-field form-input"
      [formGroup]="group">
      <label class="jsoneditor-label" [id]="field.name">
        {{ field.label }}
      </label>
      <pre class="json-preview" *ngIf="field.editable === false">{{ stringify(data) }}</pre>
      <json-editor
        *ngIf="field.editable !== false"
        [options]="options"
        [data]="data">
      </json-editor>
      <div class="ml-3 mb-3"
        *ngIf="field.hint">
        <label class="jsoneditor-label">
          Example
        </label>
        <pre class="code-snippet">{{ field.hint }}</pre>
      </div>
    </div>
  `
})
export class FormJsonComponent implements OnInit {
    field: Field;
    group: FormGroup;
    options = new JsonEditorOptions();
    exampleOptions = new JsonEditorOptions();
    data: object;
    example: object;

  @ViewChild(JsonEditorComponent) editor: JsonEditorComponent;

  ngOnInit() {
      this.data = this.group.value[this.field.name] || this.field.defaultValue;
      this.setValue(this.data);

      // listen changes
      this.group.controls[this.field.name].valueChanges.subscribe(data => {
          this.data = data;
      });

      this.options.mode = 'code';
      this.options.onChange = () => {
          try {
              this.patchForm(this.editor.get());
          } catch {}
      };

      this.exampleOptions.onEditable = () => false;
      this.exampleOptions.mode = 'code';
  }

  stringify(data) {
      return JSON.stringify(data, null, 2);
  }

  @Debounce()
  private patchForm(value: object) {
      this.group.controls[this.field.name].patchValue(value);
      this.group.controls[this.field.name].markAsDirty();
  }

  private setValue(value: object) {
      this.group.controls[this.field.name].patchValue(value);
      this.group.controls[this.field.name].markAsPristine();
  }

}
