import { Model, Resource, modelConfig, Field, modelRelations } from '../data';
import { convertFromCents, formatNegativeAmount, formatUtcDate } from '../utils';

export class FirstdataTransaction extends Model {
    resourceName: Resource = 'firstdataTransaction';

    id: string;
    unique_id: string;
    transaction_type: string;
    merchant_id: string;
    dba_name: string;
    external_id: string;
    funded_date: Date;
    card_type: string;
    card_number: string;
    batch_number: string;
    batch_date: Date;
    customer_reference_number: string;
    transaction_id: string;
    transaction_date: Date;
    transaction_amount_in_cents: number;
    submitted_currency_code: string;
    transaction_status: string;
    reject_indicator: string;
    reject_reason: string;
    authorization_date: Date;
    transaction_time: Date;
    headnote_transaction_id: string;
    currency_transfer_id: string;
    expiration_date: string;
    file_date: Date;
    created_at: Date;
    trace_id: string;

    constructor(attributes = {}) {
        super(attributes);
        super.update(attributes);

        this.overviewFields = [
            'id',
            {
                name: 'headnote_transaction_id',
                stayVisible: true,
                transformer: (headnote_transaction_id) => {
                    if (!headnote_transaction_id) return '-';
                    return `<a href="/crm/transactions/${headnote_transaction_id}">${headnote_transaction_id}</a>`;
                }
            },
            {
                name: 'currency_transfer_id',
                stayVisible: true,
                transformer: (currency_transfer_id) => {
                    if (!currency_transfer_id) return '-';
                    return `<a href="/crm/currency-transfers/${currency_transfer_id}">${currency_transfer_id}</a>`;
                }
            },
            'dba_name',
            'merchant_id',
            {
                label: 'Trace ID (Currency Transfer Hash)',
                name: 'trace_id',
                transformer: (trace_id) => {
                    return `<a href="/crm/currency-transfers;currency_transfer_hash=${trace_id};pageSize=25">${trace_id}</a>`;
                }
            },
            {
                name: 'transaction_type',
                cssClasses: 'bold'
            },
            {
                label: 'Transaction ID (Auth ID)',
                name: 'transaction_id',
                cssClasses: 'bold',
                stayVisible: true,
                transformer: (value) => value || '-'
            },
            {
                name: 'transaction_date',
                transformer: (value) => formatUtcDate(value, 'YYYY-MM-DD')
            },
            'transaction_time',
            {
                name: 'transaction_amount_in_cents',
                label: 'Transaction Amount',
                transformer: <FirstdataTransaction>(value) => {
                    const cssClass = this.isCredit ? 'negative-amount' : 'bold';
                    return `<span class="${cssClass}">${this.getFormattedAmount(value)}</span>`;
                }
            },
            'submitted_currency_code',
            {
                name: 'transaction_status',
                cssClasses: 'bold'
            },
            'external_id',
            {
                name: 'authorization_date',
                transformer: (value) => formatUtcDate(value, 'YYYY-MM-DD')
            },
            {
                name: 'funded_date',
                transformer: (value) => formatUtcDate(value, 'YYYY-MM-DD')
            },
            'transmission_timestamp',
            'card_type',
            'card_number',
            'expiration_date',
            'batch_number',
            {
                name: 'batch_date',
                transformer: (value) => formatUtcDate(value, 'YYYY-MM-DD')
            },
            'customer_reference_number',
            'reject_indicator',
            'reject_reason',
            {
                name: 'file_date',
                transformer: (value) => formatUtcDate(value, 'YYYY-MM-DD')
            },
            {
                name: 'transaction_unique_id',
                transformer: (value, model) => '<small>' + value + '</small>'
            },
            {
                name: 'reference_number',
                stayVisible: true,
                transformer: (value) => value || '-'
            },
            'created_at'
        ];
        this.config = modelConfig([
            {
                type: 'input',
                name: 'id',
                readonly: true
            },
            {
                type: 'input',
                name: 'unique_id',
                readonly: true
            },
            {
                type: 'input',
                name: 'headnote_transaction_id',
                readonly: true
            },
            {
                type: 'input',
                name: 'currency_transfer_id',
                readonly: true
            },
            {
                type: 'input',
                name: 'transaction_type',
                readonly: true
            },
            {
                type: 'input',
                name: 'merchant_id',
                readonly: true
            },
            {
                label: 'Trace ID (Currency Transfer Hash)',
                type: 'input',
                name: 'trace_id',
                readonly: true
            },
            {
                type: 'input',
                name: 'dba_name',
                readonly: true
            },
            {
                type: 'input',
                name: 'external_id',
                readonly: true
            },
            {
                type: 'input',
                name: 'funded_date',
                readonly: true
            },
            {
                type: 'input',
                name: 'card_type',
                readonly: true
            },
            {
                type: 'input',
                name: 'card_number',
                readonly: true
            },
            {
                type: 'input',
                name: 'batch_number',
                readonly: true
            },
            {
                type: 'input',
                name: 'batch_date',
                readonly: true
            },
            {
                type: 'input',
                name: 'customer_reference_number',
                readonly: true
            },
            {
                type: 'input',
                name: 'transaction_id',
                readonly: true
            },
            {
                type: 'input',
                name: 'transaction_date',
                readonly: true
            },
            {
                type: 'input',
                name: 'transaction_amount_in_cents',
                readonly: true
            },
            {
                type: 'input',
                name: 'submitted_currency_code',
                readonly: true
            },
            {
                type: 'input',
                name: 'transaction_status',
                readonly: true
            },
            {
                type: 'input',
                name: 'reject_indicator',
                readonly: true
            },
            {
                type: 'input',
                name: 'reject_reason',
                readonly: true
            },
            {
                type: 'input',
                name: 'authorization_date',
                readonly: true
            },
            {
                type: 'input',
                name: 'transaction_time',
                readonly: true
            },
            {
                type: 'input',
                name: 'expiration_date',
                readonly: true
            },
            {
                type: 'input',
                name: 'file_date',
                readonly: true
            },
            {
                type: 'input',
                name: 'created_at',
                readonly: true
            },
        ]);

        this.relations = modelRelations([
            {
                crmModule: 'transactions',
                crmAlias: 'Transaction',
                params: this.headnote_transaction_id,
                resource: 'transaction'
            },
            {
                crmModule: 'currency-transfers',
                crmAlias: 'Currency Transfer',
                params: this.currency_transfer_id,
                resource: 'currencyTransfer'
            },
            {
                crmModule: 'firstdata-transactions',
                crmAlias: 'Find Similar Firstdata Transactions',
                params: {
                    merchant_id: this.merchant_id,
                    last4: this.card_number ? this.card_number.slice(-4) : ''
                },
                resource: 'firstdataTransaction'
            },
        ]);
    }

    get detailsDisplayName(): string {
        return `Firstdata Transactions`;
    }

    /**
     * REFUND and CHARGEBACK are credit transactions
     * PAYMENT and CHARGEBACK_REVERSAL are debit transactions
     */
    get isCredit() {
        return ['REFUND', 'CHARGEBACK'].includes(this.transaction_type);
    }

    /**
     * Returns amount formatted as $1,234.56 or $(1,234.56) if it's credit
     */
    getFormattedAmount(value: number): string {
        const result = convertFromCents(value, this.submitted_currency_code);
        return this.isCredit ? formatNegativeAmount(result) : result;
    }

    static filterFields(): Field[] {
        return modelConfig([
            {
                type: 'input',
                name: 'headnote_transaction_id',
                hidden: true,
                cssClasses: 'hidden'
            },
            {
                type: 'input',
                name: 'firstdata_transaction_id',
                hidden: true,
                cssClasses: 'hidden'
            },
            {
                type: 'select',
                label: 'Transaction source',
                name: 'transaction_source',
                cssClasses: 'col-sm-4 min-width-200',
                defaultValue: 'HEADNOTE_PAYMENTS',
                options: {
                    'HEADNOTE_PAYMENTS': 'HEADNOTE_PAYMENTS',
                    'HEADNOTE_COM': 'HEADNOTE_COM'
                }
            },
            {
                type: 'select',
                label: 'Transaction type',
                name: 'transaction_type',
                cssClasses: 'col-sm-4 min-width-200',
                options: {
                    url: '/firstdata-transactions/transaction-types',
                    default: {'': 'All types'}
                }
            },
            {
                type: 'select',
                label: 'Transaction status',
                name: 'transaction_status',
                cssClasses: 'col-sm-4 min-width-200',
                options: {
                    '': 'All statuses',
                    'ACCEPTED': 'ACCEPTED',
                    'REJECT': 'REJECT',
                }
            },
            {
                type: 'datepicker',
                name: 'authorization_date_from',
                label: 'Auth Date From',
                cssClasses: 'filter-date-input',
            },
            {
                type: 'datepicker',
                name: 'authorization_date_to',
                label: 'Auth Date To',
                cssClasses: 'filter-date-input',
            },
            {
                type: 'input',
                name: 'transaction_amount_in_cents',
                label: 'Amount in cents',
                minlength: 3,
                maxlength: 8,
                cssClasses: 'col-sm-4 min-width-200'
            },
            {
                type: 'input',
                name: 'last4',
                label: 'Last4',
                pattern: '^[0-9]{4}$',
                minlength: 4,
                maxlength: 4,
                cssClasses: 'col-sm-4 min-width-200'
            },
            {
                type: 'input',
                name: 'dba_name',
                label: 'DBA Name',
                minlength: 3,
                cssClasses: 'col-sm-4 min-width-200'
            },
            {
                type: 'input',
                name: 'merchant_id',
                label: 'Merchant ID',
                pattern: '^([0-9]{12,15}|RCTST[0-9]+)$',
                minlength: 12,
                maxlength: 15,
                cssClasses: 'col-sm-4 min-width-200'
            },
            {
                type: 'input',
                name: 'transaction_id',
                label: 'Transaction Id (Auth ID)',
                pattern: '^[0-9A-Z]{6}$',
                minlength: 6,
                maxlength: 6,
                cssClasses: 'col-sm-4 min-width-200'
            },
            {
                type: 'checkbox',
                name: 'unmatched_only'
            }
        ]);
    }
}

