import * as moment from 'moment';
import * as _ from 'lodash';

import {
    Model,
    Resource,
    modelConfig,
    modelRelations,
    Field,
    FraudRule,
    FirmBankingPartners
} from '../data';
import { FRAUD_ALERT_CENT_VALUES, FRAUD_ALERT_PERCENT_VALUES, getFirmSettingsDefaultFraudRules } from '../constants';
import { convertFromCents } from '../utils';
import { SCHEDULE_OPTIONS, SCHEDULE_NAMES } from '@app/protected/crm/firm-settings/firm-settings.constants';
import { BankingPartnersRow } from '../services/banking-partners.service';

export class FirmSettings extends Model {
    resourceName: Resource = 'firmSettings';

    id: string;
    firmId: string;
    stripeSubscription: object;
    paymentReminderSchedule: object;
    allowedPaymentTypes: object;

    cardPaymentProcessor: string;
    achPaymentProcessor: string;

    level23DataEnabled: boolean;
    surchargeFeatureEnabled: boolean;
    networkTokensEnabled: boolean;
    interestFeatureEnabled: boolean;
    isAmexDisabled: boolean;
    createdAt: Date;
    updatedAt: Date;
    fraudRules: FraudRule[];
    defaultFraudRules: FraudRule[];
    batchFeeProcessing: boolean;
    creditCardProcessingAllowed: boolean;
    debitCardProcessingAllowed: boolean;
    achProcessingAllowed: boolean;
    batchFeeProcessingSchedule: {
        startDate: string;
        period: string;
    };

    firm: {
        partnerId: string;
        email: string,
        name: string,
        partnerName: string
    };

    bankingPartners: FirmBankingPartners;
    bankingPartnerList: BankingPartnersRow[];

    constructor(attributes = {}) {
        super(attributes);
        super.update(attributes);

        const batchFeeProcessingScheduleFromDB = _.get(attributes, 'batchFeeProcessingSchedule', null);
        if (batchFeeProcessingScheduleFromDB) {
            this.batchFeeProcessingSchedule.period = SCHEDULE_OPTIONS[JSON.stringify(batchFeeProcessingScheduleFromDB.period)];
        }

        this.computedFields = ['defaultFraudRules'];

        this.overviewFieldsFn = () => [
            {
                label: 'Partner',
                name: 'firmId',
                transformer: <FirmSettings>(value, model) => `<a href='/crm/partners/${model.firm.partnerId}'>${model.firm.partnerName}</a>`
            },
            'firmName',
            'firmEmail',
            {
                label: 'Payment Processors',
                isSectionHeader: true,
                name: 'payment-processors'
            },
            {
                label: 'Credit Card payment processor',
                name: 'cardPaymentProcessor',
            },
            {
                label: 'ACH payment processor',
                name: 'achPaymentProcessor',
            },
            {
                label: 'Banking Partners',
                isSectionHeader: true,
                name: 'banking-partners'
            },
            ...this.getBankingPartnersOverviewFields(),
            {
                label: 'Limits',
                isSectionHeader: true,
                name: 'delays'
            },
            {
                label: 'Credit Card Transaction Limit',
                name: 'maxCardTransactionLimit',
                transformer: 'formatAmount',
            },
            {
                label: 'ACH Transaction Limit',
                name: 'maxAchTransactionLimit',
                transformer: 'formatAmount',
            },
            {
                label: 'Delays',
                isSectionHeader: true,
                name: 'delays'
            },
            {
                label: 'ACH payout delay',
                name: 'achPayoutDelay',
                transformer: (value) => `<b>${value}</b> business days`
            },
            {
                label: 'RapidConnect payout delay',
                name: 'rapidconnectPayoutDelay',
                transformer: (value) => `<b>${value}</b> business days`
            },
            {
                label: 'Batch Processing',
                isSectionHeader: true,
                name: 'batch-processing'
            },
            {
                name: 'batchFeeProcessing',
                transformer: value => this.enabledDisabled(value)
            },
            {
                name: 'batchFeeProcessingSchedule',
                transformer: (value, model: any) => {
                    if (!model.batchFeeProcessing) return '-';

                    const period = value.period;

                    if (period === SCHEDULE_NAMES.DAY) {
                        return period;
                    }

                    if (period === SCHEDULE_NAMES.TWICE_MONTH) {
                        return period;
                    }

                    if (period === SCHEDULE_NAMES.MONTH) {
                        const startDate = moment(value.startDate).format('Do');
                        return period ? startDate + ' ' + period : '';
                    }

                    const dayOfWeek = moment(value.startDate).format('dddd').toLowerCase();

                    return period ? dayOfWeek + ' ' + period : '';
                }
            },
            {
                label: 'Batch Payouts Processing',
                name: 'batchPayoutsProcessing',
                transformer: value => this.enabledDisabled(value)
            },
            {
                label: 'Enable/Disable Client Payment Methods',
                isSectionHeader: true,
                name: 'payment-methods'
            },
            {
                label: 'Allow Client Credit Card Payments',
                name: 'creditCardProcessingAllowed',
                transformer: value => this.enabledDisabled(value)
            },
            {
                label: 'Allow Client Debit Card Payments',
                name: 'debitCardProcessingAllowed',
                transformer: value => this.enabledDisabled(value)
            },
            {
                label: 'Allow Client ACH Payments',
                name: 'achProcessingAllowed',
                transformer: value => this.enabledDisabled(value)
            },
            {
                label: 'Enable/Disable Payout Methods',
                isSectionHeader: true,
                name: 'payout-methods'
            },
            {
                label: 'Operating Payouts',
                name: 'operatingPayoutsProcessing',
                transformer: (value) => this.enabledDisabled(value, 'Enabled', 'Paused')
            },
            {
                label: 'Trust Payouts',
                name: 'trustPayoutsProcessing',
                transformer: (value) => this.enabledDisabled(value, 'Enabled', 'Paused')
            },
            {
                label: 'Additional Features',
                isSectionHeader: true,
                name: 'additional-features'
            },
            {
                label: 'Surcharge Feature',
                name: 'surchargeFeatureEnabled',
                transformer: value => this.enabledDisabled(value)
            },
            {
                label: 'Network Tokens',
                name: 'networkTokensEnabled',
                transformer: value => this.enabledDisabled(value)
            },
            {
                label: 'Fraud Rules',
                isSectionHeader: true,
                name: 'fraud-rules'
            },
            {
                hideLabel: true,
                name: 'fraudRules',
                label: 'Fraud rules override',
                stayVisible: true,
                transformer: <FirmSettings>(value, model) => {
                    if (!model.defaultFraudRules) {
                        return '';
                    }
                    // Map DEFAULT_FRAUD_RULES with current fraudRules overrides
                    const rules = model.defaultFraudRules.map((defaultRule: FraudRule) => {
                        const ruleOverride = value ? value.find(configOverride => configOverride.ruleName === defaultRule.ruleName) : null;
                        const overrideIsEnabled = ruleOverride && 'isEnabled' in ruleOverride;
                        const isEnabled = !ruleOverride || !overrideIsEnabled
                            ? true
                            : ruleOverride.isEnabled;
                        return {
                            ruleName: defaultRule.ruleName,
                            isEnabled: isEnabled,
                            description: defaultRule.description,
                            config: Object.entries(defaultRule.config).map(function([key, ruleValue]) {
                                const isDefault = _.get(ruleOverride, 'config.' + key) ? false : true;
                                let actualValue = _.get(ruleOverride, 'config.' + key) ? ruleOverride.config[key] : ruleValue;
                                if (FRAUD_ALERT_CENT_VALUES.includes(key)) {
                                    actualValue = convertFromCents(actualValue as string);
                                } else if (FRAUD_ALERT_PERCENT_VALUES.includes(key)) {
                                    actualValue = actualValue + '%';
                                }

                                return {
                                    key: key,
                                    value: actualValue,
                                    isDefault: isDefault
                                };
                            }),

                        };
                    });

                    return `
                        <div class="semibold">Fraud rules override:</div>
                        <div class="">
                            <div>
                                <table class="table-fraud-rules">
                                    <thead>
                                        ${this.titlesFragment}
                                    </thead>
                                    <tbody>
                                        ${rules.reduce(this.ruleReducer, '')}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    `;

                },
                cssClasses: 'overflow-auto'
            },
            'createdAt',
            {
                name: 'updatedAt',
                transformer: 'formatDate'
            }
        ];

        this.configFn = () => modelConfig([
            {
                type: 'input',
                name: 'firmId',
                readonly: true
            },
            {
                label: 'Payment Processors',
                type: 'section-header',
                name: 'payment-processors'
            },
            {
                type: 'select',
                name: 'cardPaymentProcessor',
                options: [
                    'PAYEEZY',
                    'HP_PAYMENTS_RAPIDCONNECT',
                    'HP_PAYMENTS_PAYEEZY'
                ]
            },
            {
                type: 'select',
                label: 'ACH payment processor',
                name: 'achPaymentProcessor',
                options: [
                    'NACHA_FILES',
                    'HP_PAYMENTS'
                ]
            },
            {
                label: 'Limits',
                type: 'section-header',
                name: 'limits'
            },
            {
                label: 'Credit Card Transaction Limit (USD, 1 - 250000)',
                type: 'input',
                name: 'maxCardTransactionLimit',
            },
            {
                label: 'ACH Transaction Limit (USD, 1 - 250000)',
                type: 'input',
                name: 'maxAchTransactionLimit',
            },
            {
                label: 'Delays',
                type: 'section-header',
                name: 'delays'
            },
            {
                label: 'ACH payout delay (if not specified its default value is 3 business days)',
                type: 'input',
                name: 'achPayoutDelay',
                pattern: '^[0-9]+$',
            },
            {
                label: 'RapidConnect payout delay (if not specified its default value is 1 business day)',
                type: 'input',
                name: 'rapidconnectPayoutDelay',
                pattern: '^[0-9]+$',
            },
            {
                label: 'Batch Processing',
                type: 'section-header',
                name: 'batch-processing'
            },
            {
                type: 'checkbox',
                name: 'batchFeeProcessing'
            },
            {
                type: 'json-group',
                name: 'batchFeeProcessingSchedule',
                group: [
                    {
                        type: 'datepicker',
                        name: 'startDate',
                        cssClasses: 'col-sm-4',
                    },
                    {
                        type: 'select',
                        name: 'period',
                        cssClasses: 'col-sm-4',
                        options: Object.values(SCHEDULE_NAMES).reduce((prev, current) => ({ ...prev, [current]: current }), {})
                    },
                ]
            },
            {
                type: 'checkbox',
                name: 'batchPayoutsProcessing',
                label: 'Batch Payouts Processing',
                hint: 'Every day 18:50 Pacific Time'
            },
            {
                label: 'Enable/Disable Client Payment Methods',
                type: 'section-header',
                name: 'enable-payment-methods'
            },
            {
                label: 'Allow Client Credit Card Payments',
                type: 'checkbox',
                name: 'creditCardProcessingAllowed',
            },
            {
                label: 'Allow Client Debit Card Payments',
                type: 'checkbox',
                name: 'debitCardProcessingAllowed',
            },
            {
                label: 'Allow Client ACH Payments',
                type: 'checkbox',
                name: 'achProcessingAllowed',
            },
            {
                label: 'Enable/Disable Payout Methods',
                type: 'section-header',
                name: 'enable-payout-methods'
            },
            {
                type: 'checkbox',
                name: 'operatingPayoutsProcessing',
                label: 'Operating Payouts enabled'
            },
            {
                type: 'checkbox',
                name: 'trustPayoutsProcessing',
                label: 'Trust Payouts enabled'
            },
            {
                label: 'Additional Features',
                type: 'section-header',
                name: 'additional-features'
            },
            {
                type: 'checkbox',
                name: 'surchargeFeatureEnabled',
                hideIf: (values) => values.cardPaymentProcessor !== 'HP_PAYMENTS_RAPIDCONNECT'
            },
            {
                type: 'checkbox',
                name: 'networkTokensEnabled',
                hideIf: (values) => values.cardPaymentProcessor !== 'HP_PAYMENTS_RAPIDCONNECT'
            },
            {
                type: 'fraud-rules',
                name: 'fraudRules',
                getOptions: this.getFirmSettingsDefaultFraudRulesAsync
            },
            {
                type: 'json-group',
                name: 'bankingPartners',
                additional: true,
                group: (this.bankingPartnerList || []).map(({ value, label, globally_enabled }) => {

                    // check if banking partner is already configured (has MID or TID)
                    const isConfigured = this.bankingPartners[value]
                        && (this.bankingPartners[value].enabled
                            || this.bankingPartners[value].rapidconnectMid
                            || this.bankingPartners[value].rapidconnectTid);

                    // we allow to update banking partner settings only if
                    // banking partner is globally enabled OR if it's configured already
                    const isEditable = globally_enabled || isConfigured;

                    return {
                        type: 'json-group',
                        name: value,
                        additional: true,
                        group: [
                            {
                                label: label,
                                type: 'section-header',
                                name: value
                            },
                            {
                                label: value + ' RapidConnect MID',
                                type: 'input',
                                name: 'rapidconnectMid',
                                pattern: '^' + value + 'MID[0-9A-Za-z]{5,100}$',
                                readonly: !isEditable
                            },
                            {
                                label: value + ' RapidConnect TID',
                                type: 'input',
                                name: 'rapidconnectTid',
                                pattern: '^' + value + 'TID[0-9]{8,8}$',
                                readonly: !isEditable
                            },
                            {
                                type: 'checkbox',
                                label: 'Enabled',
                                name: 'enabled',
                                readonly: !isEditable
                            }
                        ]
                    };
                })
            }
        ]);

        this.relations = modelRelations([
            {
                crmModule: 'firms',
                crmAlias: 'firm',
                params: this.firmId,
                resource: 'firm'
            },
            {
                crmModule: 'banks',
                params: { firmId: this.firmId },
                resource: 'bank'
            },
            {
                crmModule: 'firm-fees',
                params: { firmId: this.firmId },
                resource: 'firmFee'
            },
            {
                crmModule: 'audit-logs',
                params: { entity_type: 'firm_settings', entity_id: this.id },
                resource: 'auditLog'
            }
        ]);

        this.configCreate = modelConfig([
            {
                type: 'input',
                name: 'firmId'
            },
            {
                type: 'json',
                name: 'stripeSubscription'
            },
            {
                type: 'json',
                name: 'paymentReminderSchedule'
            },
            {
                type: 'json',
                name: 'allowedPaymentTypes',
                defaultValue: {
                    FAST_PAY: [
                        'ACH',
                        'CARD'
                    ],
                    PORTAL: [
                        'ACH',
                        'CARD'
                    ]
                }
            }
        ]);
    }

    get firmName(): string {
        return this.firm.name;
    }

    get firmEmail(): string {
        return this.firm.email;
    }

    get getFirmSettingsDefaultFraudRulesAsync(): Function {
        return getFirmSettingsDefaultFraudRules;
    }

    get detailsDisplayName(): string {
        return `Firm "${this.firmName}" > Firm Settings`;
    }

    getBankingPartnersOverviewFields() {
        return (this.bankingPartnerList || [])
            // Filtered turned off in hp-common
            .filter(({ globally_enabled }) => globally_enabled)
            .map(({ value, label }) => ({
                label,
                name: 'banking-partner-' + value,
                stayVisible: true,
                transformer: () => this.enabledDisabled(
                    this.bankingPartners
                    && this.bankingPartners[value]
                    && this.bankingPartners[value].enabled
                )
            }));
    }

    // transformer for the fields which say Enabled/Disabled
    enabledDisabled (value, enabledLabel = 'Enabled', disabledLabel = 'Disabled') {
        return value
            ? `<span class="firm-settings-feature-enabled">${enabledLabel}</span>`
            : `<span class="firm-settings-feature-disabled">${disabledLabel}</span>`;
    }

    get titlesFragment(): string {
        return `
                <tr class="tr-fraud-rules">
                    <th class="th-fraud-rules rule-name-overview">Rule</th>
                    <th class="th-fraud-rules rule-option-overview">Config</th>
                    <th class="th-fraud-rules rule-option-overview-big">Value</th>
                    <th class="th-fraud-rules rule-option-overview">Enabled</th>
                </tr>
            `;
    }

    rulesFragment({
        ruleName,
        config,
        isEnabled
    }): string {
        return `
                <tr class="tr-fraud-rules">
                    <td class="td-fraud-rules rule-name-overview bold-rule">
                        <div class="rule-name-value">
                            ${ruleName}
                        </div>
                    </td>
                    <td class="td-fraud-rules rule-option-overview bold-rule">
                        ${this.configReducer(config)}
                    </td>
                    <td class="td-fraud-rules rule-option-overview-big">
                        ${this.configReducer(config, 'value')}
                    </td>
                    <td class="td-fraud-rules rule-option-overview rule-enabling">${isEnabled ? 'Yes' : 'No'}</td>
                </tr>
            `;
    }

    configFragment({ value, isDefault = false}) {
        return `
                <div class="rule-value">
                ${value}
                ${isDefault ? '<small class="text-muted">(default)</small>' : ''}
                </div>
            `;
    }

    ruleReducer = (prev, current): string => {
        const { ruleName, config, isEnabled } = current;
        const params = { ruleName, config, isEnabled };

        const htmlFragment = this.rulesFragment(params);
        return prev + htmlFragment;
    };

    configReducer(config, reduceProp: 'key'|'value' = 'key'): string {
        return config.reduce((prev, option) => {
            const htmlFragment = this.configFragment({
                ...(reduceProp === 'key') && {value: option.key},
                ...(reduceProp === 'value') && { value: option.value, isDefault: option.isDefault }
            });
            return prev + htmlFragment;
        }, '');
    }

    // eslint-disable-next-line @typescript-eslint/member-ordering
    static filterFields(): Field[] {
        return modelConfig([
            {
                type: 'select',
                name: 'category',
                label: 'Show',
                cssClasses: 'col-sm-6 min-width-400',
                options: {
                    '': 'All Firm Settings',
                    'FIRMS_WITH_NETWORK_TOKENS_ENABLED': 'Firms with Network Tokens enabled',
                    'FIRMS_WITH_BATCH_PAYOUTS': 'Firms with batch payout processing',
                    'FIRMS_WITH_BATCH_FEES': 'Firms with batch fee processing',
                    'FIRMS_WITH_LEVEL_23_ENABLED': 'Firms with level 2/3 data enabled',
                    'FIRMS_WITH_CUSTOM_CARD_TRANSACTION_LIMIT': 'Firms with custom Credit Card transactions limit',
                    'FIRMS_WITH_CUSTOM_ACH_TRANSACTION_LIMIT': 'Firms with custom ACH transactions limit'
                }
            },
            {
                type: 'input',
                name: 'achPayoutDelay',
                cssClasses: 'margin-right-20',
            },
            {
                type: 'input',
                name: 'rapidconnectPayoutDelay',
                cssClasses: 'margin-right-20',
            },
            {
                type: 'datepicker',
                name: 'createdFrom',
                cssClasses: 'filter-date-input',
            },
            {
                type: 'datepicker',
                name: 'createdTo',
                cssClasses: 'filter-date-input',
            }
        ]);
    }
}
