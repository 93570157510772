import { Component, OnInit } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../../authentication/authentication.service';
import { Field, FieldEncrypted } from '@app/shared';
import { Debounce } from '../../../../utils';

@Component({
    selector: 'aw-form-encrypted',
    styleUrls: ['./form-encrypted.component.scss'],
    templateUrl: './form-encrypted.component.html'
})

export class FormEncryptedComponent implements OnInit {
    field: FieldEncrypted & Field;
    group: FormGroup;
    getDecrypted: Function;
    isEncrypted: boolean;
    isLoading: boolean;
    isFocused: boolean;
    value: string;

    constructor(
        private authenticationService: AuthenticationService,
        private route: ActivatedRoute
    ) {
        this.isLoading = false;
        this.isFocused = false;
    }

    ngOnInit() {
        if (this.control.value) {
            this.isEncrypted = true;
            this.value = '**********';
        } else {
            this.isEncrypted = false;
            this.value = '';
        }
    }

    get control(): AbstractControl {
        return this.group.controls[this.field.name];
    }

    @Debounce()
    private patchForm(value: string) {
        this.control.patchValue(value);
        this.control.markAsDirty();
    }

    async decrypt () {
        if (this.isEncrypted) {
            this.isLoading = true;
            const name = this.field.name;
            const firmId = this.route.snapshot.params['id'];
            const getDecryptedAsync = this.field.getDecrypted;
            const token = await this.authenticationService.getAccessToken().toPromise();

            if (getDecryptedAsync) {
                const result = await getDecryptedAsync(token, firmId, name);
                this.patchForm(result[name]);
                this.isLoading = false;
                this.isEncrypted = false;
                this.value = result[name];
            }
        }
    }

    onValueChange (changedValue: string) {
        this.value = changedValue;

        // DELAY patchForm in case we clear existing value in field
        // to prevent angular material input label floating bug
        // Value stil will be cleared on focusOut event
        if (!(this.control.value && this.control.value.length === 1 && this.value === '')) {
            this.patchForm(changedValue);
        }
    }

    onFocus () {
        this.isFocused = true;
    }

    onFocusOut () {
        this.isFocused = false;

        // resolve DELAYED patchForm value in case we clear existing value in the field
        if (this.control.value && this.control.value.length === 1 && this.value === '') {
            this.patchForm(this.value);
        }
    }
}

