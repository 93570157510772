import { ListPageOptions, DynamoDbListOptions } from '../list/list.interfaces';

export class DynamoDbListComponent {

    public dynamoDbPagination = true;

    /** Here we store startFrom values for the pages */
    private startFromValues = {};

    /**
     * This function adds "order" and "startFrom" value to the options
     * It gets "startFrom" value for pages from startFromValues cache
     * @param options
     */
    prepareOptions(options: ListPageOptions): DynamoDbListOptions {
        const currentOptions: DynamoDbListOptions = {
            ...options,
            order: 'DESC',
            startFrom: ''
        };

        if (options.pageIndex === 0) {
            currentOptions.startFrom = '';
            this.resetStartFromValues();
        } else {
            currentOptions.startFrom = this.getStartFromForThePage(options.pageIndex);
        }

        // If filter params changed
        return currentOptions;
    }

    /**
     * After fetching data for the page @pageIndex we get @last_evaluated_key
     * and store it to this.startFromValues
     *
     * @param {number} pageIndex
     * @param {string} last_evaluated_key
     */
    saveLastEvaluatedKey(pageIndex: number, last_evaluated_key: string = ''): void {
        this.startFromValues[pageIndex + 1] = last_evaluated_key;
    }

    private resetStartFromValues(): void {
        this.startFromValues = {};
    }

    private getStartFromForThePage(pageIndex: number): string {
        return this.startFromValues[pageIndex] || '';
    }
}
