import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { SearchFieldComponent } from './search-field.component';

@NgModule({
    imports: [MatIconModule],
    exports: [SearchFieldComponent],
    declarations: [SearchFieldComponent]
})
export class SearchFieldModule { }
