import { ComponentFactoryResolver, Directive, Input, OnInit, ViewContainerRef } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Field } from '../../data';
import { components } from './components';

@Directive({
    selector: '[awDynamicField]'
})
export class DynamicFieldDirective implements OnInit {
    @Input()
        field: Field;

    @Input()
        group: FormGroup;

    @Input()
        buttonSubmitText: string;

    constructor(
        private resolver: ComponentFactoryResolver,
        private container: ViewContainerRef
    ) { }

    ngOnInit() {
        // ! TODO warning  'ComponentFactoryResolver' is deprecated. Angular no longer requires Component factories.
        // Please use other APIs where Component class can be used directly deprecation/deprecation
        // ! TODO warning  'createComponent' is deprecated. Angular no longer requires component factories to dynamically create components.
        // Use different signature of the `createComponent` method, which allows passing
        // Component class directly  deprecation/deprecation
        const Type = components[this.field.type];
        const factory = this.resolver.resolveComponentFactory(Type);
        const component: any = this.container.createComponent(factory);

        component.instance.field = this.field;
        component.instance.group = this.group;
        component.instance.buttonSubmitText = this.buttonSubmitText;
    }
}
