import { OnInit, OnDestroy, ViewChild, HostListener, Directive } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';

import { SidenavService } from './sidenav.service';

@Directive()
export class SidenavComponent implements OnInit, OnDestroy {

  @ViewChild('sidenav') sidenav: MatSidenav;

  screenWidth: number = window.innerWidth;

  constructor(private sidenavService: SidenavService) {}

  ngOnInit() {
      this.setSidenav();
  }

  ngOnDestroy() {
      this.resetSidenav();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
      this.screenWidth = event.target.innerWidth;
      this.setSidenav();
  }

  private setSidenav() {
      if (this.screenWidth > 840) {
          this.sidenavService.setSidenav(null);
      } else {
          this.sidenavService.setSidenav(this.sidenav);
      }
  }

  private resetSidenav() {
      this.sidenavService.setSidenav(null);
  }

}
