<div class="dynamic-field form-input" class="{{ field.cssClasses }}"
    [formGroup]="group">

    <mat-form-field class="custom_form__field" appearance="fill"
        [ngClass]="{
            'mat-form-field-should-float': !isLoading && !isEncrypted && (isFocused || value.length > 0),
            'mat-encrypted-field': true}">

        <mat-label>{{ !isLoading && !isEncrypted && value === '' && !isFocused ? '\u00A0\u00A0\u00A0\u00A0\u00A0' + field.label : field.label }}</mat-label>
        <mat-icon
            class="mat-list-icon mat-icon material-icons icon-encrypted icon-pointer"
            matlisticon=""
            role="img"
            aria-hidden="true"
            (click)="decrypt()"
            *ngIf="isEncrypted && !isLoading">
                lock
        </mat-icon>

        <mat-icon
            class="mat-list-icon mat-icon material-icons icon-decrypted"
            matlisticon=""
            role="img"
            aria-hidden="true"
            *ngIf="!isEncrypted && !isLoading">
                lock_open
        </mat-icon>

        <input
            class="mat-input-element mat-form-field-autofill-control cdk-text-field-autofill-monitored ng-valid encrypted-input"
            aria-invalid="false"
            aria-required="false"
            *ngIf="!isLoading"
            [id]="'encryptedInput-' + field.name"
            [type]="isEncrypted ? 'password' : 'text'"
            [value]="value"
            [readonly]="isEncrypted || isLoading"
            (input)="onValueChange($event.target.value)"
            (focus)="onFocus()"
            (focusout)="onFocusOut()"
            (click)="decrypt()">

        <mat-spinner class="" *ngIf="isLoading" diameter="13"></mat-spinner>

        <label>
            <input
                #baseInput
                style="display:none"
                matInput
                type="text"
                [formControlName]="field.name"
                [readonly]="isEncrypted || isLoading"
                placeholder="">
        </label>
    </mat-form-field>
</div>

