<h3>Overview</h3>
<p class="d-flex justify-content-between {{field.cssClasses}}" *ngFor="let field of fields">
  <span *ngIf="!field.hideLabel" class="semibold d-flex justify-content-center align-items-center">
      <span *ngIf="!field.isSectionHeader">{{ label(field) }}:</span>
      <span class="section-header" *ngIf="field.isSectionHeader">{{ label(field) }}</span>
  </span>
  <span *ngIf="!field.isFilePreview && !field.isSectionHeader"
        [innerHtml]="value(field) | transformer: getTransformer(field) ">
  </span>
  <span *ngIf="field.isFilePreview">
      <aw-file-preview [src]="value(field)"></aw-file-preview>
  </span>
</p>
