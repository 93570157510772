import { normalizeLabel } from '../utils';
import { FieldConfig, RelationConfig, NonRelationConfig, Relation, Field } from './types';

export function modelConfig(fields: FieldConfig[]): Field[] {
    return fields.map((field: Field) => ({
        ...field,
        label: field.label || normalizeLabel(field.name),
        group: (field as any).group ? modelConfig((field as any).group) : null
    }));
}

export function modelRelations(relations: RelationConfig[]): Relation[] {
    return relations.map((relation: RelationConfig) => ({
        name: relation.crmAlias ? normalizeLabel(relation.crmAlias) : normalizeLabel(relation.crmModule),
        routerLink: [`/crm/${relation.crmModule}`, relation.params],
        hidden: relation.hidden,
        resource: relation.resource,
        disabled:  relation.disabled
    }));
}

export function nonCrmRelations(relations: NonRelationConfig[]): Relation[] {
    return relations.map((relation: NonRelationConfig) => ({
        name: normalizeLabel(relation.alias),
        routerLink: [`/${relation.module}`, relation.params],
        hidden: relation.hidden,
        resource: relation.resource
    }));
}
