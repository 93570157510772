import { Component, Inject, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

import { DialogService, MAT_DIALOG_DATA } from '../../dialog';
import { Model } from '../../data';
import { normalizeLabel } from '../../utils';
import { DynamicFormService } from '../dynamic-form';
import { Observable } from 'rxjs';

type ConfirmDialogMessageLevel = 'note' | 'warning' | 'red-warning';

export interface ConfirmDialogMessage {
    text: string;
    level: ConfirmDialogMessageLevel;
}

export interface DialogData {
    changedData: object;
    oldValues?: object;
    model: Model;
    messages: ConfirmDialogMessage[],
    onSave: <T>() => Observable<T>;
}

@Component({
    selector: 'aw-confirm-dialog',
    template: `
        <h5 mat-dialog-title>
        Changed Fields:
        </h5>

        <mat-dialog-content>
        <mat-table [dataSource]="dataSource">
            <ng-container matColumnDef="label">
            <mat-header-cell *matHeaderCellDef>Field Name</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.label }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="oldValue">
            <mat-header-cell *matHeaderCellDef>Old Value</mat-header-cell>
            <mat-cell *matCellDef="let element">
                <pre>{{ element.oldValue | json }}</pre>
            </mat-cell>
            </ng-container>

            <ng-container matColumnDef="newValue">
            <mat-header-cell *matHeaderCellDef>New Value</mat-header-cell>
            <mat-cell *matCellDef="let element">
                <pre>{{ element.newValue | json }}</pre>
            </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
        </mat-dialog-content>

        <p *ngFor="let message of messages" [class]="'user-message user-message-' + message.level">
            <mat-icon>{{ getIconForMessage(message.level) }}</mat-icon>
            <span [innerHTML]="message.text"></span>
        </p>

        <p *ngIf="errorMessage" class="error-message">
            {{ errorMessage }}
        </p>

        <mat-dialog-actions class="mt-3 mb-1">
            <button
                *ngIf="!isSaving"
                mat-raised-button
                (click)="dismiss()">
                Cancel
            </button>

            <button
                mat-raised-button
                color="warn"
                [disabled]="isSaving"
                (click)="onSave()">
                {{ buttonText }}
            </button>
        </mat-dialog-actions>
    `,
    styles: [`
        p {
            white-space: pre-line;
            text-align: center;
        }
        .user-message {
            text-align: left;
            padding: 10px;
            font-size: 15px;
        }
        .user-message mat-icon {
            vertical-align: middle;
            margin-right: 10px;
            margin-top: -5px;
            float: left;
        }
        /** Message level 'note' **/
        .user-message.user-message-note {
            border: 1px solid #54bb75;
            background-color: #54bb7563;
            color: #327b5e;
        }
        .user-message.user-message-note mat-icon {
            color: #327b5e;
        }
        /** Message level 'warning' **/
        .user-message.user-message-warning {
            border: 1px solid orange;
            background-color: #ffa5004f;
            color: #ab720e;
        }
        .user-message.user-message-warning mat-icon {
            color: orange;
        }
        /** Message level 'red-warning' **/
        .user-message.user-message-red-warning {
            border: 1px solid #ec1330;
            background-color: #fcc1ce82;
            color: #f64f74;
        }
        .user-message.user-message-red-warning mat-icon {
            color: #ec1330;
        }
    `],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmDialogComponent {

    displayedColumns: string[] = ['label', 'oldValue', 'newValue'];
    dataSource = [];

    messages: ConfirmDialogMessage[];
    errorMessage = '';
    isSaving = false;

    constructor(
        private dialogService: DialogService,
        @Inject(MAT_DIALOG_DATA) private data: DialogData,
        private dynamicFormService: DynamicFormService,
        private cdr: ChangeDetectorRef
    ) {
        this.dataSource = this.changedData;
        this.messages = this.data.messages || [];
    }

    get changedData(): object[] {
        return Object.keys(this.data.changedData)
            .map((key) => ({
                label: normalizeLabel(key),
                isJSON: typeof this.data.model[key] === 'object',
                oldValue: this.getOldData(key),
                newValue: this.data.changedData[key]
            }));
    }

    get buttonText() {
        if (this.isSaving) return 'Please wait, saving...';
        return 'Save changes';
    }

    getIconForMessage(level: ConfirmDialogMessageLevel) {
        if (level === 'note') return 'error_outline';
        return 'warning';
    }

    onSave<T>() {
        if (this.data.onSave) {
            this.isSaving = true;
            this.data.onSave<T>().subscribe((data: T) => {
                this.isSaving = false;
                this.close<T>(data);
            }, (err) => {
                this.isSaving = false;
                this.errorMessage = err;
                // force component to re-render
                // (it was not re-rendering by some reason)
                this.cdr.detectChanges();
            });
        } else {
            this.close();
        }
    }

    dismiss() {
        if (this.errorMessage) {
            // just close the popup because user needs to fix something
            this.dialogService.dismiss();
        } else {
            // reset form, user canceled
            this.dynamicFormService.resetForm();
            this.dynamicFormService.setPristine();
            this.dialogService.dismiss();
        }
    }

    close<T>(data?: T) {
        this.dialogService.close(data);
    }

    getOldData(key) {
        const oldValues = this.data.oldValues || {};
        const oldValueOriginal = oldValues[key] || this.data.model[key];
        if (oldValueOriginal
            && typeof oldValueOriginal === 'object'
            && oldValueOriginal.hasOwnProperty('type')
            && oldValueOriginal.type === 'Buffer') {
            return 'Old Encrypted Value';
        }

        return oldValueOriginal;
    }
}
