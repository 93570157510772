import { FormCheckboxComponent } from './form-checkbox/form-checkbox.component';
import { FormInputComponent } from './form-input/form-input.component';
import { FormSelectComponent } from './form-select/form-select.component';
import { FormAutocompleteComponent } from './form-autocomplete/form-autocomplete.component';
import { FormJsonComponent } from './form-json/form-json.component';
import { FormJsonGroupComponent } from './form-json-group/form-json-group.component';
import { FormDatepickerComponent } from './form-datepicker/form-datepicker.component';
import { FormTextareaComponent } from './form-textarea/form-textarea.component';
import { FormFileComponent } from './form-file/form-file.component';
import { FormImageComponent } from './form-image/form-image.component';
import { FormFraudRulesComponent } from './form-fraud-rules/form-fraud-rules.component';
import { FormEncryptedComponent } from './form-encrypted/form-encrypted.component';
import { FormSectionHeaderComponent } from './form-section-header/form-section-header.component';

const components = {
    autocomplete: FormAutocompleteComponent,
    input: FormInputComponent,
    select: FormSelectComponent,
    checkbox: FormCheckboxComponent,
    json: FormJsonComponent,
    datepicker: FormDatepickerComponent,
    textarea: FormTextareaComponent,
    file: FormFileComponent,
    'json-group': FormJsonGroupComponent,
    image: FormImageComponent,
    'fraud-rules': FormFraudRulesComponent,
    encrypted: FormEncryptedComponent,
    'section-header': FormSectionHeaderComponent
};

export {
    components,
    FormCheckboxComponent,
    FormInputComponent,
    FormSelectComponent,
    FormAutocompleteComponent,
    FormJsonComponent,
    FormDatepickerComponent,
    FormTextareaComponent,
    FormFileComponent,
    FormJsonGroupComponent,
    FormImageComponent,
    FormFraudRulesComponent,
    FormEncryptedComponent,
    FormSectionHeaderComponent
};
