import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpResponse, HttpEventType, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';

import { LoadingService } from './loading.service';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {

    constructor(private loadingService: LoadingService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.loadingService.showLoading();

        return next.handle(req)
            .pipe(
                tap((res: HttpResponse<any>) => {
                    if (res.type === HttpEventType.Response) {
                        this.loadingService.hideLoading();
                    }
                }),
                catchError(err => {
                    this.loadingService.hideLoading();

                    return throwError(() => err);
                })
            );
    }

}
