import { Model, Resource, OverviewField, modelConfig } from '../data';

export class AppSettings extends Model {
    resourceName: Resource = 'appSettings';

    id: string;
    paymentsMaintenanceModeIsOn: Boolean;
    bankAccountValidationCardId: string;

    overviewFields: Array<OverviewField | string> = [];

    config = modelConfig([
        {
            type: 'checkbox',
            name: 'paymentsMaintenanceModeIsOn',
            hint: 'This will stop creation of all new payments and pause all payment processing via cronjobs. ' +
                'Partners will get an error message "Payments is currently undergoing maintenance. ' +
                'Please try again later.'
        },
        {
            type: 'input',
            name: 'bankAccountValidationCardId',
            showLabelAbove: true
        },
    ]);

    constructor(attributes: Partial<AppSettings> = {}) {
        super(attributes);
        this.update(attributes);
    }
}
