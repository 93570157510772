import { Component, Input } from '@angular/core';

@Component({
    selector: 'aw-page-heading',
    styleUrls: ['./page-heading.component.scss'],
    template: `
    <div class="page-header">
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <ng-content select="h1"></ng-content>
        </div>
        <div class="flex-shrink-1">
          <ng-content select=".actions"></ng-content>
        </div>
      </div>
    </div>
  `
})
export class PageHeadingComponent {
  @Input() title: string;
}
