import { searchFirm } from '../constants';
import { Model, Resource, modelConfig, modelRelations } from '../data';
import { formatDate } from '../utils';

export class FirmPromo extends Model {
    resourceName: Resource = 'firmPromo';

    id: string;
    promo_id: string;
    firm_id: string;
    status: string;
    description: string;
    redeemed_amount_in_cents: string;
    create_date: string; // ISO8601 string
    redeemed_date: string; // ISO8601 string
    credit_transaction_id: string;

    promo: {
        name: string;
        type: string;
        expiration_date: string;
    };

    firm: {
        name: string;
    };

    constructor(attributes = {}) {
        super(attributes);
        super.update(attributes);

        this.overviewFields = [
            {
                label: 'Promo name',
                name: 'promo_id',
                transformer: (value: string, model: any) => model.promo.name,
            },
            {
                label: 'Promo type',
                name: 'promo_id',
                transformer: (value: string, model: any) => model.promo.type,
            },
            {
                label: 'Promo expiration date',
                name: 'promo_id',
                transformer: (value: string, model: any) => formatDate(model.promo.expiration_date),
            },
            'status',
            {
                label: 'Firm name',
                name: 'firm_id',
                transformer: (value: string, model: any) => model.firm.name,
            },
            'description',
            {
                label: 'Redeemed date',
                name: 'redeemed_date'
            },
            'redeemed_amount_in_cents',
            {
                label: 'Credit transaction',
                name: 'credit_transaction_id',
                transformer: <FirmPromo>(value, model) => {
                    const transaction_id = model.credit_transaction_id;
                    return `<a href='/crm/transactions/${transaction_id}'>${transaction_id}</a>`;
                }
            }
        ];
        this.config = modelConfig([
            {
                type: 'input',
                name: 'promo_id',
                readonly: true
            },
            {
                type: 'input',
                name: 'firm_id',
                readonly: true
            },
            {
                type: 'select',
                name: 'status',
                options: {
                    url: '/firm-promos/statuses'
                }
            },
            {
                type: 'textarea',
                name: 'description'
            },
            {
                type: 'input',
                name: 'redeemed_date',
                readonly: true,
            },
            {
                type: 'input',
                name: 'redeemed_amount_in_cents',
                readonly: true,
            },
            {
                type: 'input',
                name: 'credit_transaction_id',
                readonly: true,
            },
            {
                type: 'input',
                name: 'create_date',
                readonly: true,
            }
        ]);
        this.configCreate = modelConfig([
            {
                type: 'select',
                name: 'promo_id',
                label: 'Promo*',
                options: {
                    url: '/promos/names?pageSize=50'
                }
            },
            {
                type: 'autocomplete',
                name: 'firm_id',
                label: 'Firm',
                getOptions: this.getFirmOptionsAsync
            },
            {
                type: 'select',
                name: 'status',
                options: {
                    url: '/firm-promos/statuses'
                },
                label: 'Status*'
            },
            {
                type: 'textarea',
                name: 'description'
            }
        ]);
        this.relations = modelRelations([
            {
                crmModule: 'firm-promos',
                crmAlias: 'firm-promos-by-promo-id',
                params: { promoId: this.promo_id },
                resource: 'firmPromo'
            },
            {
                crmModule: 'firm-promos',
                crmAlias: 'firm-promos-by-firm-id',
                params: { firmId: this.firm_id },
                resource: 'firmPromo'
            },
            {
                crmModule: 'promos',
                crmAlias: 'promo',
                params: this.promo_id,
                resource: 'promo'
            },
            {
                crmModule: 'firms',
                crmAlias: 'firm',
                params: this.firm_id,
                resource: 'firm'
            },
            {
                crmModule: 'transactions',
                params: this.credit_transaction_id,
                resource: 'transaction'
            },
            {
                crmModule: 'audit-logs',
                params: { entity_type: 'firm_promo', entity_id: this.id },
                resource: 'auditLog'
            }
        ]);
    }

    get getFirmOptionsAsync(): any {
        return searchFirm;
    }

    get detailsDisplayName(): string {
        return `Firm "${this.firm.name}" promo "${this.promo.name}"`;
    }
}
