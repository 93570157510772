import { Model, Resource, modelConfig, modelRelations, Field } from '../data';
import { formatAmount } from '../utils';
import { NACHA_FILE } from '../constants';

export class NachaFile extends Model {
    resourceName: Resource = 'nachaFile';

    id: string;
    seq_no: number;
    s3_filename: string;
    batch_id: string;
    bank_account_type: string;
    banking_partner_id: string;
    category: keyof typeof NACHA_FILE.CATEGORY;
    date: Date;
    created_at: Date;
    updated_at: Date;
    status: keyof typeof NACHA_FILE.STATUS;
    cnt_transactions: number;
    total_in_cents: number;
    is_reprocessing_requested: boolean;
    metadata?: {
        migrated_from_postgres: boolean;
    };

    get isBatchNachaFile() {
        return this.category && ['BATCH_PAYOUTS', 'BATCH_FEES'].includes(this.category);
    }

    get migratedFromPostgres() {
        return this.metadata && this.metadata.migrated_from_postgres;
    }

    constructor(attributes = {}) {
        super(attributes);
        super.update(attributes);

        this.overviewFields = [
            'banking_partner_id',
            {
                name: 'batch_id',
                cssClasses: 'bold'
            },
            'date',
            'seq_no',
            {
                name: 'category',
                label: 'Category',
            },
            'bank_account_type',
            {
                name: 'status',
                label: 'Status',
            },
            {
                label: 'Re-processing',
                name: 'is_reprocessing_requested',
                transformer: (is_reprocessing_requested) => is_reprocessing_requested ? '<b>Re-processing requested</b>' : null
            },
            'error_message',
            'filename',
            {
                label: 'S3 Filename',
                name: 's3_filename'
            },
            {
                label: 'Nacha File Content',
                name: 'metadata',
                transformer: <NachaFile>(metadata, model) => {
                    if (!metadata.transactions) return;
                    if (!metadata.transactions.length) return;
                    // build list which describes NACHA File content
                    let contentDescription = '<ul>';
                    contentDescription += `<li><b>This Nacha File Contains</b></li>`;
                    metadata.transactions.forEach(t => {
                        contentDescription += `<li>${t.firm} - ${formatAmount(t.total_in_cents / 100, 'USD')} ${t.category}</li>`;
                    });
                    contentDescription += `<li><b>Total: ${formatAmount(model.total_in_cents / 100, 'USD')}</b></li>`;
                    contentDescription += '</ul>';
                    return contentDescription;
                }
            },
            'cnt_transactions',
            {
                name: 'total_in_cents',
                label: 'Total',
                transformer: (value) => (value && formatAmount(value / 100, 'USD'))
            },
            {
                name: 'created_at',
                transformer: 'formatDate'
            },
            {
                name: 'updated_at',
                transformer: 'formatDate'
            }
        ];
        this.config = modelConfig([
            {
                type: 'input',
                name: 'id',
                readonly: true
            },
            {
                type: 'input',
                name: 'date',
                readonly: true
            },
            {
                type: 'input',
                subType: 'number',
                name: 'seq_no',
                readonly: true
            },
            {
                type: 'input',
                name: 'batch_id',
                readonly: true
            },
            {
                type: 'input',
                name: 'category',
                readonly: true
            },
            {
                type: 'select',
                name: 'status',
                options: {
                    url: '/nacha-files/statuses'
                }
            },
            {
                type: 'datepicker',
                name: 'date',
                readonly: true
            },
            {
                type: 'input',
                name: 'created_at',
                readonly: true,
            },
            {
                type: 'input',
                name: 'updated_at',
                readonly: true,
            },
            {
                label: 'Metadata',
                name: 'metadata',
                type: 'json',
                readonly: true,
                additional: true,
            }
        ]);
        this.relations = modelRelations([
            {
                crmModule: 'transactions',
                crmAlias: 'Transactions',
                params: { batchId: this.batch_id },
                resource: 'transaction',
                hidden: !this.migratedFromPostgres
            },
            {
                crmAlias: 'Batch Transactions',
                crmModule: 'nacha-transactions',
                params: { nachaFileId: this.id },
                resource: 'nachaTransaction',
                hidden: !this.isBatchNachaFile || !this.migratedFromPostgres
            },
            {
                crmAlias: 'Currency Transfers',
                crmModule: 'currency-transfers',
                params: { nacha_file_id: this.id },
                resource: 'currencyTransfer',
                hidden: this.migratedFromPostgres
            },
            {
                crmModule: 'audit-logs',
                params: { entity_type: 'nacha_file', entity_id: this.id },
                resource: 'auditLog'
            }
        ]);
    }

    get detailsDisplayName(): string {
        return `Nacha File: ${this.id}`;
    }

    static filterFields(): Field[] {
        return modelConfig([
            {
                type: 'input',
                name: 'batch_id',
                label: 'Find by Batch ID',
                cssClasses: 'col-sm-4 min-width-200'
            },
            {
                type: 'select',
                name: 'category',
                label: 'Filter by category',
                cssClasses: 'col-sm-4 min-width-200',
                options: {
                    PAYMENT: 'PAYMENT',
                    PAYOUT: 'PAYOUT',
                    FEE: 'FEE',
                    BATCH_FEES: 'BATCH_FEES',
                    BATCH_PAYOUTS: 'BATCH_PAYOUTS',
                    REFUND_DEDUCTION: 'REFUND_DEDUCTION',
                    REFUND_DISBURSE: 'REFUND_DISBURSE',
                    CREDIT: 'CREDIT',
                    CHARGEBACK: 'CHARGEBACK',
                    FEE_TRANSFER: 'FEE_TRANSFER',
                }
            },
            {
                type: 'select',
                name: 'status',
                label: 'Find by status',
                cssClasses: 'col-sm-4 min-width-200',
                options: {
                    url: '/nacha-files/statuses'
                }
            },
            {
                type: 'datepicker',
                name: 'createdFrom',
                cssClasses: 'filter-date-input',
            },
            {
                type: 'datepicker',
                name: 'createdTo',
                cssClasses: 'filter-date-input',
            }
        ]);
    }
}
