import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../../authentication/authentication.service';
import { Field, FieldFraudRules } from '../../../../data';
import { FraudRule, FraudConfig, FRAUD_ALERT_CENT_VALUES, FRAUD_ALERT_PERCENT_VALUES } from '../../../../../shared';
import { FormGroup, AbstractControl } from '@angular/forms';
import { Debounce, validateNumberValue, copyValue } from '../../../../utils';
import * as _ from 'lodash';

@Component({
    selector: 'aw-form-fraud-rules',
    styleUrls: ['./form-fraud-rules.component.scss'],
    templateUrl: './form-fraud-rules.component.html',
})
export class FormFraudRulesComponent implements OnInit {
    field: Field;
    group: FormGroup;
    getOptions: Function;
    options: FraudRule[];
    defaultOptions: FraudRule[];

    constructor(private authenticationService: AuthenticationService) {}

    ngOnInit() {
        this.compareAndMapRules();

        // listen for changes
        this.group.controls[this.field.name].valueChanges.subscribe((data) => {
            this.compareAndMapRules(true, data);
        });
    }

    async compareAndMapRules(
        isChanged?: boolean,
        changedValue?: FraudRule[]
    ): Promise<FraudRule[]> {
        const field = this.field as FieldFraudRules;
        const getOptionsAsync = field.getOptions;

        if (getOptionsAsync && !this.options) {
            const token = await this.authenticationService
                .getAccessToken()
                .toPromise();
            const result = await getOptionsAsync(token);
            this.defaultOptions = result.map((option: FraudRule) => {
                option.config = this.configCerntsToDollars(option.config);
                return option;
            }) as FraudRule[];
        }
        const currentValue = copyValue<FraudRule[]>(
            isChanged ? changedValue : this.currentValue
        );
        this.options = [];

        for (let index = 0; index < this.defaultOptions.length; index++) {
            const el = copyValue<FraudRule>(this.defaultOptions[index]); // Two way data binding

            if (currentValue) {
                const overrideOriginalRule = currentValue.find((override) => override.ruleName === el.ruleName);
                if (overrideOriginalRule) {
                    const overrideRule = copyValue<FraudRule>(overrideOriginalRule);
                    overrideRule.config = this.configCerntsToDollars(
                        overrideRule.config
                    );
                    this.options = [
                        ...this.options,
                        {
                            description: el.description,
                            config: { ...el.config, ...overrideRule.config },
                            isEnabled:
                                overrideRule.isEnabled === false ? false : true,
                            ruleName: el.ruleName,
                        } as FraudRule,
                    ];
                    continue;
                }
            }
            // Initial load and map
            this.options = [
                ...this.options,
                {
                    isEnabled: true,
                    ruleName: el.ruleName,
                    description: el.description,
                    config: el.config,
                },
            ];
        }

        return this.options.slice();
    }

    get currentValue(): any {
        return this.group.value[this.field.name];
    }

    get defaultValue(): FraudRule[] {
        return this.options;
    }

    get control(): AbstractControl {
        return this.group.controls[this.field.name];
    }

    validateValue(event: any) {
        validateNumberValue(event);
    }

    updateList(event: any, rule: string, property: string) {
        this.updateRuleOverrides(event, rule, property);
    }

    changeEnabling(event: any, rule: string) {
        this.updateRuleOverrides(event, rule);
    }

    updateRuleOverrides(event: any, rule: string, property?: string) {
        let currentValue = copyValue<FraudRule[]>(this.currentValue);
        const newValue = property ? event.target.textContent.trim() : undefined;
        let defaultRule = this.defaultOptions.find(
            (defaultConfig) => defaultConfig.ruleName === rule
        );
        let isRuleExists = false;

        if (property && defaultRule.config[property] === newValue) {
            // There is no changes, return
            return;
        }

        if (currentValue) {
            for (let index = 0; index < currentValue.length; index++) {
                const ruleOverride = currentValue[index];
                if (ruleOverride.ruleName !== rule) {
                    continue;
                }
                isRuleExists = true;

                if (property) {
                    // a) Content editable (config prop) change

                    // Check rule updating for it's equality to the default config, and clean it in case it is
                    // or reset to defaults if value is leaving blank
                    const isNewValueExist =
                        newValue === defaultRule.config[property] ||
                        newValue === '';
                    if (isNewValueExist) {
                        // New config prop value equals default, so delete it if exists
                        if (_.get(currentValue[index], 'config.' + property)) {
                            delete currentValue[index].config[property];
                        }

                        if (
                            Object.keys(currentValue[index].config).length < 1
                        ) {
                            // Whole rule config equals default rule config, so delete rule config
                            delete currentValue[index].config;
                            if (currentValue[index].isEnabled !== false) {
                                // Whole rule with new value equals default rule, this rule is redundant
                                currentValue.splice(index, 1);
                                break;
                            }
                        }
                    } else {
                        if (!currentValue[index].config) {
                            currentValue[index].config = {};
                        }
                        // Update current rule property value
                        const isCentProp =
                            FRAUD_ALERT_CENT_VALUES.includes(property);
                        currentValue[index].config[property] = isCentProp
                            ? parseFloat(newValue) * 100
                            : parseFloat(newValue);
                    }
                } else {
                    // b) isEnable checkbox change

                    // Check rule is completelly equals default
                    if (!currentValue[index].config && event.checked) {
                        currentValue.splice(index, 1);
                        break;
                    }
                    currentValue[index].isEnabled = event.checked;
                }
            }
        }

        if (!isRuleExists && !(property && newValue === '')) {
            defaultRule = this.defaultOptions.find(
                (defaultConfig) => defaultConfig.ruleName === rule
            );
            const uppdateRule = copyValue<FraudRule>(defaultRule);

            // Clear for support not only all config props override, but one also
            delete uppdateRule.config;
            if (property) {
                uppdateRule.config = {};
                if (FRAUD_ALERT_CENT_VALUES.includes(property)) {
                    // dollars to cents revert
                    uppdateRule.config[property] =
                        parseFloat(event.target.textContent.trim()) * 100;
                } else {
                    uppdateRule.config[property] =
                        event.target.textContent.trim();
                }
                uppdateRule.isEnabled = true;
            } else {
                uppdateRule.isEnabled = event.checked;
            }

            const val = currentValue || [];
            currentValue = [
                ...val,
                {
                    ruleName: rule,
                    ...(uppdateRule.config && {
                        config: { ...uppdateRule.config },
                    }),
                    isEnabled: uppdateRule.isEnabled,
                    description: uppdateRule.description,
                },
            ];
        }
        if (currentValue && currentValue.length !== 0) {
            this.patchForm(currentValue);
        } else {
            this.patchForm(null);
        }
    }

    @Debounce()
    private patchForm(value: object) {
        this.control.patchValue(value);
        this.control.markAsDirty();
    }

    getEditableClasses(property: string): string {
        if (FRAUD_ALERT_CENT_VALUES.includes(property)) {
            return 'editable-dollar';
        } else if (FRAUD_ALERT_PERCENT_VALUES.includes(property)) {
            return 'editable-percents';
        } else {
            return '';
        }
    }

    configCerntsToDollars(config?: FraudConfig): FraudConfig {
        if (config) {
            Object.keys(config).forEach((element) => {
                if (FRAUD_ALERT_CENT_VALUES.includes(element)) {
                    // 'shared.constants convertFromCents() is not used there because it returns value with dollar sign
                    const cents = parseInt(config[element].toString(), 10);
                    if (typeof cents === 'number' && cents !== 0) {
                        const dollars = cents / 100;
                        config[element] = parseFloat(
                            dollars.toString()
                        ).toFixed(2);
                    }
                }
            });
        }

        return config;
    }
}
