import { Model, Resource, modelConfig, modelRelations, Field } from '../data';

type Invoice = {
    surchargeEnabled: boolean;
    clientId: string;
    bankId: string;
    amount: number;
    bank?: {
        id: string;
        bankAccountType: string;
        friendlyName: string;
        last4: string;
    };
};
export class RecurringPayment extends Model {
    resourceName: Resource = 'recurringPayment';

    id: string;
    firmId: string;
    invoiceId: string;
    cardId: string;
    clientBankAccountId: string;
    currency: string;
    status: string;
    amount: number;
    confirmedDate: Date;
    createdAt: Date;
    updatedAt: Date;

    firm: {
        partnerId: string;
        name: string,
        partnerName: string,
    };

    invoice: Invoice;

    clientBankAccount?: {
        name: string;
        last4: string;
    };

    clientCard?: {
        name: string;
        last4: string;
    };
    private surchargeEnabled: boolean;

    constructor(attributes = {}) {
        super(attributes);
        super.update(attributes);

        this.amount = this.invoice.amount;
        this.computedFields = ['amount'];
        this.overviewFields = [
            'id',
            {
                label: 'Partner',
                name: 'firmId',
                transformer:
                    <RecurringPayment>(value, model) =>`<a href='/crm/partners/${model.firm.partnerId}'>${model.firm.partnerName}</a>`
            },
            {
                label: 'Firm',
                name: 'firmId',
                transformer: (value: string, model: any) => model.firm.name,
            },
            {
                label: 'Client',
                name: 'id',
                transformer: (value: string, model: any) => {
                    const client = model.invoice.client;
                    const clientName = client.company || `${client.firstName} ${client.lastName}`;
                    return `<a href="/crm/clients/${client.id}">${clientName}</a>`;
                },
            },
            {
                label: 'Reference ID',
                name: 'referenceId',
                stayVisible: true,
                transformer: (value: string) => value || '-'
            },
            {
                label: 'Payment method',
                name: 'paymentMethod'
            },
            {
                labelFunction: (model) => 'Source',
                name: 'id',
                transformer: (value: string, model: any) => {
                    if (model.clientCard) {
                        return `<a href="/crm/client-cards/${model.clientCard.id}">${model.clientCard.name}</a>`;
                    }
                    if (model.clientBankAccount) {
                        return `<a href="/crm/client-bank-accounts/${model.clientBankAccount.id}">` +
                            model.clientBankAccount.name + ' ' + model.clientBankAccount.last4 + `</a>`;
                    }
                    return `<i>Not provided yet</i>`;
                }
            },
            {
                labelFunction: (model) => 'Destination',
                name: 'id',
                transformer: (value: string, model: any) => {
                    if (!model.invoice || !model.invoice.bank) {
                        return '';
                    }
                    const archived = model.invoice.bank.archived ? ' (archived)' : '';

                    return `<a href="/crm/banks/${model.invoice.bankId}">` +
                      `${model.invoice.bank.bankAccountType} ${model.invoice.bank.friendlyName} ${model.invoice.bank.last4}${archived}` +
                      `</a>`;
                },
            },
            {
                label: 'Amount',
                name: 'amount',
                transformer: 'formatAmount',
                cssClasses: 'bold'
            },
            {
                label: 'Surcharge Enabled',
                name: 'id',
                transformer: (value: string, model: any) =>
                    model.invoice.surchargeEnabled ? 'YES' : 'NO'
            },
            {
                label: 'Start Date',
                name: 'startDate',
                transformer: (value: string, model: any) => value
            },
            {
                label: 'Installments',
                name: 'installments',
            },
            {
                label: 'interval',
                name: 'interval',
            },
            {
                label: 'intervalQuantity',
                name: 'intervalQuantity',
            },
            // {
            //   label: 'Due Date',
            //   name: 'id',
            //   transformer: (value: string, model: any) => {
            //     return moment(model.invoice.dueDate).format('MM/DD/YY');
            //   },
            // },
            'status',
            'createdAt',
            {
                name: 'confirmedDate',
                transformer: 'formatDate'
            }
        ];
        this.config = modelConfig([
            {
                type: 'input',
                name: 'id',
                readonly: true
            },
            {
                type: 'input',
                name: 'firmId',
                readonly: true
            },
            {
                type: 'input',
                name: 'invoiceId',
                readonly: true
            },
            {
                type: 'input',
                name: 'referenceId',
                readonly: true
            },
            {
                type: 'input',
                name: 'cardId',
                readonly: true
            },
            {
                type: 'input',
                name: 'clientBankAccountId',
                readonly: true
            },
            {
                type: 'input',
                name: 'startDate',
                readonly: true
            },
            {
                type: 'input',
                name: 'installments',
                readonly: true
            },
            {
                type: 'input',
                name: 'interval',
                readonly: true
            },
            {
                type: 'input',
                name: 'intervalQuantity',
                readonly: true
            },
            {
                type: 'select',
                name: 'status',
                options: {
                    CANCELED: 'CANCELED',
                    PENDING: 'PENDING', // waiting for client to confirm
                    ACTIVE: 'ACTIVE', // confirmed
                    TOKEN_EXPIRED: 'TOKEN_EXPIRED', // plaid token expired
                    PAID: 'PAID',
                    EXPIRED: 'EXPIRED'
                },
                readonly: true
            },
            {
                type: 'checkbox',
                name: 'surchargeEnabled'
            },
            {
                type: 'datepicker',
                name: 'confirmedDate'
            },
            {
                type: 'input',
                name: 'createdAt',
                readonly: true,
            },
            {
                type: 'input',
                name: 'updatedAt',
                readonly: true,
            },
            {
                type: 'json',
                name: 'paymentCredentials',
                readonly: true,
                additional: true
            }
        ]);
        this.relations = modelRelations([
            {
                crmAlias: 'scheduledPayments',
                crmModule: 'recurring-payment-dates',
                params: { recurringPaymentId: this.id },
                resource: 'recurringPaymentDate'
            },
            {
                crmModule: 'transactions',
                params: { recurringPaymentId: this.id },
                resource: 'transaction'
            },
            {
                crmModule: 'clients',
                params: this.invoice?.clientId,
                resource: 'client'
            },
            {
                crmModule: 'firms',
                crmAlias: 'firm',
                params: this.firmId,
                resource: 'firm'
            },
            {
                crmModule: 'banks',
                crmAlias: 'bank',
                params: this.invoice.bankId,
                resource: 'bank'
            },
            {
                crmModule: 'audit-logs',
                params: { entity_type: 'recurring_payment', entity_id: this.id },
                resource: 'auditLog'
            },
        ]);
    }

    get detailsDisplayName(): string {
        return `Recurring Payment ${this.id}`;
    }

    static filterFields(): Field[] {
        return modelConfig([
            {
                // this field is hidden in filter
                type: 'input',
                name: 'invoiceId',
                hidden: true,
                cssClasses: 'hidden'
            },
            {
                // this field is hidden in filter
                type: 'input',
                name: 'firmId',
                hidden: true,
                cssClasses: 'hidden'
            },
            {
                type: 'select',
                name: 'status',
                label: 'Filter by Status',
                cssClasses: 'col-sm-6 min-width-400',
                options: {
                    'CANCELED': 'CANCELED',
                    'PENDING': 'PENDING', // waiting for client to confirm
                    'ACTIVE': 'ACTIVE', // confirmed
                    'TOKEN_EXPIRED': 'TOKEN_EXPIRED', // plaid token expired
                    'PAID': 'PAID',
                    'EXPIRED': 'EXPIRED'
                }
            },
            {
                label: 'With Surcharge',
                type: 'checkbox',
                name: 'surchargeEnabled'
            }
        ]);
    }
}
