import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { NgJsonEditorModule } from 'ang-jsoneditor';
import { FileUploadModule } from 'ng2-file-upload';

import { FilePreviewModule } from '../file-preview';
import { DynamicFormService } from './dynamic-form.service';
import { DynamicFieldDirective } from './dynamic-field.directive';
import { DynamicFormComponent } from './dynamic-form.component';
import {
    FormAutocompleteComponent,
    FormInputComponent,
    FormSelectComponent,
    FormCheckboxComponent,
    FormJsonComponent,
    FormJsonGroupComponent,
    FormDatepickerComponent,
    FormTextareaComponent,
    FormFileComponent,
    FormImageComponent,
    FormFraudRulesComponent,
    FormEncryptedComponent,
    FormSectionHeaderComponent
} from './components';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatInputModule,
        MatButtonModule,
        MatSelectModule,
        MatAutocompleteModule,
        MatProgressSpinnerModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatIconModule,
        NgJsonEditorModule,
        FileUploadModule,
        FormsModule,
        MatFormFieldModule,
        FilePreviewModule
    ],
    providers: [
        DynamicFormService
    ],
    declarations: [
        FormAutocompleteComponent,
        FormInputComponent,
        FormSelectComponent,
        FormCheckboxComponent,
        FormJsonComponent,
        FormJsonGroupComponent,
        FormDatepickerComponent,
        FormTextareaComponent,
        FormFileComponent,
        FormImageComponent,
        DynamicFieldDirective,
        DynamicFormComponent,
        FormFraudRulesComponent,
        FormEncryptedComponent,
        FormSectionHeaderComponent
    ],
    exports: [
        DynamicFormComponent,
        DynamicFieldDirective
    ]
})
export class DynamicFormModule { }
