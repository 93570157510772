import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment as environmentLocal } from '@environment';
import { Bank, Service } from '@app/shared';
import { HttpClient } from '@angular/common/http';

export class BankingPartnersRow {
    value: string;
    label: string;
    globally_enabled: boolean;
}

@Injectable()
export class BankingPartnersService extends Service<Bank> {
    protected apiRoot = `${environmentLocal.apiUrl}/banking-partners`;

    constructor(protected http: HttpClient) {
        super(http);
    }

    getBankingPartnerList(): Observable<BankingPartnersRow[]> {
        return this.http.get<BankingPartnersRow[]>(`${this.apiRoot}/list`);
    }
}
