import {Model, Resource, modelConfig, Field, modelRelations} from '../data';
import { convertFromCents, formatDate, formatNegativeAmount } from '@app/shared';

export class HeadnoteBankTransaction extends Model {
    resourceName: Resource = 'headnoteBanks';

    id: string;
    file_id: string;
    unique_id: string;
    bank_account_id: string;
    transaction_date: Date;
    transaction_type: string;
    transaction_category: string;
    unmatched: string;
    currency_transfer_id: string;
    amount_in_cents: number;
    transaction_description: string;
    bank_reference_number: string;
    intraday_index: number;
    matched_currency_transfers: [{
        firm_id: string,
        clien_id: string,
        currency_transfer_id: string
    }];
    metadata: object;
    created_at: Date;
    updated_at: Date;

    constructor(attributes = {}) {
        super(attributes);
        super.update(attributes);

        this.overviewFields = [
            'id',
            'file_id',
            'unique_id',
            {
                label: 'Bank Account Id',
                name: 'bank_account_id',
                transformer: <HeadnoteBanks>(value, model) =>
                    `<a href='crm/headnote-banks/${model.bank_account_id}/transactions'>${model.bank_account_id}</a>`
            },
            {
                name: 'transaction_type',
                cssClasses: 'bold'
            },
            {
                name: 'transaction_category',
                cssClasses: 'bold'
            },
            {
                label: 'Amount',
                name: 'amount_in_cents',
                transformer: (value) => {
                    const cssClass = this.isDebit ? 'negative-amount' : 'bold';
                    return `<span class="${cssClass}">${this.getFormattedAmount(value, this.isDebit)}</span>`;
                },
            },
            {
                label: 'Transaction Date',
                name: 'transaction_date',
                cssClasses: 'bold',
                transformer: 'formatDate',
            },
            'transaction_description',
            'bank_reference_number',
            'intraday_index',
            {
                label: 'Created At',
                name: 'created_at',
                transformer: 'formatDate',
            },
            {
                label: 'Updated At',
                name: 'updated_at',
                transformer: 'formatDate',
            },
            {
                label: 'Includes',
                name: 'matched_currency_transfers',
                hideLabel: true,
                transformer: (matched_currency_transfers) => {
                    let html = '<table class="mapping-table">';
                    html += '<tr><th colspan="4" class="mapping-table-header">Matched currency transfers</th></tr>';
                    html += '<tr><th>Firm</th><th>Firm Bank Account</th>' +
                        '<th>Currency transfer Reconciliation Status</th><th>Amount</th></tr>';

                    let total = 0;

                    matched_currency_transfers.forEach(mapping_record => {
                        const isDebit = mapping_record.currency_transfer_type === 'DEBIT';
                        const reconciliation_error = mapping_record.reconciliation_error ? ' - ' + mapping_record.reconciliation_error : '';
                        const firm_bank_account_link = `/crm/banks/${mapping_record.firm_bank_account_id}`;
                        const transfer_link = `/crm/currency-transfers/${mapping_record.currency_transfer_id}`;

                        total += (isDebit ? -1 : 1) * mapping_record.transfer_amount_in_cents;

                        html += `<tr>`;
                        html += `<td>${mapping_record.firm_name}</td>`;
                        html += `<td><a href="${firm_bank_account_link}">${mapping_record.firm_bank_account_name}</a></td>`;
                        html += `<td><a href="${transfer_link}">${mapping_record.reconciliation_status}</a>${reconciliation_error}</td>`;
                        html += `<td>${this.getFormattedAmount(mapping_record.transfer_amount_in_cents, isDebit)}</td>`;
                        html += `</tr>`;
                    });

                    html += `<tr><th colspan="3" class="mapping-table-footer">Total:</th>`;
                    html += `<th>${this.getFormattedAmount(total, total < 0)}</th></tr>`;
                    return html;
                },
            },
        ];
        this.config = modelConfig([
            {
                type: 'input',
                name: 'id',
                readonly: true
            },
            {
                type: 'input',
                name: 'file_id',
                readonly: true
            },
            {
                type: 'input',
                name: 'unique_id',
                readonly: true
            },
            {
                type: 'input',
                name: 'bank_account_id',
                readonly: true
            },
            {
                type: 'input',
                name: 'transaction_date',
                readonly: true
            },
            {
                type: 'input',
                name: 'transaction_type',
                readonly: true
            },
            {
                type: 'input',
                name: 'transaction_category',
                readonly: true
            },
            {
                type: 'input',
                name: 'amount_in_cents',
                readonly: true
            },
            {
                type: 'input',
                name: 'transaction_description',
                readonly: true
            },
            {
                type: 'input',
                name: 'bank_reference_number',
                readonly: true
            },
            {
                type: 'input',
                name: 'intraday_index',
                readonly: true
            },
            {
                type: 'json',
                name: 'metadata',
                editable: false,
                additional: true
            },
            {
                type: 'datepicker',
                name: 'created_at',
                readonly: true,
            },
            {
                type: 'datepicker',
                name: 'updated_at',
                readonly: true,
            }
        ]);
        this.relations = modelRelations([
            {
                crmModule: 'currency-transfers',
                crmAlias: 'Currency Transfers',
                params: { bank_account_transaction_id: this.id },
                resource: 'currencyTransfer',
                hidden: (model) => model.matched_currency_transfers.length === 0
            },
            {
                crmAlias: 'Firm',
                crmModule: 'firms',
                params: this.firmId,
                resource: 'firm',
                hidden: (model) => model.matched_currency_transfers.length === 0
            },
        ]);
    }

    get detailsDisplayName(): string {
        return `Headnote Bank Transaction: ${this.id}`;
    }

    get isDebit(): boolean {
        return this.transaction_type === 'DEBIT';
    }

    get firmId(): string|null {
        if (this.matched_currency_transfers && this.matched_currency_transfers.length > 0) {
            return this.matched_currency_transfers[0].firm_id;
        }
        return null;
    }

    /**
     * Returns amount formatted as $1,234.56 or $(1,234.56) if it's debit
     */
    getFormattedAmount(amount_in_cents: number, isDebit = false): string {
        const result = convertFromCents(amount_in_cents);
        return isDebit ? formatNegativeAmount(result) : result;
    }

    static filterFields(): Field[] {
        return modelConfig([
            {
                type: 'input',
                name: 'firm_id',
                hidden: true,
                cssClasses: 'hidden'
            },
            {
                type: 'input',
                name: 'bank_account_id',
                hidden: true,
                cssClasses: 'hidden'
            },
            {
                type: 'input',
                name: 'firm_bank_account_id',
                hidden: true,
                cssClasses: 'hidden'
            },
            {
                type: 'select',
                label: 'Transaction Type',
                name: 'transaction_type',
                options: {
                    CREDIT: 'CREDIT',
                    DEBIT: 'DEBIT'
                },
                cssClasses: 'margin-right-20 margin-left-20',
            },
            {
                type: 'select',
                label: 'Transaction Category',
                name: 'transaction_category',
                cssClasses: 'col-sm-4 min-width-200',
                required: false,
                options: {
                    url: '/headnote-banks/categories'
                }
            },
            {
                type: 'select',
                label: 'Reconciliation status',
                name: 'reconciliation_status',
                options: {
                    'PENDING': 'PENDING',
                    'RECONCILED': 'RECONCILED',
                    'ISSUE': 'ISSUE',
                    'CANNOT_RECONCILE': 'CANNOT_RECONCILE',
                    'SKIPPED': 'SKIPPED',
                },
                cssClasses: 'margin-right-20 margin-left-20 max-width-150',
            },
            {
                type: 'input',
                name: 'currency_transfer_id',
                hidden: true,
                cssClasses: 'hidden'
            },
            {
                type: 'input',
                name: 'transaction_amount_in_cents',
                label: 'Filter by amount in cents',
                cssClasses: 'col-sm-4 min-width-200',
            },
            {
                label: 'Trans. Date From',
                type: 'datepicker',
                name: 'transaction_date_from',
                cssClasses: 'filter-date-input',
            },
            {
                label: 'Trans. Date To',
                type: 'datepicker',
                name: 'transaction_date_to',
                cssClasses: 'filter-date-input',
            },
            {
                label: 'Unmatched',
                type: 'checkbox',
                name: 'unmatched',
            }
        ]);
    }
}
