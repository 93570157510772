import { Model, Resource, OverviewField, modelConfig, modelRelations } from '../data';
import { environment as environmentLocal } from '@environment';

export class Invoice extends Model {
    resourceName: Resource = 'invoice';

    id: string;
    shortId: string;
    ownerId: string;
    clientId: string;
    firmId: string;
    bankId: string;
    status: string;
    type: string;
    subType: string;
    amount: number;
    archived: boolean;
    archivedDate: Date;
    surchargeEnabled: boolean;
    total: number;
    metadata: object;
    createdAt: Date;
    updatedAt: Date;
    bank?: {
        id: string;
        friendlyName: string,
        last4: string,
    };
    client?: {
        firstName: string,
        lastName: string,
        company: string,
        email: string
    };

    constructor(attributes = {}) {
        super(attributes);
        super.update(attributes);

        this.overviewFields = [
            'id',
            {
                label: 'Firm',
                name: 'firmId',
                transformer: (value: string, model: any) => model.firm.name,
            },
            {
                label: 'Type',
                name: 'type'
            },
            {
                label: 'Bank',
                name: 'bankId',
                transformer: (value: string, model: any) => {
                    if (!value) return `Default`;
                    return `<a href="/crm/banks/${model.bankId}">
            ${model.bank.bankAccountType} ${model.bank.friendlyName} ${model.bank.last4}
            </a>`;
                },
            },
            {
                label: 'Subtotal',
                name: 'amount',
                transformer: 'fromDollars'
            },
            {
                name: 'total',
                transformer: 'fromDollars'
            },
            {
                name: 'surchargeEnabled',
                transformer: (value) => value ? 'YES' : 'NO'
            },
            'status',
            'subType',
            'shortId',
            'createdAt'
        ];
        this.config = modelConfig([
            {
                type: 'input',
                name: 'id',
                readonly: true
            },
            {
                type: 'input',
                name: 'clientId',
                readonly: true
            },
            {
                type: 'input',
                name: 'firmId',
                readonly: true
            },
            {
                type: 'input',
                name: 'bankId'
            },
            {
                type: 'select',
                name: 'type',
                options: ['OPERATING', 'TRUST']
            },
            {
                type: 'select',
                name: 'subType',
                options: [
                    'NORMAL',
                    'PDF',
                    'SAMPLE',
                    'FAST_PAY',
                    'PORTAL',
                    'CHARGE'
                ]
            },
            {
                type: 'select',
                name: 'status',
                options: [
                    'DRAFT',
                    'GENERATED',
                    'SENT_UNOPENED',
                    'LATE',
                    'SENT_OPENED',
                    'SENT_VIEWED',
                    'PAYING',
                    'PAYMENT_INITIATED',
                    'PAID',
                    'PARTIALLY_PAID',
                    'FAILED',
                    'PAID_CHECK',
                    'PAID_CASH',
                    'PAID_OTHER',
                    'MANUALLY_MARKED'
                ]
            },
            {
                type: 'input',
                name: 'amount'
            },
            {
                type: 'input',
                name: 'total'
            },
            {
                type: 'checkbox',
                name: 'surchargeEnabled'
            },
            {
                type: 'input',
                name: 'shortId',
                readonly: true
            },
            {
                type: 'json',
                name: 'metadata',
                editable: false,
                additional: true
            },
            {
                type: 'input',
                name: 'createdAt',
                readonly: true,
            },
            {
                type: 'input',
                name: 'updatedAt',
                readonly: true,
            },
            {
                type: 'checkbox',
                name: 'archived'
            },
            {
                type: 'datepicker',
                name: 'archivedDate',
                readonly: true
            },
        ]);
        this.relations = modelRelations([
            {
                crmModule: 'firms',
                crmAlias: 'firm',
                params: this.firmId,
                resource: 'firm'
            },
            {
                crmModule: 'clients',
                crmAlias: 'client',
                params: this.clientId,
                resource: 'client'
            },
            {
                crmModule: 'profiles',
                crmAlias: 'Attorney',
                params: this.ownerId,
                resource: 'profile'
            },
            {
                crmModule: 'transactions',
                params: { invoiceId: this.id },
                resource: 'transaction'
            },
            {
                crmModule: 'recurring-payments',
                params: { invoiceId: this.id },
                resource: 'recurringPayment',
                hidden: (model: any) => model.subType !== 'FAST_PAY'
            }
        ]);
    }

    get clientName(): string {
        return this.client && (this.client.company || `${this.client.firstName} ${this.client.lastName}`);
    }

    get detailsDisplayName(): string {
        if (this.client) {
            return `Invoice for client: ${this.client.email}`;
        }

        return `Invoice ${this.id}`;
    }

    primaryRelations = [
        'client',
        'transaction',
        'recurringPayment',
        'paymentReminder',
        'firm',
        'profile'
    ];
}
