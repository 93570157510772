import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

export interface CSVExportResult {
    filename: string;
    csv: string;
}

@Injectable()
export class CSVExportService {
    constructor(protected http: HttpClient) {}

    export(exportURL: string, callback?: (err?: Error) => void): void {
        this.http.get<CSVExportResult>(exportURL).subscribe(
            (response: CSVExportResult) => {
                this.startCsvDownload(response);
                if (callback) callback();
            }, (err: Error) => {
                if (callback) callback(err);
            }
        );
    }

    /**
     * Starts download of exported CSV
     * @param response - ExportResult
     */
    private startCsvDownload(response: CSVExportResult) {
        const url = window.URL.createObjectURL(new Blob([response.csv], {type: 'text/csv'}));
        const a = document.createElement('a');
        a.href = url;
        a.download = response.filename;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); // afterwards we remove the element again
    }
}
