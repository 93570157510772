import { Component, OnInit } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';
import { FileUploader } from 'ng2-file-upload';

import { Field, FieldFile, FileWithUploadProgress } from '../../../../data';

@Component({
    selector: 'aw-form-file',
    template: `
    <div class="dynamic-field form-input" class="{{ field.cssClasses }}" [formGroup]="group">
        <div class="mat-form-field mat-form-field-wrapper">

        <span class="mat-form-field-label-wrapper mat-form-field-appearance-legacy">
            <label class="mat-form-field-label mat-empty mat-form-field-empty">
              {{ field.label }}
            </label>
        </span>

        <div class="margin-top-30">
            <p *ngIf="!currentValue && !uploadedFileName">Not uploaded yet</p>

            <p *ngIf="!uploadedFileName && field.sampleLinkConfig" >
                <a class="sample-link" (click)="field.sampleLinkConfig.onClick()">{{field.sampleLinkConfig.linkText}}</a>
            </p>

            <p *ngIf="uploadedFileName">
                Just selected: <b>{{ uploadedFileName }}</b><br/><br/>

                <span *ngIf="uploadedFileName && !errorMessage && !uploadProgress" class="image-upload-warning">
                    Please, click "{{ buttonSubmitText }}" to store this new file to the server.
                </span>

                <span *ngIf="uploadProgress" class="image-upload-warning">
                    Please wait... {{ uploadProgress }}% uploaded
                </span>

                <span *ngIf="errorMessage" class="image-upload-error">
                    {{ errorMessage }}
                </span>
            </p>

            <p *ngIf="field.hint && !uploadedFileName">{{field.hint}}</p>
        </div>


        <button type="button" *ngIf="!uploadProgress"
          mat-stroked-button
          (click)="file.click()"
        >
          <span class="margin-right-20">{{ uploadButtonText }}</span>
          <mat-icon *ngIf="!uploadedFileName">cloud</mat-icon>
          <mat-icon *ngIf="uploadedFileName" color="accent">cloud_done</mat-icon>
        </button>

        <input type="file"
          ng2FileSelect
          [uploader]="fileUploader"
          (change)="update()"
          [accept]="field.allowedExtensions"
          hidden
          #file/>
        </div>
    </div>
  `,
    styleUrls: ['./form-file.component.scss']
})
export class FormFileComponent implements OnInit {
    field: FieldFile & Field;
    group: FormGroup;
    buttonSubmitText: string;
    errorMessage: string;
    fileUploader = new FileUploader({ url: ''});

    ngOnInit() {
        this.group.controls[this.field.name].valueChanges.subscribe((data) => {
            this.fileUploader.clearQueue();
        });
    }

    /**
   * For "file" inputs we create hidden control with name starting with $ sign
   * For example, if form has barCard input, there will be $barCard control
   * These controls emit FileUploader as its value
   */
    get fileUploaderControl(): AbstractControl {
        return this.group.controls[`$${this.field.name}`];
    }

    get currentValue(): string {
        return this.group.value[this.field.name];
    }

    get uploadedFileName(): string {
        if (this.lastUploadedFile) {
            return this.lastUploadedFile.name;
        }
        return null;
    }

    get uploadProgress(): number {
        if (this.lastUploadedFile) {
            return this.lastUploadedFile.uploadProgress || 0;
        }
        return null;
    }

    get uploadButtonText(): string {
        if (this.uploadedFileName) {
            return 'Choose another file';
        }

        return this.field.btnTitle || 'Choose file';
    }

    checkFileSize(file: FileWithUploadProgress): boolean {
        const fileSize = file.size || 0;
        if (this.field.maxFileSizeInBytes && fileSize > this.field.maxFileSizeInBytes) {
            this.errorMessage = 'File is too big. Please, choose some other file.';
            return false;
        } else {
            this.errorMessage = '';
            return true;
        }
    }

    get lastUploadedFile(): FileWithUploadProgress {
        if (this.fileUploader.queue.length > 0) {
            return this.fileUploader.queue[this.fileUploader.queue.length - 1]._file;
        }
        return null;
    }

    update() {
        const file: FileWithUploadProgress = this.lastUploadedFile;
        if (this.checkFileSize(file)) {
            this.fileUploaderControl.patchValue(this.lastUploadedFile);
            this.fileUploaderControl.markAsDirty();
        } else {
            this.fileUploaderControl.setErrors({
                'size': 'File is too big. Please, choose another smaller file.'
            });
        }
    }
}
