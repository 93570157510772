import { Model, Resource, modelConfig, modelRelations, Field } from '../data';
import { formatAmount } from '../utils';
import { CURRENCY_TRANSFER_STATUS, DEFAULT_CURRENCY } from '../constants';

interface MerchantDetails {
    mid: string;
    tid: string;
}

interface BankAccountDetails {
    banking_partner_id: string;
    bank_account_type: string;
}

interface CreditCardDetails {
    last4: string;
    card_exp_date: string;
    card_cvv_token: string;
    card_zip_code_token: string;
    card_number_token: string;
    card_type: string;
    cardholder_name: string;
}

export class CurrencyTransfer extends Model {
    resourceName: Resource = 'currencyTransfer';

    id: string;
    reference_id: string;
    amount_in_cents: string;
    surcharge_amount_in_cents: string;
    currency: string;
    status: string;
    transfer_type: string;
    transfer_method: string;
    source_type: string;
    source_details: MerchantDetails | CreditCardDetails | BankAccountDetails;
    destination_type: string;
    destination_details: MerchantDetails | CreditCardDetails | BankAccountDetails;
    metadata?: {
        firm_name: string;
        partner_name: string;
        short_id: string;
        currency_transfer_category: string;
    };
    auth_id: string;
    network_token_was_used?: string;
    currency_transfer_hash: string;
    created_at: Date;
    parent_currency_transfer_id: string;
    banking_partner_id: string;
    nacha_file_id: string;
    error_message: string;

    get isBatchTransfer() {
        return this.metadata
            && this.metadata.currency_transfer_category &&
            ['BATCH_PAYOUT', 'BATCH_FEE'].includes(this.metadata.currency_transfer_category);
    }

    constructor(attributes = {}) {
        super(attributes);
        super.update(attributes);

        this.overviewFields = [
            {
                label: 'Reference id',
                name: 'reference_id',
                transformer: <CurrencyTransfer>(value, model) => {
                    if (model.isBatchTransfer) {
                        return `<a href='/crm/nacha-transactions/${model.reference_id}'>${model.reference_id}</a>`;
                    } else {
                        return `<a href='/crm/transactions/${model.reference_id}'>${model.reference_id}</a>`;
                    }
                }
            },
            {
                name: 'parent_currency_transfer_id',
                transformer: <CurrencyTransfer>(value, model) =>
                    `<a href='/crm/currency-transfers/${model.parent_currency_transfer_id}'>` +
                    `${model.parent_currency_transfer_id}</a>`
            },
            {
                name: 'amount_in_cents',
                label: 'Amount',
                // readonly: true,
                transformer: (value) => formatAmount(parseFloat((value / 100).toString()).toFixed(2), DEFAULT_CURRENCY)
            },
            'currency',
            {
                name: 'status',
                transformer: (value) => `<b>${value}</b>`
            },
            {
                name: 'auth_id',
                transformer: (value) => `<b>${value}</b>`
            },
            'transfer_type',
            {
                name: 'currency_transfer_hash'
            },
            'transfer_method',
            'banking_partner_id',
            'source_type',
            'destination_type',
            {
                hideLabel: true,
                name: 'source_details',
                transformer: (value) => `<div>
                        <div class="semibold">Source details</div>
                        <pre>${JSON.stringify(value, null, 4)}</pre>
                    </div>`,
                cssClasses: 'overflow-auto'
            },
            {
                hideLabel: true,
                name: 'destination_details',
                transformer: (value) => `<div>
                        <div class="semibold">Destination details</div>
                        <pre>${JSON.stringify(value, null, 4)}</pre>
                    </div>`,
                cssClasses: 'overflow-auto'
            },
            {
                hideLabel: true,
                name: 'metadata',
                transformer: (value) => `<div>
                        <div class="semibold">Metadata</div>
                        <pre>${JSON.stringify(value, null, 4)}</pre>
                    </div>`,
                cssClasses: 'overflow-auto'
            },
            'network_token_was_used',
            'error_message',
            {
                name: 'surcharge_amount_in_cents',
                label: 'Surcharge Amount (for RapidConnect)',
                transformer: (value) => formatAmount(parseFloat((value / 100).toString()).toFixed(2), DEFAULT_CURRENCY),
                hide: (model: CurrencyTransfer) => model.transfer_method !== 'CARD'
            },
            {
                label: 'NACHA File',
                name: 'nacha_file_id',
                transformer:
                    <CurrencyTransfer>(value, model) => `<a href='/crm/nacha-files/${model.nacha_file_id}'>${model.nacha_file_id}</a>`
            },
            {
                name: 'created_at',
                transformer: 'formatDate'
            },
            {
                name: 'updated_at',
                transformer: 'formatDate'
            },
            {
                name: 'processed_at',
                transformer: 'formatDate'
            }
        ];
        this.config = modelConfig([
            {
                type: 'input',
                name: 'reference_id',
                readonly: true
            },
            {
                type: 'input',
                name: 'parent_currency_transfer_id',
                readonly: true
            },
            {
                type: 'input',
                name: 'amount_in_cents',
                readonly: true
            },
            {
                type: 'input',
                name: 'surcharge_amount_in_cents',
                readonly: true
            },
            {
                type: 'input',
                name: 'currency',
                readonly: true
            },
            {
                type: 'select',
                name: 'status',
                options: Object.keys(CURRENCY_TRANSFER_STATUS)
            },
            {
                type: 'input',
                name: 'transfer_type',
                readonly: true
            },
            {
                type: 'input',
                name: 'transfer_method',
                readonly: true
            },
            {
                type: 'input',
                name: 'source_type',
                readonly: true
            },
            {
                type: 'json',
                name: 'source_details',
                readonly: true,
                editable: true
            },
            {
                type: 'input',
                name: 'destination_type',
                readonly: true
            },
            {
                type: 'json',
                name: 'destination_details',
                readonly: true,
                editable: true
            },
            {
                type: 'json',
                name: 'response_content',
                readonly: true,
                editable: true,
            },
            {
                type: 'input',
                name: 'error_message',
                readonly: true
            },
            {
                type: 'input',
                name: 'created_at',
                readonly: true,
            },
            {
                type: 'input',
                name: 'updated_at',
                readonly: true,
            },
            {
                type: 'input',
                name: 'processed_at',
                readonly: true,
            }
        ]);
        this.relations = modelRelations([
            {
                crmAlias: 'Transaction',
                crmModule: 'transactions',
                params: this.reference_id,
                resource: 'transaction',
                hidden: this.isBatchTransfer
            },
            {
                crmAlias: 'Batch Transaction',
                crmModule: 'nacha-transactions',
                params: this.reference_id,
                resource: 'nachaTransaction',
                hidden: !this.isBatchTransfer
            },
            {
                crmAlias: 'Parent Currency Transfer',
                crmModule: 'currency-transfers',
                params: this.parent_currency_transfer_id,
                resource: 'currencyTransfer'
            },
            {
                crmModule: 'rapidconnect-requests',
                params: { parent_currency_transfer_id: this.id },
                resource: 'rapidconnectRequest',
                hidden: (model) => model.transfer_method === 'ACH'
            },
            {
                crmAlias: 'Bank Transactions',
                crmModule: 'headnote-banks/all/transactions',
                params: { currency_transfer_id: this.id },
                resource: 'headnoteBanks',
            },
            {
                crmModule: 'firstdata-transactions',
                params: { currency_transfer_id: this.id },
                resource: 'firstdataTransaction',
                hidden: (model) => model.transfer_method === 'ACH'
            },
            {
                crmModule: 'audit-logs',
                params: { entity_type: 'currency_transfer', entity_id: this.id },
                resource: 'auditLog'
            },
            {
                crmAlias: 'Nacha File',
                crmModule: 'nacha-files',
                params: this.nacha_file_id,
                resource: 'nachaFile'
            },
            {
                crmAlias: 'Firm Issues',
                crmModule: 'firm-issues',
                params: { currencyTransferId: this.id },
                resource: 'firmIssue'
            },
        ]);
    }

    get detailsDisplayName(): string {
        return `Currency Transfer ${this.id}`;
    }

    static filterFields(): Field[] {
        return modelConfig([
            {
                type: 'input',
                name: 'reference_id',
                hidden: true,
                cssClasses: 'hidden'
            },
            {
                type: 'select',
                name: 'status',
                label: 'Filter by status',
                cssClasses: 'col-sm-6 min-width-400',
                options: {
                    '': 'All Statuses',
                    PENDING: 'PENDING',
                    TRANSFERRED: 'TRANSFERRED',
                    REVERSED: 'REVERSED',
                    FAILED: 'FAILED',
                    PARTIALLY_REFUNDED: 'PARTIALLY_REFUNDED',
                    REFUNDED: 'REFUNDED',
                    SENDING_NACHA: 'SENDING_NACHA'
                }
            },
            {
                label: 'Currency Transfer Hash',
                type: 'input',
                name: 'currency_transfer_hash',
                pattern: '^[0-9]{12}$'
            },
        ]);
    }
}
