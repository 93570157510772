import { Attributes } from './attributes';
import { Resource, Relation, Field, OverviewField } from './types';

export class Model extends Attributes {
    resourceName: Resource;

    /**
     * Configuration of the Overview Fields
     * You can use overviewFieldsFn if configuration changes dynamically
     */
    overviewFields: Array<OverviewField | string> = [];
    overviewFieldsFn: () => Array<OverviewField | string>;

    /**
     * Configuration of the Edit Fields
     * You can use configFn if configuration changes dynamically
     */
    config: Field[] = [];
    configFn: () => Field[];

    /**
     * Configuration of the Create Fields
     */
    configCreate: Field[] = [];

    /**
     * Configuration of the Relations
     */
    relations: Relation[];

    /**
     * Names of relations which we show as buttons on entity Overview page
     */
    primaryRelations: string[];

    /**
     * Fields which don't exist in model but are computed on fronte-end
     */
    computedFields: string[] = [];

    _detailsDisplayName: string;
    get detailsDisplayName() {
        return this._detailsDisplayName;
    };

    constructor(attributes = {}) {
        super(attributes);
        this.update(attributes);
    }

    update(data: object) {
        Object.keys(data).forEach(key => this[key] = data[key]);
        this.updateFields();
    }

    updateFields() {
        if (this.overviewFieldsFn) this.overviewFields = this.overviewFieldsFn();
        if (this.configFn) this.config = this.configFn();
    }

    setRelationCount(resource: string, count: number): void {
        const relation = this.relations.find(r => r.resource === resource);
        if (relation) {
            relation.count = count;
        }
    }
}
