import { Model, Resource, OverviewField, modelConfig, modelRelations } from '../data';

export class Partner extends Model {
    resourceName: Resource = 'partner';

    id: string;
    name: string;
    active: boolean;
    createdAt: Date;
    updatedAt: Date;

    constructor(attributes = {}) {
        super(attributes);
        super.update(attributes);

        this.overviewFields = [
            'id',
            'name',
            'achCode',
            'active'
        ];
        this.config = modelConfig([
            {
                type: 'input',
                name: 'id',
                readonly: true
            },
            {
                type: 'input',
                name: 'name'
            },
            {
                type: 'input',
                name: 'achCode'
            },
            {
                type: 'checkbox',
                name: 'active'
            },
            {
                type: 'input',
                name: 'createdAt',
                readonly: true,
                additional: true
            },
            {
                type: 'input',
                name: 'updatedAt',
                readonly: true,
                additional: true
            }
        ]);
        this.configCreate = modelConfig([
            {
                type: 'input',
                name: 'name'
            }
        ]);
        this.relations = modelRelations([
            {
                crmModule: 'partner-api-keys',
                params: { partnerId: this.id },
                resource: 'partnerApiKey'
            },
            {
                crmModule: 'partner-webhooks',
                params: { partnerId: this.id },
                resource: 'partnerWebhook'
            },
            {
                crmModule: 'audit-logs',
                params: { partner_id: this.id },
                resource: 'auditLog'
            }
        ]);
    }

    get detailsDisplayName(): string {
        return `Partner: ${this.name}`;
    }
}
