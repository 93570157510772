import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Field, FieldInput } from '../../../../data';

@Component({
    selector: 'aw-form-input',
    template: `
    <div class="dynamic-field form-input" class="{{ field.cssClasses }}"
      [formGroup]="group">

      <label *ngIf="field.showLabelAbove" for="{{ field.name }}">{{ field.label }}</label>

      <mat-form-field class="custom_form__field" appearance="fill" class="custom_form__field">
        <mat-label>{{ field.showLabelAbove ? '' : field.label }}</mat-label>
        <input matInput
          [type]="field.subType || 'text'"
          [formControlName]="field.name"
          minlength="{{ field.minlength }}"
          maxlength="{{ field.maxlength }}"
          pattern="{{ field.pattern }}"
          required="{{ field.required ? true : false }}"
          placeholder="">
        <mat-hint class="margin-bottom-20" *ngIf="field.hint">{{ field.hint }}</mat-hint>
        <span *ngIf="field.prefix" matPrefix><b>{{ field.prefix }}&nbsp;</b></span>
        <span *ngIf="field.suffix" matSuffix><b>&nbsp;{{ field.suffix }}</b></span>
      </mat-form-field>
    </div>
  `,
})
export class FormInputComponent {
    field: FieldInput & Field;
    group: FormGroup;
}
