import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnChanges, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent as PaginationEvent } from '@angular/material/paginator';
import { Location } from '@angular/common';
import * as _ from 'lodash';

import { List, Column } from './list';
import { Model, formatDate, getCurrentTimezone, Field } from '../../../shared';
import { environment as environmentLocal } from '@environment';

import { CSVExportService } from '../../services/csv-export.service';
import { ListFilterValues, ListPageOptions, ListPaginationParams } from './list.interfaces';
import { LIST_FILTER_EMPTY_VALUE } from './list.constants';

@Component({
    selector: 'aw-list',
    templateUrl: './list.component.html',
    providers: [CSVExportService],
    styles: [`
    .mat-mdc-form-field {
      font-size: 14px;
      width: 100%;
    }
    .mat-column-firstName,
    .mat-column-lastName,
    .mat-column-company {
      flex: 0 0 15%;
    }
    .mat-column-id {
      max-width: 36rem;
    }
    .mat-column-ownerEmail,
    .mat-column-email {
      flex: 0 0 25%;
    }
    .mat-column-method {
      max-width: 5rem;
    }
    .mat-column-amount {
      max-width: 8rem;
    }
    .mat-mdc-cell:last-of-type {
      padding-right: 0px;
    }
  `]
})
export class ListComponent implements OnInit, OnChanges, AfterViewInit {

    @Input() list: List;
    @Input() filterFields: Field[] = [];
    @Input() title: string;
    @Input() dynamoDbPagination: boolean;

    @Output() page: EventEmitter<ListPageOptions> = new EventEmitter();

    filterValues: ListFilterValues = {};
    paginationParams: ListPaginationParams = { pageIndex: 0, pageSize: 25 };
    dataIsLoading = true;
    dataIsExporting = false;

    fieldTransforms = {
        createdAt: (date) => formatDate(date),
        updatedAt: (date) => formatDate(date)
    };

    dataSource: MatTableDataSource<object>;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    constructor(
        private router: Router,
        protected route: ActivatedRoute,
        protected location: Location,
        protected csvExportService?: CSVExportService,
    ) {}

    ngOnInit(): void {
        // If we have limited back and forward DynamoDB pagination enabled
        // and current route at the init step has page index value
        // this case we have to reset pagination to the beginning
        const pageIndex = _.get(this.route, 'snapshot.params.pageIndex');
        if (this.dynamoDbPagination && pageIndex > 0) {
            this.router.navigateByUrl(this.router.url.replace(`pageIndex=${pageIndex}`, 'pageIndex=0'));
            this.paginationParams = {
                pageIndex: 0,
                pageSize: this.paginationParams.pageSize
            };
        }

        if (this.list && this.list.pageSizeOptions) {
            this.paginationParams.pageSize = this.list.pageSizeOptions[0];
        }

        this.dataSource = new MatTableDataSource<object>();
        this.observeRouteParamsChanges();
    }

    /**
     * When URL parameters change - we set filter values and reload data
     */
    private observeRouteParamsChanges(): void {
        if (!this.route || !this.route.snapshot || !this.route.snapshot.params) return;

        this.route.params.subscribe(params => {
            this.applyUrlParams(params as ListFilterValues & ListPaginationParams);
            this.fetchData();
        });
    }

    /**
     * Is called when list-filter values change: it updates filterValues and re-fetches the data
     */
    onFilterValuesChanged(newFilterValues: ListFilterValues): void {
        if (JSON.stringify(this.filterValues) !== JSON.stringify(newFilterValues)) {
            this.filterValues = newFilterValues;
            this.paginationParams.pageIndex = 0;
            this.updatePageUrl();
            this.fetchData();
        }
    }

    onRefresh(): void {
        this.fetchData();
    }

    /**
     * Is called on page change: updates pagination params and fetches the data
     */
    onPageChange(event: PaginationEvent): void {
        this.paginationParams = {
            pageIndex: event.pageIndex,
            pageSize: event.pageSize,
        };
        this.updatePageUrl();
        this.fetchData();
    }

    getTitle(): string {
        return (this.list && this.list.listTitle) || this.title;
    }

    isJSONField(fieldName: string): boolean {
        return this.list.columnTypes && this.list.columnTypes[fieldName] === 'json';
    }

    isHTMLField(fieldName: string): boolean {
        return this.list.columnTypes && this.list.columnTypes[fieldName] === 'html';
    }

    getColumnValue(column: Column, model: Model): string {
        return this.fieldTransforms[column.name]
            ? this.fieldTransforms[column.name](model[column.name])
            : model[column.name];
    }

    ngAfterViewInit(): void {
        this.dataSource.paginator = this.paginator;
    }

    /**
     * When data arrived
     */
    ngOnChanges(): void {
        this.dataIsLoading = false;
        if (!this.list || !this.list.data) {
            return;
        }
        const { totalCount, data } = this.list;
        this.dataSource.data = data;
        const { pageSize, pageIndex } = this.paginationParams;

        if (totalCount - pageSize * pageIndex < 0) {
            this.router.navigateByUrl(this.router.url.replace(`pageIndex=${pageIndex}`, 'pageIndex=0'));
            this.paginationParams = { pageIndex: 0, pageSize: 25 };
        }
    }

    getStyle(columnName: string, model: Model): string {
        if (!this.list.getColumnStyle) return;
        return this.list.getColumnStyle(columnName, model);
    }

    getHeaderStyles(columnName: string): string {
        if (!this.list.getHeaderStyle) return;
        return this.list.getHeaderStyle(columnName);
    }

    buildLink(model: object): string[] {
        if (this.list.routeLinkColumnTemplate) {
            const template= this.list.routeLinkColumnTemplate.replace(/:([a-zA-Z_]+)/g, (match, key) => model[key]);
            return [template];
        }
        if (this.list.routeTemplate) {
            const template= this.list.routeTemplate.replace(/:([a-zA-Z_]+)/g, (match, key) => model[key]);
            return [template];
        }
        if (this.list.crmModule) {
            return ['/crm', this.list.crmModule, model['id']];
        }

        return [`/${this.list.module}`, model['id']];
    }

    private updatePageUrl(): void {
        const currentUrlParams = {};
        if (this.route.snapshot.paramMap.keys.length > 0) {
            this.route.snapshot.paramMap.keys.map((name) => {
                currentUrlParams[name] = this.route.snapshot.paramMap.get(name);
            });
        }

        const urlParams = {
            ...currentUrlParams,
            ...this.filterValues,
            ...this.paginationParams
        };

        const filterString = Object.keys(urlParams)
            .filter(key => urlParams[key])
            .map(key => `${key}=${urlParams[key]}`).join(';');

        // pageUrl for before and after initialization of this.list
        let pageUrl;
        if (this.list) {
            if (this.list.routeTemplate) {
                pageUrl = this.list.routeTemplate.replace(/:([a-zA-Z_]+)/g, (match, key) => urlParams[key] || '').replace(/\/$/, '');
            } else {
                pageUrl = `/crm/${this.list.crmModule}`;
            }
        } else {
            pageUrl = this.getUrlWithoutParams();
        }
        // update URL without reload
        this.location.replaceState(`${pageUrl};${filterString}`);
    }

    /**
     * Fetch data using current value of filterValues and paginationParams
     */
    private fetchData(): void {
        this.dataIsLoading = true;
        this.page.next({
            ...this.filterValues,
            ...this.paginationParams
        });
    }

    /**
     * Is called on click on Export button
     */
    onExport() {
        const { exportPageSize } = this.list;
        let pageNum = '1';

        if (this.list.totalCount > exportPageSize) {
            pageNum = prompt(
                `Only ${exportPageSize} rows can be exported. Please enter page number`,
                '1'
            );
            // prevents NaN value for pageIndex param
            pageNum = parseInt(pageNum, 10) ? pageNum : '1';
        }
        const exportLink = this.buildExportLink(pageNum);
        this.dataIsExporting = true;
        this.csvExportService.export(exportLink, () => {
            this.dataIsExporting = false;
        });
    }

    /**
     * Builds link for export to CSV request
     */
    private buildExportLink(pageNum: string): string {
        const { exportPageSize } = this.list;
        const params = {
            ...this.filterValues,
            userTimezone: getCurrentTimezone(),
            pageIndex: `${parseInt(pageNum, 10) - 1}`,
            pageSize: `${exportPageSize}`,
        };
        const filterString = new URLSearchParams(params).toString();
        const exportApiUrl = this.list.exportApiUrl || `/${this.list.crmModule}`;
        return `${environmentLocal.apiUrl}${exportApiUrl}/export?` + filterString;
    }

    /**
     * Set values in filter form and reload data in a table
     */
    private applyUrlParams(urlParams: ListFilterValues & ListPaginationParams) {
        const newValues: ListFilterValues = {};

        if (this.filterFields) {
            this.filterFields.forEach(field => {
                if (urlParams[field.name] === LIST_FILTER_EMPTY_VALUE) {
                    newValues[field.name] = LIST_FILTER_EMPTY_VALUE;
                } else {
                    newValues[field.name] = urlParams[field.name] || field.defaultValue || LIST_FILTER_EMPTY_VALUE;
                }
            });
        }

        if (urlParams.pageIndex) {
            this.paginationParams.pageIndex = parseInt(`${urlParams.pageIndex}`, 10);
        }
        if (urlParams.pageSize) {
            this.paginationParams.pageSize = parseInt(`${urlParams.pageSize}`, 10);
        }

        this.filterValues = newValues;
    }

    /**
     * Get url without params
     */
    private getUrlWithoutParams(): string {
        return this.router.url.split(';')[0];
    }

}
