import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Field } from '../../../../data';

@Component({
    selector: 'aw-form-section-header',
    styleUrls: ['./form-section-header.component.scss'],
    template: `
    <div class="dynamic-field form-input">
      <label class="section-header">{{ field.label }}</label>
    </div>
  `
})
export class FormSectionHeaderComponent {
    field: Field;
    group: FormGroup;
}
