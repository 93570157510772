import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Field } from '../../../../data';

@Component({
    selector: 'aw-form-input',
    template: `
    <div class="dynamic-field form-input"
      [formGroup]="group">
    <mat-form-field appearance="fill" subscriptSizing="dynamic">
        <mat-label>{{ field.label }}</mat-label>
        <textarea matInput
          rows="6"
          [formControlName]="field.name"
          placeholder="">
        </textarea>
      </mat-form-field>
    </div>
  `,
})
export class FormTextareaComponent {
    field: Field;
    group: FormGroup;
}
