import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';

import { DialogModule } from '../../dialog';
import { ConfirmDialogComponent } from './confirm-dialog.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    imports: [
        CommonModule,
        MatTableModule,
        MatButtonModule,
        DialogModule,
        MatIconModule
    ],
    declarations: [ConfirmDialogComponent],
    exports: [
        ConfirmDialogComponent
    ]
})
export class ConfirmDialogModule { }
