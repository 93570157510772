import { Model, Resource, OverviewField, modelConfig, modelRelations } from '../data';

export class Promo extends Model {
    resourceName: Resource = 'promo';

    id: string;
    name: string;
    active: boolean;
    type: string;
    description: string;
    expiration_date: string; // ISO8601 string
    create_date: string; // ISO8601 string

    constructor(attributes = {}) {
        super(attributes);
        super.update(attributes);
        this.overviewFields = [
            'id',
            'name',
            'active',
            'type',
            'description',
            {
                name: 'expiration_date',
                transformer: 'formatDate'
            }
        ];
        this.config = modelConfig([
            {
                type: 'input',
                name: 'id',
                readonly: true
            },
            {
                type: 'input',
                name: 'name'
            },
            {
                type: 'checkbox',
                name: 'active'
            },
            {
                type: 'select',
                name: 'type',
                options: {
                    url: '/promos/types'
                },
                readonly: true
            },
            {
                type: 'textarea',
                name: 'description'
            },
            {
                type: 'datepicker',
                name: 'expiration_date'
            },
            {
                type: 'input',
                name: 'create_date',
                readonly: true,
            }
        ]);
        this.configCreate = modelConfig([
            {
                type: 'input',
                name: 'name',
                label: 'Name*'
            },
            {
                type: 'datepicker',
                name: 'expiration_date',
                label: 'Expiration date*'
            },
            {
                type: 'checkbox',
                name: 'active'
            },
            {
                type: 'select',
                name: 'type',
                options: {
                    url: '/promos/types'
                },
                label: 'Type*'
            },
            {
                type: 'textarea',
                name: 'description'
            }
        ]);
        this.relations = modelRelations([
            {
                crmModule: 'firm-promos',
                crmAlias: 'firm-promos',
                params: { promoId: this.id },
                resource: 'firmPromo'
            },
            {
                crmModule: 'audit-logs',
                params: { entity_type: 'promo', entity_id: this.id },
                resource: 'auditLog'
            }
        ]);
    }

    get detailsDisplayName(): string {
        return `Promo "${this.name}"`;
    }
}
