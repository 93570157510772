import { Model, Resource, modelConfig, modelRelations, Field } from '../data';
import { RAPIDCONNECT_REQUEST_STATUS } from '../constants';

export class RapidconnectRequest extends Model {
    resourceName: Resource = 'rapidconnectRequest';

    id: string;
    parent_currency_transfer_id: string;
    stan: number;
    txn_type: string;
    status: string;
    transmission_timestamp: Date;
    response_content: object;
    created_at: Date;
    order_number: string;
    reference_number: string;
    error_message: string;
    auth_id: string;

    constructor(attributes = {}) {
        super(attributes);
        super.update(attributes);

        this.overviewFields = [
            'id',
            {
                name: 'parent_currency_transfer_id',
                transformer: (value: string, model: any) => `<a href="/crm/currency-transfers/${value}">${value}</a>`
            },
            'stan',
            'txn_type',
            {
                name: 'status',
                transformer: (value, model) => '<b>' + value + '</b>'
            },
            'auth_id',
            {
                name: 'order_number',
                transformer: (value, model) => '<code>' + value + '</code>'
            },
            {
                name: 'reference_number',
                transformer: (value, model) => '<code>' + value + '</code>'
            },
            'error_message',
            'transmission_timestamp',
            'created_at',
        ];
        this.config = modelConfig([
            {
                type: 'input',
                name: 'parent_currency_transfer_id',
                readonly: true
            },
            {
                type: 'input',
                name: 'stan',
                readonly: true
            },
            {
                type: 'input',
                name: 'txn_type',
                readonly: true
            },
            {
                type: 'select',
                name: 'status',
                options: Object.keys(RAPIDCONNECT_REQUEST_STATUS)
            },
            {
                type: 'input',
                name: 'auth_id',
                readonly: true
            },
            {
                type: 'input',
                name: 'transmission_timestamp',
                readonly: true
            },
            {
                type: 'input',
                name: 'order_number',
                readonly: true
            },
            {
                type: 'input',
                name: 'reference_number',
                readonly: true
            },
            {
                type: 'input',
                name: 'error_message',
                readonly: true
            },
            {
                type: 'json',
                name: 'response_content',
                readonly: true,
                additional: true
            },
            {
                type: 'input',
                name: 'created_at',
                readonly: true
            },
        ]);
        this.relations = modelRelations([
            {
                crmModule: 'currency-transfers',
                crmAlias: 'Currency Transfer',
                params: this.parent_currency_transfer_id,
                resource: 'currencyTransfer'
            },
            {
                crmModule: 'audit-logs',
                params: { entity_type: 'rapidconnect_request', entity_id: this.id },
                resource: 'auditLog'
            },
        ]);
    }

    get detailsDisplayName(): string {
        return `Rapidconnect Request`;
    }

    static filterFields(): Field[] {
        return modelConfig([
            {
                type: 'input',
                name: 'parent_currency_transfer_id',
                hidden: true,
                cssClasses: 'hidden'
            }
        ]);
    }
}
