import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Field } from '../../../../data';

@Component({
    selector: 'aw-form-checkbox',
    template: `
    <div [class]="'dynamic-field form-checkbox mat-form-field-wrapper ' + field.cssClasses "
      [formGroup]="group">
      <mat-checkbox [formControlName]="field.name">
        {{ field.label }}
      </mat-checkbox>
      <br/>
        <mat-hint class="margin-bottom-20" *ngIf="field.hint"> ({{ field.hint }})</mat-hint>
    </div>
  `,
})
export class FormCheckboxComponent {
    field: Field;
    group: FormGroup;
}
