import { Model, Resource, modelConfig, modelRelations, Field } from '../data';
import { ALLOWED_IMAGE_EXTENSIONS } from '../constants';

export class Bank extends Model {
    resourceName: Resource = 'bank';

    id: string;
    ownerId: string;
    firmId: string;
    credentials: object;
    payeezyMerchantToken: string;
    payeezyMerchantId: string;
    payeezyTransarmorToken: string;
    issues: object;
    platform: string;
    bankAccountType: string;
    friendlyName: string;
    last4: string;
    uniqueIdentity: string;
    plaidEnv: string;
    achStatus: string;
    voidedCheck: string;
    voidedCheckExists: boolean;
    archived: boolean;
    isDefault: boolean;
    archivedDate: Date;
    createdAt: Date;
    updatedAt: Date;

    firm: {
        id: string,
        name: string,
        email: string,
        partnerId: string
        partnerName: string;
    };

    profile: { email: string };

    constructor(attributes = {}) {
        super(attributes);
        super.update(attributes);


        this.overviewFields = [
            'id',
            {
                label: 'Partner',
                name: 'firmId',
                transformer: (value, model: Bank) => `<a href='/crm/partners/${model.firm.partnerId}'>${model.firm.partnerName}</a>`
            },
            {
                label: 'Firm',
                name: 'firmId',
                transformer: (value: string, model: any) => model.firm.name,
            },
            'bankAccountType',
            'friendlyName',
            'last4',
            {
                name: 'payeezyMerchantId'
            },
            {
                name: 'payeezyMerchantToken',
                transformer: 'token'
            },
            {
                name: 'payeezyTransarmorToken',
                transformer: 'token'
            },
            {
                name: 'status',
                transformer: (value, model: any) => model.archived ? 'ARCHIVED' : value
            },
            'isDefault',
            'archived',
            // 'achStatus',
            {
                name: 'voidedCheck',
                transformer: (value) => {
                    if (!value) return;
                    return `<a href="${value}" target="_blank">View</a>`;
                }
            },
            {
                name: 'voidedCheckExists',
                label: 'Voided check is provided',
                transformer: (value) => value ? 'Yes' : 'No'
            },
        ];
        this.config = modelConfig([
            {
                type: 'input',
                name: 'id',
                readonly: true
            },
            {
                type: 'input',
                name: 'firmId',
                readonly: true
            },
            {
                type: 'select',
                name: 'bankAccountType',
                options: ['OPERATING', 'TRUST', 'PAYMENT']
            },
            // {
            //   type: 'select',
            //   name: 'achStatus',
            //   options: ['NOT_CONNECTED', 'CONNECTED', 'VERIFIED']
            // },
            {
                type: 'input',
                name: 'friendlyName'
            },
            {
                type: 'input',
                name: 'last4'
            },
            {
                label: 'Unique ID',
                type: 'input',
                name: 'uniqueIdentity',
                readonly: true
            },
            {
                label: 'Payeezy Merchant ID (12-15 digits)',
                type: 'input',
                name: 'payeezyMerchantId',
                pattern: '^[0-9]{12,15}$',
            },
            {
                label: 'Payeezy Merchant Token (53 symbols)',
                type: 'input',
                name: 'payeezyMerchantToken',
                pattern: '^[^\\s]{53}$',
            },
            {
                label: 'Status (set to ACTIVE when you set Payeezy Merchant Token)',
                type: 'select',
                name: 'status',
                options: ['ACTIVE', 'PENDING']
            },
            {
                type: 'checkbox',
                name: 'archived'
            },
            {
                type: 'checkbox',
                name: 'isDefault'
            },
            {
                type: 'json',
                name: 'credentials',
                editable: false,
                additional: true
            },
            {
                type: 'image',
                name: 'voidedCheck',
                allowedExtensions: ALLOWED_IMAGE_EXTENSIONS,
                width: 50
            },
            {
                type: 'json',
                name: 'issues',
                editable: false,
                additional: true
            },
            {
                type: 'input',
                name: 'createdAt',
                readonly: true,
                additional: true
            },
            {
                type: 'input',
                name: 'updatedAt',
                readonly: true,
                additional: true
            },
            {
                type: 'datepicker',
                name: 'archivedDate',
                readonly: true,
                additional: true
            }
        ]);
        this.relations = modelRelations([
            {
                crmModule: 'firms',
                crmAlias: 'firm',
                params: this.firmId,
                resource: 'firm'
            },
            {
                crmModule: 'firm-documents',
                crmAlias: 'Documents',
                params: { bankId: this.id },
                resource: 'firmDocument'
            },
            {
                crmModule: 'firm-settings',
                params: this.firmId,
                resource: 'firmSettings'
            },
            {
                crmModule: 'transactions',
                params: { bankId: this.id },
                resource: 'transaction'
            },
            {
                crmAlias: 'Batch Transactions',
                crmModule: 'nacha-transactions',
                params: { bankId: this.id },
                resource: 'nachaTransaction'
            },
            {
                crmModule: 'recurring-payments',
                params: { bankId: this.id },
                resource: 'recurringPayment'
            },
            {
                crmAlias: 'Bank Transactions',
                crmModule: 'headnote-banks/all/transactions',
                params: { firm_bank_account_id: this.id },
                resource: 'headnoteBanks',
            },
            {
                crmAlias: 'SVB Reconciliation Report',
                crmModule: 'headnote-banks/svb_operating_fbo/reconciliation-report',
                params: {firm_bank_account_id: this.id, headnote_bank_account_id: 'svb_operating_fbo'},
                resource: 'reconciliationReport',
            },
            {
                crmModule: 'audit-logs',
                params: { entity_type: 'bank', entity_id: this.id },
                resource: 'auditLog'
            },
        ]);
        this.configCreate = modelConfig([
            {
                type: 'input',
                name: 'firmId'
            },
            {
                type: 'select',
                name: 'bankAccountType',
                options: ['OPERATING', 'TRUST']
            },
            {
                type: 'input',
                name: 'bankName'
            },
            {
                type: 'input',
                name: 'accountHolderName'
            },
            {
                type: 'input',
                name: 'routingNumber'
            },
            {
                type: 'input',
                name: 'accountNumber'
            },
            {
                type: 'select',
                name: 'class',
                options: ['checking', 'saving']
            },
            {
                label: 'Payeezy Merchant Token (if necessary)',
                type: 'input',
                name: 'payeezyMerchantToken'
            },
            {
                label: 'Status',
                type: 'select',
                name: 'status',
                options: ['ACTIVE', 'PENDING']
            }
        ]);
    }

    get ownerEmail(): string {
        return this.profile && this.profile.email;
    }

    get detailsDisplayName(): string {
        return `Bank: ${this.id}`;
    }

    static filterFields(): Field[] {
        return modelConfig([
            {
                type: 'select',
                name: 'status',
                label: 'Filter by Status',
                cssClasses: 'col-sm-6 min-width-400',
                options: {
                    '': 'All',
                    'PENDING': 'PENDING',
                    'ACTIVE': 'ACTIVE',
                    'ARCHIVED': 'ARCHIVED'
                }
            },
            {
                type: 'input',
                name: 'last4'
            }
        ]);
    }
}
