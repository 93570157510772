import { Pipe, PipeTransform } from '@angular/core';
import { formatCurrency } from '@angular/common';
import { Field, formatDate } from '../../../shared';

@Pipe({
    name: 'transformer'
})
export class TransformerPipe implements PipeTransform {

    private allowedTransformers = [
        'fromDollars',
        'fromCents',
        'address',
        'token',
        'formatDate',
        'formatDateOnly'
    ];

    transform(value: any, transformer: ((value: any) => string) | string | undefined): string {
        if (typeof transformer === 'string' && this.allowedTransformers.includes(transformer)) {
            return this[transformer](value);
        }

        if (typeof transformer === 'function') {
            return transformer(value);
        }

        return value;
    }

    private fromDollars(amount: any): string {
        return formatCurrency(amount, 'en', '$');
    }

    private fromCents(amount: any): string {
        return this.fromDollars(amount / 100);
    }

    private formatDate(value: any): string {
        return formatDate(value);
    }

    private formatDateOnly(value: any): string {
        return formatDate(value);
    }

    private address(address: string): string {
        const encodedAddress = encodeURI(address);
        return `<a target="_blank" href="https://www.google.com/maps/search/?api=1&query=${encodedAddress}">${address}</a>`;
    }

    private token(token: string): string {
        return token ? 'Provided' : 'Not Provided';
    }

}
