import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { ERROR_MESSAGES } from '@app/protected/crm/transactions/transaction-upload/transaction-upload.constants';

type Model<Entity> = new(...any: any[]) => Entity;

interface DefaultEntity {
  id: string;
}

interface SearchOptions {
  pageIndex: number;
  pageSize: number;
}

interface EntityCount {
    count: number;
}

export class Service<Entity extends DefaultEntity> {
    protected apiRoot: string;
    protected entity: Model<Entity>;

    constructor(protected http: HttpClient) {}

    findAll<T>(search: SearchOptions | T): Observable<{ rows: Entity[], count: number, listTitle?: string }> {
        const params: HttpParams = new HttpParams({ fromObject: search as any });

        return this.http.get<{ rows: Entity[], count: number, pageTitle?: string }>(this.apiRoot, { params })
            .pipe(
                map(data => ({ ...data, rows: data.rows
                    .map(object => new this.entity(object))
                })),
                catchError((response: HttpErrorResponse) =>
                    throwError(() => new Error(response.error.message))
                )
            );
    }

    count(search: any): Observable<number> {
        const params: HttpParams = new HttpParams({ fromObject: search });
        return this.http.get<EntityCount>(`${this.apiRoot}/count`, { params })
            .pipe(map(data => data.count));
    }

    findById(id: string): Observable<Entity> {
        return this.http.get<Entity>(`${this.apiRoot}/${id}`)
            .pipe(map(data => new this.entity(data)));
    }

    create(data: object): Observable<Entity> {
        return this.http.post<Entity>(this.apiRoot, data)
            .pipe(
                map(itemData => new this.entity(itemData)),
                catchError((response: HttpErrorResponse) =>
                    throwError(() => new Error(response.error.message))
                )
            );
    }

    update(entity: Entity, values: object): Observable<any> {
        return this.http.put(`${this.apiRoot}/${entity.id}`, values)
            .pipe(catchError((response: HttpErrorResponse) =>
                throwError(() => new Error(response.error.message))
            ));
    }

    delete(entity: Entity): Observable<Entity> {
        return this.http.delete<any>(`${this.apiRoot}/${entity.id}`)
            .pipe(catchError((response: HttpErrorResponse) =>
                throwError(() => new Error(response.error.message))
            ));
    }

    handleRequestError(response: HttpErrorResponse) {
        const errorMessage = response.error && response.error.message;
        return throwError(() => new Error(errorMessage || ERROR_MESSAGES.SOMETHING_WENT_WRONG));
    }
}
