import { Model, Resource, modelConfig, modelRelations, Field } from '../data';
import * as moment from 'moment';

export class RecurringPaymentDate extends Model {
    resourceName: Resource = 'recurringPaymentDate';

    id: string;
    recurringPaymentId: string;
    amount: number;
    currency: string;
    surchargeAmount: number;
    total: number;
    status: string;
    triggerDate: string;
    runDateTime: Date;
    createdAt: Date;
    updatedAt: Date;

    recurringPayment: {
        firm: {
            partnerId: string;
            name: string,
            partnerName: string,
        }
    };

    constructor(attributes = {}) {
        super(attributes);
        super.update(attributes);
        this.overviewFields = [
            'id',
            {
                label: 'Reference ID',
                name: 'id',
                stayVisible: true,
                transformer: (value: string, model: any) => model.recurringPayment.referenceId || '-'
            },
            {
                label: 'Partner',
                name: 'id',
                transformer: <RecurringPaymentDate>(value, model) => {
                    const firm = model.recurringPayment.firm;
                    return `<a href='/crm/partners/${firm.partnerId}'>${firm.partnerName}</a>`;
                }
            },
            {
                label: 'Firm',
                name: 'id',
                transformer: (value: string, model: any) => model.recurringPayment.firm.name,
            },
            'status',
            {
                name: 'amount',
                transformer: 'formatAmount',
                cssClasses: 'bold'
            },
            {
                name: 'surchargeAmount',
                transformer: 'formatAmount'
            },
            {
                name: 'total',
                transformer: 'formatAmount',
                cssClasses: 'bold'
            },
            'runDateTime',
            'triggerDate',
            'lastPaymentAttemptDate'
        ];
        this.config = modelConfig([
            {
                type: 'input',
                name: 'id',
                readonly: true
            },
            {
                type: 'input',
                name: 'recurringPaymentId',
                readonly: true
            },
            {
                type: 'input',
                name: 'amount',
                prefix: (model) => model.currency
            },
            {
                type: 'input',
                name: 'surchargeAmount',
                prefix: (model) => model.currency
            },
            {
                type: 'input',
                name: 'total',
                prefix: (model) => model.currency
            },
            {
                type: 'select',
                name: 'status',
                options: {
                    PENDING: 'PENDING',
                    SUCCESSFUL: 'SUCCESSFUL',
                    FAILED: 'FAILED',
                    CANCELED: 'CANCELED'
                }
            },
            {
                type: 'input',
                name: 'triggerDate',
            },
            {
                label: 'Last Payment Attempt Date (prevents from processing twice per day)',
                type: 'input',
                name: 'lastPaymentAttemptDate',
            },
            {
                type: 'datepicker',
                name: 'runDateTime',
            },
            {
                type: 'input',
                name: 'createdAt',
                readonly: true,
            },
            {
                type: 'input',
                name: 'updatedAt',
                readonly: true,
            }
        ]);
        this.relations = modelRelations([
            {
                crmModule: 'recurring-payments',
                crmAlias: 'recurringPayment',
                params: this.recurringPaymentId,
                resource: 'recurringPayment'
            },
            {
                crmModule: 'transactions',
                params: { recurringPaymentDateId: this.id },
                resource: 'transaction'
            },
            {
                crmModule: 'audit-logs',
                params: { entity_type: 'scheduled_payment', entity_id: this.id },
                resource: 'auditLog'
            },
        ]);
    }

    get detailsDisplayName(): string {
        return `Recurring Payment Date`;
    }

    static filterFields(): Field[] {
        const today = moment().format('YYYY-MM-DD');
        const tomorrow = moment().add(1, 'day').format('YYYY-MM-DD');

        return modelConfig([
            {
                type: 'select',
                name: 'status',
                label: 'Filter by Status',
                cssClasses: 'col-sm-6 min-width-400',
                options: {
                    'PENDING': 'PENDING',
                    'PROCESSING': 'PROCESSING',
                    'SUCCESSFUL': 'SUCCESSFUL', // waiting for client to confirm
                    'FAILED': 'FAILED', // confirmed
                    'CANCELED': 'CANCELED', // plaid token expired
                }
            },
            {
                type: 'select',
                name: 'triggerDate',
                label: 'Filter by trigger date',
                cssClasses: 'col-sm-6 min-width-400',
                options: {
                    ['pastDue']: 'Past Due',
                    ['today']: 'Today',
                    ['tomorrow']: 'Tomorrow'
                }
            }
        ]);
    }
}
