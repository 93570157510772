import { Model, Resource, modelConfig, modelRelations } from '../data';

export class Client extends Model {
    resourceName: Resource = 'client';

    id: string;
    firmId: string;
    ownerId: string;
    address: string;
    city: string;
    company: string;
    disableEmails: boolean;
    email: string;
    firstName: string;
    lastName: string;
    notes: string;
    phone: string;
    source: string;
    state: string;
    zip: string;
    archived: boolean;
    archivedDate: string;
    createdAt: Date;
    updatedAt: Date;

    // deprecated fields
    dob: string;
    profileId: string;
    sample: boolean;

    firm: {
        partnerId: string;
        name: string,
        partnerName: string,
    };

    constructor(attributes = {}) {
        super(attributes);
        super.update(attributes);

        this.overviewFields = [
            'id',
            {
                label: 'Partner',
                name: 'firmId',
                transformer: <Client>(value, model) => `<a href='/crm/partners/${model.firm.partnerId}'>${model.firm.partnerName}</a>`
            },
            {
                label: 'Firm',
                name: 'firmId',
                transformer: (value: string, model: any) => model.firm.name,
            },
            'firstName',
            'lastName',
            'company',
            'email',
            'phone',
            'source',
            'createdAt'
        ];
        this.config = modelConfig([
            {
                type: 'input',
                name: 'id',
                readonly: true
            },
            {
                type: 'input',
                name: 'firmId',
                readonly: true
            },
            {
                type: 'input',
                name: 'firstName'
            },
            {
                type: 'input',
                name: 'lastName'
            },
            {
                type: 'input',
                name: 'company'
            },
            {
                type: 'input',
                name: 'email'
            },
            {
                type: 'input',
                name: 'phone'
            },
            {
                type: 'input',
                name: 'address'
            },
            {
                type: 'input',
                name: 'city'
            },
            {
                type: 'input',
                name: 'state'
            },
            {
                type: 'input',
                name: 'notes'
            },
            {
                type: 'checkbox',
                name: 'disableEmails'
            },
            {
                type: 'select',
                name: 'source',
                options: ['ATTORNEY', 'CLIENT']
            },
            {
                type: 'checkbox',
                name: 'archived'
            },
            {
                type: 'datepicker',
                name: 'archivedDate'
            },
            {
                type: 'input',
                name: 'createdAt',
                readonly: true,
            },
            {
                type: 'input',
                name: 'updatedAt',
                readonly: true,
            }
        ]);
        this.relations = modelRelations([
            {
                crmModule: 'firms',
                crmAlias: 'firm',
                params: this.firmId,
                resource: 'firm'
            },
            {
                crmModule: 'profiles',
                crmAlias: 'Attorney',
                params: this.ownerId,
                resource: 'profile'
            },
            {
                crmModule: 'client-cards',
                params: { clientId: this.id },
                resource: 'clientCard'
            },
            {
                crmModule: 'card-profiles',
                params: { clientId: this.id },
                resource: 'cardProfile'
            },
            {
                crmModule: 'client-bank-accounts',
                params: { clientId: this.id },
                resource: 'clientBankAccount'
            },
            {
                crmModule: 'transactions',
                params: { clientId: this.id },
                resource: 'transaction'
            },
            {
                crmModule: 'recurring-payments',
                params: { clientId: this.id },
                resource: 'recurringPayment'
            },
            {
                crmModule: 'audit-logs',
                params: { entity_type: 'client', entity_id: this.id },
                resource: 'auditLog'
            }
        ]);
    }

    get detailsDisplayName(): string {
        return `Client: ${this.firstName} ${this.lastName}`;
    }

    get name(): string {
        return `${this.firstName} ${this.lastName}`;
    }
}
