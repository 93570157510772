import { Component, Input, OnInit } from '@angular/core';
import { SUPERADMIN_ROLE, ADMIN_ROLE } from '@app/shared';
import { AuthenticationService } from '../../authentication';
import { Model, OverviewField } from '../../data';
import { normalizeLabel, formatDate, formatAmount } from '../../utils';
import { isModelValueNotEmpty } from '../../utils/helpers';

const fieldTransforms = {
    startDate: (date) => formatDate(date),
    createdAt: (date) => formatDate(date),
    fixedAt: (date) => formatDate(date),
    formatAmount: (value, model) => formatAmount(value, model.currency || 'USD')
};

@Component({
    selector: 'aw-overview',
    templateUrl: './overview.component.html'
})
export class OverviewComponent implements OnInit {

    @Input() model: Model;

    fields: Array<OverviewField | string>;

    constructor(
        private authenticationService: AuthenticationService
    ) { }

    ngOnInit() {
        this.fields = this.filterFields();
    }

    label(field: string | OverviewField): string {
        if ((field as OverviewField).labelFunction) {
            return (field as OverviewField).labelFunction(this.model);
        }
        if ((field as OverviewField).label) {
            return (field as OverviewField).label as string;
        }
        const name = this.fieldName(field);
        return normalizeLabel(name);
    }

    value(field: string | OverviewField): string {
        const name = this.fieldName(field);
        let value = this.model[name];

        if (fieldTransforms[name]) {
            value = fieldTransforms[name](value);
        }

        return value;
    }

    getTransformer(field) {
        const transformer = (field as OverviewField).transformer;
        const isSuperadmin = this.authenticationService.profile.role === SUPERADMIN_ROLE
            || this.authenticationService.profile.role === ADMIN_ROLE;

        if (transformer && typeof transformer === 'function') {
            return (value) => field.transformer(value, this.model, isSuperadmin);
        }

        if (transformer && typeof transformer === 'string') {
            // if transformer is one of functions from @fieldTransforms
            if (fieldTransforms[transformer]) {
                return (value) => fieldTransforms[transformer](value, this.model, isSuperadmin);
            }
            return field.transformer;
        }
    }

    private filterFields(): Array<OverviewField | string> {
        const overviewFiels = this.model.overviewFieldsFn
            ? this.model.overviewFieldsFn()
            : this.model.overviewFields;

        return overviewFiels
            .filter((field: OverviewField | string) => {
                // dont show fields with undefined and null values
                const name = this.fieldName(field);
                const notEmpty: boolean = isModelValueNotEmpty(this.model[name]);
                if (typeof field === 'object') {
                    return notEmpty || field.stayVisible || field.isSectionHeader;
                }
                return notEmpty;
            })
            .filter((field: OverviewField | string) => {
                // check if filed should be hidden
                if (typeof field === 'string') return true;
                if (typeof field.hide === 'function') {
                    return field.hide(this.model) !== true;
                }
                return true;
            })
            .filter((field: string | OverviewField) => {
                // return only fields which uset can view
                const name = this.fieldName(field);
                if (!this.model.hasOwnProperty(name)) return true;
                if (this.model.computedFields.includes(name)) return true;
                if (name === 'partnerName') return true;
                return this.authenticationService.can('readAny', this.model.resourceName, [name]);
            });
    }

    private fieldName(field: string | OverviewField): string {
        return (field as OverviewField).name || field as string;
    }
}
