import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { AuthenticationService } from './authentication.service';
import { ProfileGuard } from './profile.guard';

@Injectable()
export class IfPermissionsGuard implements CanActivate {
    constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private profileGuard: ProfileGuard
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.profileGuard.canActivate(route, state)
            .pipe(map(() => {
                const canAccess: boolean = this.authenticationService.can(
                    route.data.operation,
                    route.data.resource,
                    route.data.attributes
                );

                if (!canAccess) {
                    this.router.navigateByUrl('/');
                }

                return canAccess;
            }));
    }
}
