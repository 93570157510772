import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { NotesComponent } from './notes.component';
import { NotesService } from './notes.service';
import { MatButtonModule } from '@angular/material/button';

import { IfPermissionsModule } from '../if-permissions';

@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        IfPermissionsModule
    ],
    declarations: [NotesComponent],
    exports: [NotesComponent],
    providers: [NotesService]
})
export class NotesModule { }
