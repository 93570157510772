<h3>Notes</h3>

<p class="mb-3" *ngIf="!isLoading && !notes.length">
    <i><small>No notes yet</small></i>
</p>

<div *ngIf="!isLoading">
  <div *ngFor="let note of notes" class="mb-3">
    <div class="row">
      <div class="col-6">
        <mat-card-subtitle>{{ note.author?.firstName }} {{ note.author?.lastName }}</mat-card-subtitle>
      </div>
      <div class="col-6 text-right">
        <mat-card-subtitle>{{ formatDate(note.createdAt) }}</mat-card-subtitle>
      </div>
    </div>
    <mat-card-content>
      {{ note.text }}
    </mat-card-content>
  </div>
  <div *awIfPermissions="{resource: '$feature', attributes: ['notes'], operation: 'updateAny'}">
    <mat-form-field appearance="fill">
        <mat-label>Leave a note</mat-label>
        <textarea matInput [(ngModel)]="text" placeholder="" rows="5">
        </textarea>
    </mat-form-field>
    <button mat-stroked-button
            type="button"
            (click)="createNote(text)">
      Create Note
    </button>
  </div>
</div>
