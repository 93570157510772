import { Injectable } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment as environmentLocal } from '@environment';
import { Observable } from 'rxjs';

import { LocalStorageService } from '../../data';
import { NgEventBus } from '@lib/eventbus';
import { EVENT_BUS_EVENTS } from '@app/shared';


interface StoredCounter {
  count: number;
  updated: string;
}


@Injectable()
export class SidenavService {

    sidenav$: BehaviorSubject<MatSidenav> = new BehaviorSubject(null);

    protected apiRoot = `${environmentLocal.apiUrl}/fraud-alerts`;
    protected firmIssuesApiRoot = `${environmentLocal.apiUrl}/firm-issues`;

    protected localStorageKeys = {
        activeFraudAlerts: 'ACTIVE_FRAUD_ALERTS',
        activeFirmIssues:  'ACTIVE_FIRM_ISSUES',
    };

    protected localStorageConfig = {
        expirationTimeInSec: 60 // 1 minute
    };

    constructor(
        protected http: HttpClient,
        private eventBus: NgEventBus
    ) {}

    setSidenav(sidenav: MatSidenav): void {
        this.sidenav$.next(sidenav);
    }

    getActiveAlerts(): Observable<number> {
        return this.http.get<number>(`${this.apiRoot}/count/active`);
    }

    getActiveFirmIssues(): Observable<number> {
        return this.http.get<number>(`${this.firmIssuesApiRoot}/count/active`);
    }

    async updateCounters(forceApiCall: boolean = false) {
        const activeFraudAlertsCount = await this.getActiveAlertsCount(forceApiCall);
        const activeFirmIssuesCount = await this.getActiveFirmIssuesCount(forceApiCall);

        return { activeFraudAlertsCount, activeFirmIssuesCount };
    }

    async getActiveAlertsCount(forceApiCall: boolean): Promise<number> {
        // check if we already have value stored within last 1 minute
        const storedCount = this.getLocalStorageCounter(this.localStorageKeys.activeFraudAlerts);
        if (!forceApiCall && storedCount) {
            return storedCount;
        }

        try {
            // get from api and store in we haven't stored fresh value
            const count: number = await this.getActiveAlerts().toPromise();
            this.putLocalStorageCounter(this.localStorageKeys.activeFraudAlerts, count);

            if (forceApiCall) {
                this.eventBus.cast(EVENT_BUS_EVENTS.SIDENAV_COUNTER_UPDATE, { activeFraudAlertsCount: count });
            }
            return count;
        } catch (err) {
            // Do nothing
        }
    }

    async getActiveFirmIssuesCount(forceApiCall: boolean): Promise<number> {
        // check if we already have value stored within last 1 minute
        const storedCount = this.getLocalStorageCounter(this.localStorageKeys.activeFirmIssues);
        if (!forceApiCall && storedCount) {
            return storedCount;
        }

        try {
            // get from api and store in we haven't stored fresh value
            const count: number = await this.getActiveFirmIssues().toPromise();
            this.putLocalStorageCounter(this.localStorageKeys.activeFirmIssues, count);

            if (forceApiCall) {
                this.eventBus.cast(EVENT_BUS_EVENTS.SIDENAV_COUNTER_UPDATE, { activeFirmIssuesCount: count });
            }
            return count;
        } catch (err) {
            // Do nothing
        }
    }

    private getLocalStorageCounter(localStorageKey: string): number {
        const alertsStored: StoredCounter = LocalStorageService.getItem(localStorageKey);
        if (alertsStored) {
            const diff = Date.now() - new Date(alertsStored.updated).valueOf();
            if (diff < this.localStorageConfig.expirationTimeInSec * 1000) {
                return alertsStored.count;
            }
        }
        return null;
    }

    private putLocalStorageCounter(localStorageKey: string, count: number): void {
        LocalStorageService.setItem(localStorageKey, {
            count,
            updated: new Date().toISOString()
        });
    }
}
