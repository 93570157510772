import { Component, Input, Output, OnInit, OnDestroy, OnChanges, EventEmitter, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';

import { AuthenticationService } from '../../authentication';
import { Model, Field, FieldSelect } from '../../data';
import { Debounce } from '../../utils';
import { DynamicFormService, DynamicFormResetMode } from './dynamic-form.service';
import { FILE_UPLOADER_CONTROL_PREFIX } from './components/form-image/form-image.constants';

export interface DynamicFormFieldChangedEvent {
    fieldName: string;
    fieldValue: string;
}

export interface DynamicFormValues {
    [key: string]: number | string | object | File | boolean | null;
}

@Component({
    selector: 'aw-dynamic-form',
    template: `
        <form class="dynamic-form"
            [formGroup]="form"
            (ngSubmit)="onSubmit()">
            <ng-container *ngFor="let field of fields"
                awDynamicField
                [field]="field"
                [group]="form"
                [buttonSubmitText]="buttonSubmitText"
                >
            </ng-container>

            <div class="dynamic-field form-button">
                <button
                *ngIf="form"
                mat-raised-button
                [disabled]="form.invalid || (!form.dirty && !createAndReadyMode) || isLoading"
                type="submit"
                [color]="buttonColor">
                    {{isLoading ? 'Please wait...' : buttonSubmitText }}
                </button>

                <ng-content></ng-content>
            </div>
        </form>
    `,
    styles: [`
        ::ng-deep .dynamic-form .hidden {
            display: none;
        }
    `]
})
export class DynamicFormComponent implements OnInit, OnDestroy, OnChanges {
    @Input()
        model: Model;

    @Input()
        isLoading = false;

    @Input()
        config = 'config';

    @Input()
        buttonColor = 'warn';

    @Input()
        buttonSubmitText = 'Save changes';

    @Input()
        createMode = false;

    @Input()
        createAndReadyMode = false;

    @Input()
        additionalFields = false;

    @Output()
        submitted: EventEmitter<DynamicFormValues> = new EventEmitter<DynamicFormValues>();

    @Output()
        fieldValueChanged: EventEmitter<DynamicFormFieldChangedEvent> = new EventEmitter<DynamicFormFieldChangedEvent>();

    fields: Field[];

    form: FormGroup;

    private resetFormSubscription: Subscription;
    private successSubscription: Subscription;

    constructor(
        private fb: FormBuilder,
        private authenticationService: AuthenticationService,
        private dynamicFormService: DynamicFormService
    ) { }

    ngOnInit() {
        this.renderForm();
        this.scroll();
        this.resetFormSubscription = this.dynamicFormService.reset.subscribe((mode: DynamicFormResetMode) => {
            this.form.patchValue(this.model);
        });
        this.successSubscription = this.dynamicFormService.pristine.subscribe(
            () => this.form.markAsPristine()
        );
    }

    ngOnDestroy() {
        this.resetFormSubscription.unsubscribe();
        this.successSubscription.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.model && changes.model.currentValue && changes.model.previousValue) {
            this.form.patchValue(changes.model.currentValue);
        }
    }

    onSubmit() {
        const changedFields = this.changedFields();
        const changedFileUploaders = this.changedFields(FILE_UPLOADER_CONTROL_PREFIX);
        const allChangedFields = { ...changedFields, ...changedFileUploaders };

        if (Object.keys(allChangedFields).length) {
            this.submitted.emit(allChangedFields);
        }
    }

    private renderForm() {
        this.fields = this.filterFields();
        this.form = this.createGroup();
    }


    private changedFields(namePrefix = ''): DynamicFormValues {
        return this.fields.reduce((changedFields, field: Field) => {
            const control = this.form.controls[namePrefix + field.name];
            if (!control) return changedFields;
            const createMode = this.createMode || this.createAndReadyMode;

            if ((control.dirty && control.value !== this.model[field.name]) || (createMode && control.value !== undefined)) {
                if (field.type === 'select' && control.value === 'null') {
                    // we should set value to null if user selected 'null' option
                    changedFields[field.name] = null;
                } else {
                    changedFields[field.name] = control.value;
                }
            }

            return changedFields;
        }, {});
    }

    private filterFields(): Field[] {
        return this.model[this.config]
            .filter((field: Field) => {
                if (field.hideIf) {
                    if (field.hideIf(this.model)) {
                        delete field[field.name];
                        field.cssClasses = 'hidden';
                    } else {
                        field.cssClasses = '';
                    }
                }
                if (this.config === 'configCreate') return true;
                if (this.config === 'configUpload') return true;
                if (field.type === 'section-header') {
                    if (this.additionalFields && field.additional) return true;
                    if (!this.additionalFields && !field.additional) return true;
                }
                if (!this.additionalFields && field.additional) return false;
                if (this.additionalFields && !field.additional) return false;
                return this.authenticationService.can('readAny', this.model.resourceName, [field.name]);
            });
    }

    private createGroup(): FormGroup {
        const group = this.fb.group({});

        this.fields.forEach((field: Field) => {
            const value = this.model[field.name];
            const canUpdate = this.authenticationService.can('updateAny', this.model.resourceName, [field.name]);
            const disabled = field.readonly || (this.config !== 'configCreate' && !canUpdate);

            if ((<any>field).prefix && typeof (<any>field).prefix === 'function') {
                (<any>field).prefix = (<any>field).prefix(this.model);
            }

            group.addControl(field.name, this.fb.control({ value, disabled }));


            // adds value changed handler
            group.get(field.name).valueChanges.subscribe(fieldValue => {
                this.fieldValueChanged.emit({ fieldName: field.name, fieldValue });
            });

            // if some field has type "image" we create additional control (so form will have controls barCard and $barCard)
            // and that additional control $barCard emits FileUploader as its value
            if (field.type === 'image' || field.type === 'file') {
                group.addControl(`${FILE_UPLOADER_CONTROL_PREFIX}${field.name}`, this.fb.control({ value: null, disabled }));
                group.get(`$${field.name}`).valueChanges.subscribe((newValue) => {
                    this.fieldValueChanged.emit({ fieldName: `${FILE_UPLOADER_CONTROL_PREFIX}${field.name}`, fieldValue: newValue });
                });
            }
        });

        return group;
    }

    /**
     * Scroll to an anchor, if specified
     * Note: debounce, just to be sure, element is rendered
     */
    @Debounce()
    private scroll() {
        const anchor = location.hash;

        if (!anchor) {
            return;
        }

        const element = document.querySelector(anchor);

        if (element) {
            element.scrollIntoView();
        }
    }
}
