import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';

import { TransformerPipe } from './transformer.pipe';
import { OverviewComponent } from './overview.component';
import { FilePreviewModule } from '../file-preview';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        MatCardModule,
        MatDividerModule,
        MatListModule,
        FilePreviewModule
    ],
    declarations: [
        TransformerPipe,
        OverviewComponent
    ],
    exports: [OverviewComponent],
})
export class OverviewModule { }
