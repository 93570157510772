import { Model } from '../data';

export class Note extends Model {
    id: string;
    modelName: string;
    itemId: string;
    author: string;
    text: string;
    createdAt: Date;
    updatedAt: Date;
    sk: string;
    constructor(attributes: any = {}) {
        super(attributes);
        super.update(attributes);
    }
}
