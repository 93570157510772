import { Injectable } from '@angular/core';
import { NgProgress, NgProgressRef } from 'ngx-progressbar';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class LoadingService {

    /**
   * Loading Shown Behavior Subject
   */
    loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    progressRef: NgProgressRef;

    /**
   * Last time shown loading
   */
    private timer;

    constructor(private ngProgress: NgProgress) {
        this.progressRef = ngProgress.ref();
    }

    /**
   * Display loading state overlay
   */
    showLoading() {
        clearTimeout(this.timer);

        this.loading$.next(true);
        this.progressRef.start();
    }

    /**
   * Hide loading state overlay
   */
    hideLoading() {
        clearTimeout(this.timer);

        this.timer = setTimeout(() => {
            this.loading$.next(false);
            this.progressRef.complete();
        }, 500);
    }
}
