import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { DialogService } from './dialog.service';
import { NotificationService } from './notification.service';

@NgModule({
    imports: [
        MatDialogModule,
        MatSnackBarModule
    ],
    providers: [
        DialogService,
        NotificationService
    ],
    exports: [
        MatDialogModule,
        MatSnackBarModule
    ]
})
export class DialogModule {}
