<aw-page-heading>
  <h1>{{ getTitle() }}</h1>
  <div class="actions">
    <ng-content select=".actions"></ng-content>
  </div>
</aw-page-heading>

<div class="panel-body">
  <mat-card appearance="outlined">
    <div class="p-5 text-center" *ngIf="!list">
      <mat-spinner></mat-spinner>
    </div>

    <ng-container *ngIf="list">
        <aw-list-filter
            class="custom__list"
            [list]="list"
            [filterFields]="filterFields"
            [filterValues]="filterValues"
            (filterValuesChanged)="onFilterValuesChanged($event)">

            <a (click)="onExport()" *ngIf="list.hasExport" class="mat-mdc-button mat-button-light-border list-export-button">
                {{dataIsExporting ? 'Exporting...' : 'Export'}}
            </a>

            <a *ngIf="list.showRefreshButton" (click)="onRefresh()" class="mat-mdc-button mat-button-light-border list-refresh-button">
                Refresh
            </a>

            <div class="list-total-count" *ngIf="!dataIsLoading && !dynamoDbPagination">
                {{list.totalCount}} {{list.crmModule}}<span *ngIf="list.totalAmount">, {{list.totalAmount}}</span>
            </div>

        </aw-list-filter>

      <div *ngIf="!filterFields || !filterFields.length">
          <div class="list-total-count" style="padding-top: 20px;" *ngIf="!dataIsLoading">{{list.totalCount}} {{list.crmModule}}</div>
      </div>

      <div class="list-hint" *ngIf="list.hint">
         {{list.hint}}
      </div>

      <span *ngIf="dataIsLoading" class="loading-span">
          Loading....
      </span>

      <mat-table #table [dataSource]="dataSource" *ngIf="!dataIsLoading">

        <mat-header-row *matHeaderRowDef="list.columnNames"></mat-header-row>
        <mat-row *matRowDef="let row; columns: list.columnNames;"></mat-row>

        <ng-container *ngFor="let column of list.columns"
          [matColumnDef]="column.name">
          <mat-header-cell *matHeaderCellDef [ngStyle]="getHeaderStyles(column.name)" > {{ column.label }} </mat-header-cell>

            <mat-cell *matCellDef="let model" [ngStyle]="getStyle(column.name, model)"
                    [routerLink]="list.disableRowLinks ? [] : buildLink(model)">

                <a *ngIf="column.routerLink"
                  [routerLink]="buildLink(model)">
                  {{ getColumnValue(column, model) }}
                </a>

                <pre class="json-field"
                     *ngIf="!column.routerLink && isJSONField(column.name)">{{ getColumnValue(column, model) }}</pre>

                <span [innerHTML]="getColumnValue(column, model)"
                       *ngIf="!column.routerLink && isHTMLField(column.name)"></span>

                <span class="text-truncate" matTooltip="{{model[column.name]}}"
                        *ngIf="!column.routerLink && !isJSONField(column.name) && !isHTMLField(column.name)">
                  {{ getColumnValue(column, model) }}
                </span>
            </mat-cell>

        </ng-container>

      </mat-table>

      <mat-paginator  *ngIf="!dataIsLoading" #paginator
        [pageSizeOptions]="list.pageSizeOptions"
        [length]="list.totalCount"
        [pageIndex]="paginationParams.pageIndex"
        [pageSize]="paginationParams.pageSize"
        [hidePageSize]="list.totalCount < list.pageSizeOptions[0]"
        (page)="onPageChange($event)"
        [showFirstLastButtons]="!dynamoDbPagination">
      </mat-paginator>

    </ng-container>
  </mat-card>
</div>
