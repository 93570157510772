import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Field } from '../../../../data';

@Component({
    selector: 'aw-form-datepicker',
    template: `
    <div class="dynamic-field form-input" class="{{ field.cssClasses }}"
      [formGroup]="group">
      <mat-form-field class="custom_form__field" appearance="fill">
        <mat-label>{{ field.label }}</mat-label>
        <input
          matInput
          [matDatepicker]="date"
          [formControlName]="field.name"
          placeholder="">
        <mat-datepicker-toggle matSuffix [for]="date"></mat-datepicker-toggle>
        <mat-datepicker #date></mat-datepicker>
      </mat-form-field>
    </div>
  `,
})
export class FormDatepickerComponent {
    field: Field;
    group: FormGroup;
}
