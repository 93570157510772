export const SCHEDULE_NAMES = {
    MONTH: 'every month',
    DAY: 'every day',
    WEEK: 'every week',
    TWO_WEEKS: 'every 2 weeks',
    FOUR_WEEKS: 'every 4 weeks',
    TWICE_MONTH: '1st and 16th day of month',
};

export const SCHEDULE_OPTIONS = {
    [JSON.stringify({ 'months': 1 })]: SCHEDULE_NAMES.MONTH,
    [JSON.stringify({ 'days': 1 })]: SCHEDULE_NAMES.DAY,
    [JSON.stringify({ 'days': 7 })]: SCHEDULE_NAMES.WEEK,
    [JSON.stringify({ 'days': 14 })]: SCHEDULE_NAMES.TWO_WEEKS,
    [JSON.stringify({ 'days': 28 })]: SCHEDULE_NAMES.FOUR_WEEKS,
    [JSON.stringify({ 'days': 15 })]: SCHEDULE_NAMES.TWICE_MONTH,
};
