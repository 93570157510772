import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

import { NgEventBus } from '@lib/eventbus';
import { Field } from '../../../../data';
import { HttpClient } from '@angular/common/http';
import { environment as environmentLocal } from '@environment';
import { EVENT_BUS_EVENTS } from '@app/shared';

interface FormSelectOption {
    value: number | string;
    label: string;
    isDefault?: boolean;
}

@Component({
    selector: 'aw-form-select',
    template: `
    <div class="dynamic-field form-select" class="{{ field.cssClasses }}"
      [formGroup]="group">
      <mat-form-field class="custom_form__field" appearance="fill">
        <mat-label>{{ field.label }}</mat-label>
        <mat-select
            [(value)]="selected"
            [formControlName]="field.name"
            [placeholder]=""
            required="{{ field.required ? true : false }}"
        >
          <mat-option *ngFor="let option of options"
            [value]="option.value"
            selected>
                {{option.label}}
                <i class="text-danger" *ngIf="option.isDefault && field.defaultValueLabel"> {{field.defaultValueLabel}}</i>
            </mat-option>
        </mat-select>
        <mat-hint class="margin-bottom-20" *ngIf="field.hint">{{ field.hint }}</mat-hint>
      </mat-form-field>
    </div>
  `,
})
export class FormSelectComponent implements OnInit, OnDestroy {
    field: Field;
    group: FormGroup;
    selected: string;

    /**
     * Default options, like {'': 'All partners'} or {'null': 'None'}
     */
    defaultOptions: FormSelectOption[] = [];
    /**
     * All other options
     */
    options: FormSelectOption[] = [];
    /**
     * Subscription for FORM_SELECT_OPTIONS_CHANGED event
     */
    formSelectOptionsChangedSub: Subscription;

    constructor(
        protected http: HttpClient,
        private eventBus: NgEventBus
    ) { }

    ngOnInit() {
        const options = this.field['options'];
        const defaultValue = options.defaultValue;

        if (options.url) {
            if (options.default) {
                this.defaultOptions = this.buildOptions(options.default);
            }
            // TODO Need refactor.
            // TODO Move load select option early then component will be rendered first time.
            // TODO Need to update logic for case, when we get select option form server
            // (return only arrays or object and similar). Do it more general
            this.fetchOptions(options.url).subscribe((data) => {
                this.setOptions(data);
                this.selected = defaultValue;
            });
        } else {
            this.setOptions(options);
        }

        // listens FORM_SELECT_OPTIONS_CHANGED_EVENT to update select options
        this.formSelectOptionsChangedSub = this.eventBus.on(
            EVENT_BUS_EVENTS.FORM_SELECT_OPTIONS_CHANGED_EVENT
        ).subscribe(({ data }) => {
            const newOptions = data[this.field.name];
            if (!newOptions) {
                return;
            }
            this.setOptions(newOptions);
        });
    }

    ngOnDestroy() {
        this.formSelectOptionsChangedSub.unsubscribe();
    }

    /**
     * Fetches options from backend
     * @param url
     */
    private fetchOptions(url: string) {
        return this.http.get(`${environmentLocal.apiUrl}${url}`);
    }

    private setOptions(options) {
        this.options = [
            ...this.defaultOptions,
            ...this.buildOptions(options)
        ];
    }

    private buildOptions(options): FormSelectOption[] {
        if (
            Array.isArray(options) && options[0] && this.isFormSelectOption(options[0])
        ) return options;

        if (Array.isArray(options)) {
            return (<string[]>options).map(item => ({
                value: item,
                label: item,
                isDefault: item === this.field.defaultValue
            }));
        }
        if (typeof options === 'object' && options !== null) {
            return Object.keys(<object>options).map(
                key => {
                    if (typeof options[key] === 'object' && this.isFormSelectOption(options[key])) {
                        return <FormSelectOption>options[key];
                    } else {
                        return {
                            value: key,
                            label: options[key],
                            isDefault: key === this.field.defaultValue
                        };
                    }
                }
            );
        }
    }

    private isFormSelectOption(object: any): object is FormSelectOption {
        return typeof object === 'object' && 'value' in object && 'label' in object;
    }
}
