import { Model } from '../../data';
import { normalizeLabel } from '../../utils';

export interface Column {
    name: string;
    label: string;
    routerLink: boolean;
}

interface ColumnLabels {
    [key: string]: string;
}

export interface ListSearchResult {
    count: number,
    last_evaluated_key?: string
}

export class List extends Model {
    listTitle?: string;
    crmModule?: string;
    module?: string;
    pageSizeOptions?: number[] = [25, 50, 100];
    columnNames: string[];
    columnLabels?: ColumnLabels;
    columns: Column[];
    routeTemplate: string;
    linkColumn: string;
    routeLinkColumnTemplate?: string;
    data: object[];
    hint?: string;
    totalCount: number;
    totalAmount?: string;
    getColumnStyle?: (columnName: string, data: any) => string;
    getHeaderStyle?: (columnName: string) => string;
    hasExport?: boolean;
    exportPageSize?: number = 10000;
    exportApiUrl?: boolean;
    showRefreshButton: boolean;
    disableRowLinks?: boolean;
    columnTypes?: {
        [key: string]: 'json' | 'html';
    };

    constructor(attributes: object) {
        super(attributes);
        super.update(attributes);

        this.columns = this.columnNames.map((columnName: string, i: number) => (<Column>{
            name: columnName,
            label: (this.columnLabels && this.columnLabels[columnName]) || normalizeLabel(columnName),
            routerLink: this.linkColumn ? (columnName === this.linkColumn) : (i === 0)
        }));
    }

    reload() {
        window.location.assign(`/crm/${this.crmModule}`);
    }
}
