import { NgModule } from '@angular/core';
import { FilePreviewComponent } from './file-preview.component';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        FilePreviewComponent
    ],
    exports: [FilePreviewComponent],
})
export class FilePreviewModule { }
