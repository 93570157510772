import * as _ from 'lodash';
import * as moment from 'moment';
import { DEFAULT_CURRENCY, searchFirm, TRANSACTION } from '../constants';
import { Model, Resource, modelConfig, modelRelations, Field, FieldConfig } from '../data';
import { formatAmount, convertToUSD } from '../utils';
import { getCurrentTimezone} from '@app/shared';
import { NETWORK_FEES_CSV_TEMPLATE, NETWORK_FEES_CSV_TEMPLATE_FILE_NAME } from '../constants';

export class Transaction extends Model {
    resourceName: Resource = 'transaction';

    id: string;
    batchId: string;
    batchTransactionId?: string;
    shortId: string;
    ownerId: string;
    firmId: string;
    invoiceId: string;
    referenceId: string;
    bankId: string;
    dateTime: Date;
    amount: number;
    usdExchangeRate: number;
    currency: string;
    interestAmount: number;
    surchargeAmount: string;
    total: number;
    feeAmount: number;
    type: string;
    category: string;
    subCategory: string;
    transaction: object;
    method: string;
    memo: string;
    checkNumber: string;
    bankInfo: object;
    status: keyof typeof TRANSACTION.STATUS;
    paidBy: string;
    paidByType: string;
    reason: string;
    metadata: object;
    archived: boolean;
    errorMessage: string;
    archivedDate: Date;
    createdAt: Date;
    updatedAt: Date;
    processedAt: Date;
    fundedAt: Date;
    parentTransactionId: string;
    recurringPaymentId: string;
    recurringPaymentDateId: string;
    cardId: string;
    clientBankAccountId: string;
    networkTokenWasRequested?: boolean;
    networkTokenWasUsed?: boolean | null;
    clientId?: string;
    cardProfileId: string;
    configUpload: FieldConfig[];

    bankingPartnerId: string;
    bankingPartnerName: string;


    firm: {
        partnerId: string;
        name: string,
        partnerName: string,
    };

    bank?: {
        id: string;
        bankAccountType: string;
        friendlyName: string;
        last4: string;
    };

    clientBankAccount?: {
        id: string;
        name: string;
        last4: string;
    };

    clientCard?: {
        id: string;
        name: string;
        last4: string;
    };

    invoice: Record<'clientId', string>;

    get isPayeezyPayment(): boolean {
        return _.get(this, 'transaction.type') === 'PAYEEZY';
    }

    get isRapidconnectPayment(): boolean {
        return _.get(this, 'transaction.type') === 'HP_PAYMENTS_RAPIDCONNECT';
    }

    get payeezyTransactionId(): string {
        return _.get(this, 'transaction.transaction.transaction_id');
    }

    get amountInCents(): number {
        return parseFloat(`${this.total}`) * 100;
    }

    get firstdataTransactionsFilter(): object {
        return {
            headnote_transaction_id: this.id
        };
    }

    get authId() {
        if (this.isRapidconnectPayment) {
            return _.get(this, 'transaction.result.data.currency_transfer.auth_id');
        }
        if (this.isPayeezyPayment) {
            return _.get(this, 'transaction.transaction.transaction_id');
        }
    }

    constructor(attributes = {}) {
        super(attributes);
        super.update(attributes);


        this.overviewFields = [
            'id',
            {
                label: 'Partner',
                name: 'firmId',
                transformer: <Transaction>(value, model) => `<a href='/crm/partners/${model.firm.partnerId}'>${model.firm.partnerName}</a>`
            },
            {
                label: 'Banking Partner',
                name: 'bankingPartnerId'
            },
            {
                label: 'Firm',
                name: 'firmId',
                transformer: (value: string, model: any) => model.firm.name,
            },
            'shortId',
            {
                label: 'Reference ID',
                name: 'referenceId',
                stayVisible: true,
                transformer: (value: string) => value || '-'
            },
            {
                label: 'Category',
                name: 'category',
                transformer: (value, model: any) => {
                    if (model.category === 'FEE' || model.category === 'AUTHORIZATION') {
                        return model.category;
                    }
                    return model.type + ' ' + model.category;
                }
            },
            {
                label: 'Sub Category',
                name: 'subCategory'
            },
            {
                label: 'Payment Method',
                name: 'method',
                transformer: (value) => value
            },
            {
                label: 'Banking Partner',
                name: 'bankingPartnerName'
            },
            {
                label: 'Card Type',
                name: 'method',
                hide: (model: any) => {
                    if (model.method !== 'CARD') return true;
                    return !model.clientCardType;
                },
                transformer(value: string, model: any): string {
                    if (model.method !== 'CARD') return;
                    return model.clientCardType;
                }
            },
            {
                name: 'currency'
            },
            {
                label: 'Client',
                name: 'clientId',
                transformer: (value: string, model: any) => {
                    const client = model.client || {};
                    return `<a href="/crm/clients/${model.clientId}">${client.firstName} ${client.lastName}</a>`;
                },
            },
            {
                name: 'id',
                labelFunction: (model) => {
                    if (['PAYMENT'].includes(model.category)) {
                        return 'Source';
                    }
                    if (['REFUND'].includes(model.category)) {
                        return 'Destination';
                    }
                    return '';
                },
                transformer: (value: string, model: any) => {
                    if (model.method === 'CARD') {
                        return model.clientCardDescription;
                    }
                    if (model.method === 'ACH') {
                        return model.clientBankAccountDescription;
                    }
                },
                hide: (model: any) => ['PAYMENT', 'REFUND'].indexOf(model.category) === -1
            },
            {
                labelFunction: (model) => {
                    if (['FEE', 'REFUND'].includes(model.category)) {
                        return 'Source';
                    }
                    if (['PAYMENT', 'PAYOUT'].includes(model.category)) {
                        return 'Destination';
                    }
                    return 'Bank';
                },
                name: 'bankId',
                transformer: (value: string, model: any) => {
                    if (!value) return `Default`;
                    const archived = model.bank.archived ? ' (archived)' : '';

                    return `<a href="/crm/banks/${model.bankId}">
                ${model.bank.bankAccountType} - ${model.bank.friendlyName} ${model.bank.last4}${archived}</a>`;
                },
            },
            {
                name: 'amount',
                transformer: 'formatAmount',
                hide: (model: any) => ['FEE'].includes(model.category)
            },
            {
                name: 'surchargeAmount',
                transformer: 'formatAmount',
                hide: (model: any) => ['FEE'].includes(model.category)
            },
            {
                name: 'total',
                transformer: 'formatAmount',
                cssClasses: 'bold'
            },
            {
                name: 'usdExchangeRate',
                transformer: (value) => parseFloat(value),
                hide: (model: any) => model.currency === DEFAULT_CURRENCY
            },
            {
                name: 'total',
                label: 'Total in ' + DEFAULT_CURRENCY,
                transformer: (value, model: any) => {
                    // show USD amount from Payeezy transaction details
                    // if we have transaction.transaction
                    if (model.transaction && model.transaction.transaction) {
                        const payeezyAmountInCents: number = model.transaction.transaction.amount;
                        const payeezyAmountInUsd: string = parseFloat((payeezyAmountInCents / 100).toString()).toFixed(2);

                        return formatAmount(payeezyAmountInUsd, DEFAULT_CURRENCY);
                    }
                    // otherwise calculate total in USD using usdExchangeRate
                    if (this.total && this.usdExchangeRate) {
                        const totalInUSD = convertToUSD(this.total, this.usdExchangeRate);
                        return formatAmount(totalInUSD, DEFAULT_CURRENCY);
                    }
                },
                hide: (model: any) => model.currency === 'USD'
            },
            {
                name: 'feeAmount',
                transformer: 'fromDollars',
                hide: (model: any) => model.category === 'FEE' || model.category === 'PAYOUT'
            },
            {
                label: 'Is Recurring',
                name: 'recurringPaymentDateId',
                transformer: (value) => value ? 'YES' : 'NO'
            },
            {
                name: 'status',
                transformer: (value) => '<b>' + value + '</b>'
            },
            'reason',
            'errorMessage',
            {
                label: 'NACHA File',
                name: 'batchId',
                transformer(value, model, isSuperadmin) {
                    return isSuperadmin ? `<a href="/crm/nacha-files/${value}">${value}</a>` : value;
                }
            },
            {
                name: 'stripeTransaction',
                transformer(value): string {
                    return `<a target="_blank" href="https://dashboard.stripe.com/payments/${value}">Stripe Dashboard</a>`;
                }
            },
            // {
            //   label: 'Payment System',
            //   name: 'method',
            //   transformer: (value, model: any) => {
            //     const platformType = _.get(model, 'transaction.type');
            //
            //     return (model.method === 'CARD') ? platformType : model.method;
            //   }
            // },
            {
                label: 'Auth ID',
                name: 'transaction',
                transformer: (value: string, model: any): string => '<b>' + model.authId + '</b>',
                hide: (model: any): boolean => !model.authId
            },
            {
                label: 'Batch Transaction',
                name: 'id',
                transformer: (value: string, model: any) =>
                    (model.batchTransactionId) ? `<a href="/crm/nacha-transactions/${model.batchTransactionId}">
                       ${model.batchTransactionId}</a>` : 'NONE'

            },
            {
                name: 'networkTokenWasRequested',
                transformer: (value: boolean) => value ? 'YES' : 'NO'
            },
            {
                name: 'networkTokenWasUsed',
                transformer: (value: boolean) => value ? 'YES' : 'NO'
            },
            'createdAt',
            {
                label: 'To Be Processed At',
                name: 'dateTime',
                transformer: 'formatDate',
                hide: (model: any) => model.category === 'CLIENT_FEE'
            },
            {
                label: 'To Be Transferred Date',
                name: 'toBeTransferredDate',
                transformer: 'formatDate',
                hide: (model: any) => model.category !== 'CLIENT_FEE'
            },
            {
                name: 'processedAt',
                transformer: 'formatDate'
            },
            // {
            //     name: 'fundedAt',
            //     transformer: 'formatDate'
            // }
        ];
        this.config = modelConfig([
            {
                type: 'input',
                name: 'id',
                readonly: true
            },
            {
                type: 'input',
                name: 'shortId',
                readonly: true
            },
            {
                type: 'input',
                name: 'firmId',
                readonly: true
            },
            {
                type: 'input',
                name: 'invoiceId',
                readonly: true
            },
            {
                type: 'input',
                name: 'clientId',
                readonly: true
            },
            {
                type: 'input',
                name: 'referenceId',
                readonly: true
            },
            {
                label: 'Banking Partner',
                type: 'select',
                name: 'bankingPartnerId',
                options: {
                    url: '/banking-partners/list',
                }
            },
            {
                type: 'select',
                name: 'bankId',
                options: {
                    url: `/banks/names?firmId=${this.firmId}&allowArchived=true&userTimezone=` + getCurrentTimezone(),
                    default: {'null': '---'}
                }
            },
            {
                type: 'input',
                name: 'batchTransactionId'
            },
            {
                type: 'input',
                name: 'cardId'
            },
            {
                type: 'input',
                name: 'clientBankAccountId'
            },
            {
                type: 'input',
                name: 'amount',
                prefix: (model) => model.currency
            },
            {
                type: 'input',
                name: 'surchargeAmount',
                readonly: true,
                prefix: (model) => model.currency
            },
            {
                type: 'input',
                name: 'total',
                prefix: (model) => model.currency
            },
            {
                type: 'input',
                name: 'feeAmount',
                readonly: false,
                prefix: 'USD'
            },
            {
                type: 'select',
                name: 'type',
                options: ['OPERATING', 'TRUST'],
                readonly: true
            },
            {
                type: 'select',
                name: 'category',
                options: {
                    url: '/transactions/categories'
                },
                readonly: true
            },
            {
                type: 'select',
                name: 'subCategory',
                options: ['CHARGEBACK_FEE', 'INSUFFICIENT_FUNDS_FEE', 'NETWORK_FEE', 'TRANSACTION_FEE', 'OTHER'],
                readonly: true
            },
            {
                type: 'select',
                name: 'method',
                options: ['ACH', 'CARD', 'CASH', 'CHECK', 'OTHER'],
                readonly: true
            },
            {
                type: 'select',
                name: 'paidByType',
                options: ['ATTORNEY', 'CLIENT', 'HEADNOTE'],
                readonly: true
            },
            {
                type: 'select',
                name: 'status',
                options: {
                    url: '/transactions/statuses'
                }
            },
            {
                label: 'Error Message',
                type: 'input',
                name: 'errorMessage'
            },
            {
                label: 'Credit card or Bank info',
                type: 'json',
                name: 'bankInfo',
                editable: false,
                additional: true
            },
            {
                label: 'Reason (here we store some additional notes for REFUNDs)',
                type: 'textarea',
                name: 'reason'
            },
            {
                label: 'Transaction details',
                type: 'json',
                name: 'transaction',
                editable: true,
                additional: true
            },
            {
                type: 'input',
                name: 'batchId',
                label: 'NACHA File',
                readonly: true,
                additional: true
            },
            {
                type: 'json',
                name: 'metadata',
                editable: false,
                additional: true
            },
            {
                type: 'datepicker',
                name: 'createdAt'
            },
            {
                label: 'To Be Processed At',
                type: 'datepicker',
                name: 'dateTime',
                hideIf: (values) => values.category === 'CLIENT_FEE'
            },
            {
                label: 'To Be Transferred Date',
                type: 'datepicker',
                name: 'toBeTransferredDate',
                hideIf: (values) => values.category !== 'CLIENT_FEE'
            },
            {
                type: 'datepicker',
                name: 'updatedAt',
                readonly: true
            },
            {
                type: 'checkbox',
                name: 'archived'
            },
            {
                type: 'datepicker',
                name: 'archivedDate',
                readonly: true
            }
        ]);
        this.configUpload = modelConfig([
            {
                label: 'Please choose a fee CSV file:',
                type: 'file',
                name: 'csvFile',
                maxFileSizeInBytes: 40 * 1024,
                allowedExtensions: '.csv',
                hint: 'Max file length: 100 lines. Max file size: 40KB.',
                btnTitle: 'Choose a fee CSV file',
                sampleLinkConfig: {
                    linkText: 'Get a fee CSV file template',
                    onClick: () => {
                        const a = document.createElement('a');
                        a.href = NETWORK_FEES_CSV_TEMPLATE;
                        a.download = NETWORK_FEES_CSV_TEMPLATE_FILE_NAME;
                        a.click();
                    }
                }
            }
        ]);
        this.configCreate = modelConfig([
            {
                type: 'select',
                label: 'Transaction Category',
                name: 'category',
                options: {
                    'REFUND': 'Refund',
                    'PAYOUT:OPERATING': 'Operating Payout (Headnote Operating ZBO -> Firm Operating Account)',
                    'PAYOUT:TRUST': 'Trust Payout (Headnote Operating ZBO -> Firm Trust Account)',
                    'CREDIT:OPERATING': 'Credit (Headnote Revenue Account -> Firm Operating Account)',
                    'CREDIT:TRUST': 'Credit (Headnote Revenue Account -> Firm Trust Account)',
                    'FEE:OPERATING': 'Fee (Firm Operating Account -> Headnote Revenue Account)',
                    'CHARGEBACK:OPERATING': 'Operating Chargeback (Firm Operating Account -> Headnote Operating FBO)',
                    'CHARGEBACK:TRUST': 'Trust Chargeback (Firm Trust Account -> Headnote Operating FBO)',
                }
            },
            {
                type: 'select',
                label: 'Transaction Sub Category',
                name: 'subCategory',
                options: {
                    CHARGEBACK_FEE: 'CHARGEBACK_FEE',
                    INSUFFICIENT_FUNDS_FEE: 'INSUFFICIENT_FUNDS_FEE',
                    NETWORK_FEE: 'NETWORK_FEE',
                    TRANSACTION_FEE: 'TRANSACTION_FEE',
                    OTHER: 'OTHER'
                }
            },
            {
                type: 'autocomplete',
                name: 'firmId',
                label: 'Firm',
                getOptions: this.getFirmOptionsAsync
            },
            {
                type: 'select',
                name: 'bankId',
                options: {},
                label: 'Firm bank account',
                defaultValueLabel: '(Default)'
            },
            {
                type: 'input',
                name: 'parentTransactionId',
                label: 'Parent Transaction ID (optional for CREDIT or NETWORK_FEE or OTHER)',
            },
            {
                label: 'Banking Partner',
                type: 'select',
                name: 'bankingPartnerId',
                defaultValueLabel: 'SVB',
                options: {
                    url: '/banking-partners/enabled-banking-partners-list',
                }
            },
            {
                type: 'select',
                label: 'Status',
                name: 'status',
                options: {
                    PENDING: 'PENDING',
                    SUCCESSFUL: 'SUCCESSFUL'
                }
            },
            {
                type: 'input',
                name: 'payeezyTransactionTag',
                label: 'Payeezy Transaction Tag',
                pattern: '^[0-9]+$',
            },
            {
                type: 'input',
                name: 'payeezyTransactionAuthCode',
                label: 'Payeezy Transaction Auth Code',
                pattern: '^[0-9A-Z]+$',
            },
            {
                label: 'Payeezy Transaction Created At (like 2020/12/04 01:43:42 MST)',
                type: 'input',
                name: 'createdAt',
                // we allow user to insert date in format like 2020/12/04 01:43:42 MST
                pattern: '^\\d{4}/\\d{2}/\\d{2} \\d{2}:\\d{2}:\\d{2} [A-Z]{3}$',
            },
            {
                label: 'Amount (e.g. 123.01)',
                type: 'input',
                name: 'amount',
                prefix: '$',
                pattern: '^[0-9]+\.[0-9]{2}$',
                cssClasses: 'max-width-200',
            },
            {
                type: 'textarea',
                name: 'reason'
            }
        ]);
        this.relations = modelRelations([
            {
                crmModule: 'profiles',
                crmAlias: 'Attorney',
                params: this.ownerId,
                resource: 'profile'
            },
            {
                crmModule: 'firms',
                crmAlias: 'firm',
                params: this.firmId,
                resource: 'firm'
            },
            {
                crmModule: 'transactions',
                crmAlias: 'Related Transactions',
                params: { invoiceId: this.invoiceId || '' },
                resource: 'transaction',
                hidden: (model) => model.invoiceId == null
            },
            {
                crmModule: 'currency-transfers',
                crmAlias: 'Currency Transfer',
                params: { reference_id: this.id },
                resource: 'currencyTransfer',
                disabled: (model) => model.batchTransactionId !== null
            },
            {
                crmModule: 'firstdata-transactions',
                params: this.firstdataTransactionsFilter,
                resource: 'firstdataTransaction'
            },
            {
                crmModule: 'recurring-payments',
                crmAlias: 'recurringPayment',
                params: this.recurringPaymentId,
                resource: 'recurringPayment'
            },
            {
                crmModule: 'recurring-payment-dates',
                crmAlias: 'scheduledPayment',
                params: this.recurringPaymentDateId,
                resource: 'recurringPaymentDate'
            },
            {
                crmModule: 'transactions',
                crmAlias: 'parentTransaction',
                params: this.parentTransactionId,
                resource: 'transaction'
            },
            {
                crmModule: 'clients',
                crmAlias: 'client',
                params: this.clientId,
                resource: 'client'
            },
            {
                crmModule: 'nacha-transactions',
                crmAlias: 'batchTransaction',
                params: this.batchTransactionId,
                resource: 'nachaTransaction'
            },
            {
                crmModule: 'transactions/create',
                crmAlias: 'RefundIt',
                params: {
                    parentTransactionId: this.id,
                    firmId: this.firmId,
                    category: 'REFUND',
                    amount: this.total,
                    currency: this.currency
                },
                resource: 'transaction',
                hidden: (model) => model.category !== 'PAYMENT'
            },
            {
                crmModule: 'firm-issues',
                params: { transactionId: this.id },
                resource: 'firmIssue'
            },
            {
                crmModule: 'card-profiles',
                params: this.cardProfileId,
                crmAlias: 'Card Profile',
                resource: 'cardProfile',
                hidden: (model) => model.cardProfileId === null
            },
            {
                crmModule: 'audit-logs',
                params: { entity_type: 'transaction', entity_id: this.id },
                resource: 'auditLog'
            }
        ]);
    }

    get detailsDisplayName(): string {
        return `Transaction: ${this.id}`;
    }

    get stripeTransaction(): string {
        return this.transaction && this.transaction['type'] === 'STRIPE' ? this.transaction['id'] : null;
    }

    get getFirmOptionsAsync(): any {
        return searchFirm;
    }

    get isOneTimeACHPayment(): boolean {
        return this.category === 'PAYMENT' && this.method === 'ACH' && !this.clientBankAccountId;
    }

    get isOneTimeACHRefund(): boolean {
        return this.category === 'REFUND' && this.method === 'ACH' && !this.clientBankAccountId;
    }

    get clientBankAccountDescription(): string {
        // ACH payment which was using saved client bank account
        if (this.clientBankAccount) {
            return `<a href="/crm/client-bank-accounts/${this.clientBankAccountId}">
                ${this.clientBankAccount.name} ${this.clientBankAccount.last4}</a>`;
        }
        // one-time ACH payment
        if (this.clientBankLast4) {
            return `Client Bank Account ` + this.clientBankLast4;
        }
    }

    get clientCardDescription(): string {
        // CARD payment which was using saved client card
        if (this.clientCard) {
            return `<a href="/crm/client-cards/${this.clientCard.id}">${this.clientCard.name}</a>`;
        }
        // one-time card payment
        if (this.clientCardType && this.clientCardLast4) {
            let description = `${this.clientCardType} ending in ${this.clientCardLast4}`;

            if (this.clientCardHolderName) {
                description += ` - ${this.clientCardHolderName}`;
            }

            if (this.clientCardExpirationDate) {
                const date = moment(this.clientCardExpirationDate, 'MMYY').format('MM/YYYY');

                description += ` - Expires ${date}`;
            }

            return description;
        }
    }

    get clientCardType(): string {
        return _.get(this, 'transaction.vgs_card.type') || _.get(this, 'bankInfo.bank_name');
    }

    get clientCardLast4(): string {
        return _.get(this, 'transaction.vgs_card.last4') || _.get(this, 'bankInfo.last4');
    }

    get clientCardHolderName(): string {
        return _.get(this, 'transaction.vgs_card.cardholder_name', '');
    }

    get clientCardExpirationDate(): string {
        return _.get(this, 'transaction.vgs_card.exp_date', '');
    }

    get clientBankLast4(): string {
        return _.get(this, 'transaction.bankInfo.last4') || _.get(this, 'bankInfo.last4');
    }

    static filterFields(): Field[] {
        return modelConfig([
            {
                // this field is hidden in filter
                type: 'input',
                name: 'firmId',
                hidden: true,
                cssClasses: 'hidden'
            },
            {
                // this field is hidden in filter
                type: 'input',
                name: 'batchId',
                hidden: true,
                cssClasses: 'hidden'
            },
            {
                // this field is hidden in filter
                type: 'input',
                name: 'invoiceId',
                hidden: true,
                cssClasses: 'hidden'
            },
            {
                // this field is hidden in filter
                type: 'input',
                name: 'cardProfileId',
                hidden: true,
                cssClasses: 'hidden'
            },
            {
                // this field is hidden in filter
                type: 'input',
                name: 'bankId',
                hidden: true,
                cssClasses: 'hidden'
            },
            {
                // this field is hidden in filter
                type: 'input',
                name: 'batchTransactionId',
                hidden: true,
                cssClasses: 'hidden'
            },
            {
                type: 'input',
                name: 'cardId',
                hidden: true,
                cssClasses: 'hidden'
            },
            {
                type: 'input',
                name: 'clientBankAccountId',
                hidden: true,
                cssClasses: 'hidden'
            },
            {
                // this field is hidden in filter
                type: 'input',
                name: 'clientId',
                hidden: true,
                cssClasses: 'hidden'
            },
            {
                // this field is hidden in filter
                type: 'input',
                name: 'recurringPaymentId',
                hidden: true,
                cssClasses: 'hidden'
            },
            {
                // this field is hidden in filter
                type: 'input',
                name: 'batchId',
                hidden: true,
                cssClasses: 'hidden'
            },
            {
                // this field is hidden in filter
                type: 'input',
                name: 'recurringPaymentDateId',
                hidden: true,
                cssClasses: 'hidden'
            },
            {
                type: 'input',
                name: 'networkTokenWasRequested',
                hidden: true,
                cssClasses: 'hidden'
            },
            {
                type: 'input',
                name: 'networkTokenWasUsed',
                hidden: true,
                cssClasses: 'hidden'
            },
            {
                type: 'select',
                name: 'partnerId',
                label: 'Filter by Partner',
                cssClasses: 'col-sm-4 min-width-200',
                options: {
                    url: '/partners/names',
                    default: {'': 'All Partners'}
                }
            },
            {
                type: 'select',
                name: 'category',
                label: 'Filter by Category',
                cssClasses: 'col-sm-4 min-width-200',
                options: {
                    url: '/transactions/categories',
                    default: {'': 'All Categories'}
                }
            },
            {
                type: 'select',
                name: 'subCategory',
                label: 'Filter by Sub Category',
                cssClasses: 'col-sm-4 min-width-200',
                options: {
                    '': 'All Sub Categories',
                    'CHARGEBACK_FEE': 'CHARGEBACK_FEE',
                    'INSUFFICIENT_FUNDS_FEE': 'INSUFFICIENT_FUNDS_FEE',
                    'NETWORK_FEE': 'NETWORK_FEE',
                    'TRANSACTION_FEE': 'TRANSACTION_FEE',
                    'OTHER': 'OTHER'
                },
                hideIf: (values) => values.category !== 'FEE'
            },
            {
                type: 'select',
                name: 'method',
                label: 'Filter by Method',
                cssClasses: 'col-sm-4 min-width-200',
                options: {
                    '': 'All Methods',
                    'CARD': 'CARD',
                    'ACH': 'ACH'
                }
            },
            {
                type: 'select',
                name: 'status',
                label: 'Filter by Status',
                cssClasses: 'col-sm-4 min-width-200',
                options: {
                    url: '/transactions/statuses'
                }
            },
            {
                type: 'datepicker',
                name: 'createdFrom',
                cssClasses: 'filter-date-input',
            },
            {
                type: 'datepicker',
                name: 'createdTo',
                cssClasses: 'filter-date-input',
            },
            {
                // this field is hidden in filter
                type: 'input',
                name: 'isDashboardFilter',
                hidden: true,
                cssClasses: 'hidden'
            },
            {
                label: 'Using Network Tokens',
                type: 'checkbox',
                name: 'networkTokenWasRequested'
            },
            {
                label: 'With Surcharge',
                type: 'checkbox',
                name: 'surchargeEnabled'
            }
        ]);
    }
}

export interface CSVFileTransaction {
    firmId: string;
    firmName?: string;
    category: string;
    subCategory: string;
    bankId?: string;
    bankFriendlyName?: string;
    amount: string;
    reason?: string;
    errorMessages?: string[];
}
