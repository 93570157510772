import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { environment as environmentLocal } from '@environment';
import { Note } from '@app/shared/models';

@Injectable()
export class NotesService {
    apiRoot = `${environmentLocal.apiUrl}/notes`;

    constructor(private http: HttpClient) {}

    findByItemId(modelName: string, id: string): Observable<Note[]> {
        return this.http.get<Note[]>(`${this.apiRoot}/${modelName}/${id}`)
            .pipe(map((notes: object[]) => notes
                .map((note: object) => new Note(note))
            ));
    }

    create(data: object): Observable<Note> {
        return this.http.post<Note>(this.apiRoot, data)
            .pipe(
                map(note => new Note(note)),
                catchError((response: HttpErrorResponse) =>
                    throwError(() => new Error(response.error.message))
                )
            );
    }
}
