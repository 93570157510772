import { Injectable, TemplateRef } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable()
export class NotificationService {

    private defaultConfig: MatSnackBarConfig = {
        duration: 2000
    };

    constructor(private snackBar: MatSnackBar) {
    }

    /**
     * Open notification
     */
    open(message: string, action?: string, data?: any, config?: MatSnackBarConfig) {
        this.snackBar.open(message, action, {data, ...this.defaultConfig, ...config});
    }

    /**
     * Open error notification
     */
    openWarn(message: string, action?: string, data?: any, config?: MatSnackBarConfig) {
        this.snackBar.open(message, action, {data, panelClass: 'error-snackbar', ...this.defaultConfig, ...config});
    }

    /**
     * Open success notification
     */
    openSuccess(message: string, action?: string, data?: any, config?: MatSnackBarConfig) {
        this.snackBar.open(message, action, {data, panelClass: 'success-snackbar', ...this.defaultConfig, ...config});
    }

    /**
     * Open notification using template
     */
    openFromTemplate(template: TemplateRef<any>, data?: any, config?: MatSnackBarConfig) {
        this.snackBar.openFromTemplate(template, {data, ...this.defaultConfig, ...config});
    }

    /**
     * Dismiss currently open notification
     */
    dismiss() {
        this.snackBar.dismiss();
    }

}
