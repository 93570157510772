import { Model, Resource, OverviewField, modelConfig, modelRelations } from '../data';

export class PartnerApiKey extends Model {
    resourceName: Resource = 'partnerApiKey';

    id: string;
    partnerId: string;
    key: string;
    active: boolean;
    createdAt: Date;
    updatedAt: Date;

    partner: {
    name: string;
  };

    constructor(attributes = {}) {
        super(attributes);
        super.update(attributes);
        this.overviewFields = [
            {
                label: 'Partner',
                name: 'partnerId',
                transformer: <PartnerApiKey>(value, model) => `<a href='/crm/partners/${model.partnerId}'>${model.partner.name}</a>`
            },
            'key',
            'active'
        ];
        this.config = modelConfig([
            {
                type: 'input',
                name: 'partnerId',
                readonly: true
            },
            {
                type: 'input',
                name: 'key'
            },
            {
                type: 'checkbox',
                name: 'active'
            },
            {
                type: 'input',
                name: 'createdAt',
                readonly: true,
                additional: true
            },
            {
                type: 'input',
                name: 'updatedAt',
                readonly: true,
                additional: true
            }
        ]);
        this.configCreate = modelConfig([
            {
                type: 'input',
                name: 'partnerId'
            },
            {
                type: 'input',
                name: 'key'
            }
        ]);
        this.relations = modelRelations([
            {
                crmModule: 'partners',
                crmAlias: 'partner',
                params: this.partnerId,
                resource: 'partner'
            }
        ]);
    }

    get detailsDisplayName(): string {
        return `Partner API Key`;
    }
}
