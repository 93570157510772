import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

import { AuthenticationService } from '../../authentication';

export interface PermissionDefinition {
  resource: string;
  operation?: 'readAny' | 'updateAny';
  attributes?: string[];
}

@Directive({
    selector: '[awIfPermissions]'
})
export class IfPermissionsDirective {

    constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authenticationService: AuthenticationService
    ) {}

  @Input() set awIfPermissions(def: PermissionDefinition) {
        const permissions: PermissionDefinition = {
            operation: 'readAny',
            ...def
        };

        if (this.authenticationService.can(permissions.operation, permissions.resource, permissions.attributes)) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }
    }
}
