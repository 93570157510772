import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { getFileName } from '@app/shared';

@Component({
    selector: 'aw-file-preview',
    templateUrl: './file-preview.component.html'
})
export class FilePreviewComponent {

    @Input() src: string;

    constructor(
        private sanitizer: DomSanitizer
    ) {}

    get fileName(): string {
        return getFileName(this.src);
    }

    get fileType(): string {
        return this.fileName.toLowerCase().split('.').pop();
    }

    get isImage(): boolean {
        return ['png', 'jpg', 'jpeg', 'gif'].includes(this.fileType);
    }

    get isPdf(): boolean {
        return this.fileType === 'pdf';
    }

    get safePreviewURL(): SafeResourceUrl {
        return this.sanitizer.bypassSecurityTrustResourceUrl(this.src);
    }
}
