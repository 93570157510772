import { Injectable, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { ComponentType } from '@angular/cdk/portal';
import { filter } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class DialogService {

    private dialogRef: MatDialogRef<any>;
    private defaultConfig: MatDialogConfig = {
        width: '800px',
        disableClose: true,
        scrollStrategy: new NoopScrollStrategy()
    };

    constructor(private dialog: MatDialog) {}

    /**
   * Open dialog window
   */
    open<T>(component: ComponentType<T>, data?: any, config?: MatDialogConfig): Observable<any> {
        this.dialogRef = this.dialog.open(
            component, {
                data,
                ...this.defaultConfig,
                ...config
            });

        return this.dialogRef.afterClosed()
            .pipe(filter(result => !!result));
    }

    /**
   * Open dialog window with given template ref
   */
    openDialogWithRef(templateRef: TemplateRef<any>) {
        this.dialogRef = this.dialog.open(templateRef);

        return this.dialogRef.afterClosed()
            .pipe(filter(result => !!result));
    }

    /**
   * Dismiss modal
   */
    dismiss() {
        this.dialogRef.close();
    }

    /**
   * Close modal with data
   */
    close<T>(data?: T) {
        this.dialogRef.close(data || {});
    }

    closeByRef<T>(dialogRef?: MatDialogRef<T>) {
        dialogRef.close();
    }

    getDialogRef() {
        return this.dialogRef;
    }
}
