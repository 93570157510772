import { Model, Resource, modelConfig, Field } from '../data';

/*
    'attorney' => 'profile',
    'bank',
    'firm',
    'client',
    'client_card',
    'client_bank_account',
    'transaction',
    'recurring_payment',
    'scheduled_payment' => 'recurring-payment-date',
    'recurring_payment_transaction' => 'transaction'
*/
const getCrmRouteNameByEntityType = (entity_type: string) => {
    const crmModuleName = entity_type
        .replace('attorney', 'profile')
        .replace('scheduled_payment', 'recurring-payment-date')
        .replace(/_/g, '-');
    return `${crmModuleName}s`;
};

export class AuditLog extends Model {
    resourceName: Resource = 'auditLog';

    id: string;
    partner_id: string;
    partner_name: string;
    entity_id: string;
    entity_type: string;
    user_type: string;
    admin_user_id: string;
    admin_user_email: string;
    action_type: string;
    action_details: object;
    datetime: Date;

    constructor(attributes = {}) {
        super(attributes);
        super.update(attributes);

        this.overviewFields = [
            {
                label: 'Partner',
                name: 'partner_id',
                transformer: <AuditLog>(value, model) => `<a href='/crm/partners/${value}'>${model.partner_name}</a>`
            },
            {
                label: 'Entity',
                name: 'entity_type',
                transformer:
                    <AuditLog>(value, model) => `<a href='/crm/${getCrmRouteNameByEntityType(value)}/${model.entity_id}'>${value}</a>`
            },
            {
                label: 'Action made by',
                name: 'user_type'
            },
            {
                label: 'Admin user',
                name: 'admin_user_email'
            },
            {
                label: 'Action type',
                name: 'action_type'
            },
            {
                hideLabel: true,
                name: 'action_details',
                transformer: <AuditLog>(value, model) => `<div>
                        <div class="semibold">Action details</div>
                        <pre>${JSON.stringify(value, null, 4)}</pre>
                    </div>`,
                cssClasses: 'overflow-auto'
            },
            {
                label: 'Datetime',
                name: 'datetime',
                transformer: 'formatDate'
            },
        ];
        this.config = modelConfig([
            {
                type: 'input',
                name: 'partner_id',
                readonly: true
            },
            {
                type: 'input',
                name: 'entity_id',
                readonly: true
            },
            {
                type: 'input',
                name: 'entity_type',
                readonly: true
            },
            {
                type: 'input',
                name: 'user_type',
                readonly: true
            },
            {
                type: 'input',
                name: 'admin_user_id',
                readonly: true
            },
            {
                type: 'input',
                name: 'action_type',
                readonly: true
            },
            {
                type: 'json',
                name: 'action_details',
                readonly: true
            },
            {
                type: 'input',
                name: 'datetime',
                readonly: true
            },
        ]);
    }

    get detailsDisplayName() {
        return `Audit Log Record`;
    }

    static filterFields(): Field[] {
        return modelConfig([
            {
                // this field is hidden in filter
                type: 'input',
                name: 'entity_id',
                hidden: true,
                cssClasses: 'hidden'
            },
            {
                type: 'select',
                name: 'partner_id',
                label: 'Filter by partner',
                cssClasses: 'col-sm-4 min-width-200',
                options: {
                    url: '/partners/names',
                    default: {'': 'All Partners'}
                },
                hideIf: (values) => values.entity_id // hide if entity_id is selected
            },
            {
                type: 'select',
                name: 'entity_type',
                label: 'Filter by entity type',
                cssClasses: 'col-sm-4 min-width-200',
                options: {
                    '': 'All entities',
                    'firm': 'Firm',
                    'firm_fee': 'Firm Fee',
                    'firm_settings': 'Firm Settings',
                    'firm_document': 'Firm Document',
                    'attorney': 'Attorney',
                    'bank': 'Bank',
                    'client': 'Client',
                    'client_card': 'Client card',
                    'card_profile': 'Card Profile',
                    'client_bank_account': 'Client bank account',
                    'transaction': 'Transaction',
                    'recurring_payment': 'Recurring payment',
                    'scheduled_payment': 'Scheduled payment',
                    'promo': 'Promo',
                    'firm_promo': 'Firm Promo',
                    'marketplace_order': 'Marketplace Order',
                    'fraud_alerts': 'Fraud Alerts',
                    'currency_transfer': 'Currency Transfer',
                    'rapidconnect_request': 'Rapidconnect Request',
                    'nacha_transaction': 'Batch Nacha Transaction',
                    'nacha_file': 'Nacha File Transactions'
                },
                hideIf: (values) => values.entity_id // hide if entity_id is selected
            },
            {
                type: 'select',
                name: 'search_mode',
                label: 'Search Mode',
                cssClasses: 'col-sm-4 min-width-300 margin-left-30',
                hideIf: (values) => !values.entity_id,
                options: {
                    '': 'Selected Entity Events',
                    'fulltext': 'All Related Events'
                }
            },
            {
                type: 'select',
                name: 'user_type',
                label: 'Filter by user type',
                cssClasses: 'col-sm-4 min-width-200',
                options: {
                    '': 'All user types',
                    'partner': 'Partner',
                    'admin': 'Admin',
                    'system': 'System',
                }
            },
            {
                type: 'select',
                name: 'admin_user_id',
                label: 'Filter by admin',
                cssClasses: 'col-sm-4 min-width-200',
                required: false,
                options: {
                    url: '/users/names',
                    default: {'': 'All Admins'}
                }
            },
            {
                type: 'input',
                name: 'action_type',
                cssClasses: 'col-sm-4 min-width-200',
            },
            {
                type: 'datepicker',
                name: 'date_from',
                cssClasses: 'filter-date-input',
            },
            {
                type: 'datepicker',
                name: 'date_to',
                cssClasses: 'filter-date-input',
            }
        ]);
    }
}
