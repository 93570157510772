import { searchFirm } from '../constants';
import { Model, Resource, modelConfig, modelRelations } from '../data';

export class FirmFee extends Model {
    resourceName: Resource = 'firmFee';

    id: string;
    firmId: string ;
    achPercent: number;
    creditPercent: number;
    amexPercent: number;
    cardFlatRate: number;
    achFlatRate: number;
    createdAt: Date;
    updatedAt: Date;

    firm: {
        partnerId: string;
        email: string,
        name: string,
        partnerName: string
    };

    constructor(attributes = {}) {
        super(attributes);
        super.update(attributes);

        this.overviewFields = [
            {
                label: 'Partner',
                name: 'firmId',
                transformer: <FirmFee>(value, model) => `<a href='/crm/partners/${model.firm.partnerId}'>${model.firm.partnerName}</a>`
            },
            'firmName',
            'firmEmail',
            {
                label: 'Credit Cards fee',
                name: 'creditPercent',
                transformer: (value) => value ? value + '%' : ''
            },
            {
                name: 'cardFlatRate'
            },
            {
                label: 'ACH fee',
                name: 'achPercent',
                transformer: (value) => value ? value + '%' : ''
            },
            {
                name: 'achFlatRate'
            },
            {
                label: 'Special fee for AMEX cards (optional)',
                name: 'amexPercent',
                transformer: (value) => value ? value + '%' : ''
            }
        ];
        this.config = modelConfig([
            {
                type: 'input',
                name: 'id',
                readonly: true
            },
            {
                type: 'input',
                name: 'firmId',
                readonly: true
            },
            {
                type: 'select',
                label: 'Credit Card Fees',
                name: 'creditCardFees',
                options: {
                    DEFAULT: 'DEFAULT',
                    PERCENTAGE: 'PERCENTAGE',
                    FLAT_RATE: 'FLAT_RATE'
                }
            },
            {
                label: 'Credit Card payments fee percent (e.g. 2.8%)',
                type: 'input',
                subType: 'number',
                name: 'creditPercent',
                suffix: '%',
                cssClasses: 'max-width-200 margin-left-20'
            },
            {
                label: 'Special fee for AMEX cards (optional e.g. 0.1%)',
                type: 'input',
                subType: 'number',
                name: 'amexPercent',
                suffix: '%',
                cssClasses: 'max-width-200 margin-left-20',
            },
            {
                label: 'Card flat rate (e.g. $1.01)',
                type: 'input',
                subType: 'number',
                name: 'cardFlatRate',
                pattern: '^[0-9]+\.[0-9]{2}$',
                prefix: '$',
                cssClasses: 'max-width-200 margin-left-20'
            },
            {
                type: 'select',
                label: 'ACH Fees',
                name: 'achFees',
                options: {
                    DEFAULT: 'DEFAULT',
                    PERCENTAGE: 'PERCENTAGE',
                    FLAT_RATE: 'FLAT_RATE'
                }
            },
            {
                label: 'ACH payments fee percent (e.g. 1.0%)',
                type: 'input',
                subType: 'number',
                name: 'achPercent',
                suffix: '%',
                cssClasses: 'max-width-200 margin-left-20'
            },
            {
                label: 'ACH flat rate (e.g. $1.10)',
                type: 'input',
                subType: 'number',
                name: 'achFlatRate',
                pattern: '^[0-9]+\.[0-9]{2}$',
                prefix: '$',
                cssClasses: 'max-width-200 margin-left-20'
            },
            {
                type: 'input',
                name: 'createdAt',
                readonly: true,
            },
            {
                type: 'input',
                name: 'updatedAt',
                readonly: true,
            }
        ]);
        this.relations = modelRelations([
            {
                crmModule: 'firms',
                crmAlias: 'firm',
                params: this.firmId,
                resource: 'firm'
            },
            {
                crmModule: 'firm-settings',
                params: { firmId: this.firmId },
                resource: 'firmSettings'
            },
            {
                crmModule: 'audit-logs',
                params: { entity_type: 'firm_fee', entity_id: this.id },
                resource: 'auditLog'
            }
        ]);
        this.configCreate = modelConfig([
            {
                type: 'autocomplete',
                name: 'firmId',
                label: 'Firm',
                getOptions: this.getFirmOptionsAsync
            },
            {
                type: 'select',
                label: 'Credit Card Fees',
                name: 'creditCardFees',
                options: {
                    DEFAULT: 'DEFAULT',
                    PERCENTAGE: 'PERCENTAGE',
                    FLAT_RATE: 'FLAT_RATE'
                }
            },
            {
                label: 'Credit Card payments fee percent (e.g. 2.8%)',
                type: 'input',
                subType: 'number',
                name: 'creditPercent',
                suffix: '%',
                cssClasses: 'max-width-200 margin-left-20',
            },
            {
                label: 'Special fee for AMEX cards (e.g. 0.1%)',
                type: 'input',
                subType: 'number',
                name: 'amexPercent',
                suffix: '%',
                cssClasses: 'max-width-200 margin-left-20',
                hint: 'optional, only if AMEX cards should be charged a different fee'
            },
            {
                label: 'Card flat rate (e.g. $1.01)',
                type: 'input',
                subType: 'number',
                name: 'cardFlatRate',
                pattern: '^[0-9]+\.[0-9]{2}$',
                prefix: '$',
                cssClasses: 'max-width-200 margin-left-20',
            },
            {
                type: 'select',
                label: 'ACH Fees',
                name: 'achFees',
                options: {
                    DEFAULT: 'DEFAULT',
                    PERCENTAGE: 'PERCENTAGE',
                    FLAT_RATE: 'FLAT_RATE'
                }
            },
            {
                label: 'ACH payments fee percent (e.g. 1.0%)',
                type: 'input',
                subType: 'number',
                name: 'achPercent',
                suffix: '%',
                cssClasses: 'max-width-200 margin-left-20',
            },
            {
                label: 'ACH flat rate (e.g. $1.10)',
                type: 'input',
                subType: 'number',
                name: 'achFlatRate',
                pattern: '^[0-9]+\.[0-9]{2}$',
                prefix: '$',
                cssClasses: 'max-width-200 margin-left-20',
            }
        ]);
    }

    get firmName(): string {
        return this.firm && this.firm.name;
    }

    get firmEmail(): string {
        return this.firm && this.firm.email;
    }

    get detailsDisplayName(): string {
        return `Fees for firm: ${this.firmName}`;
    }

    get getFirmOptionsAsync(): any {
        return searchFirm;
    }
}
