import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

import { IfPermissionsModule } from '../if-permissions';
import { SearchFieldModule } from '../search-field';
import { NavbarComponent } from './navbar.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        MatToolbarModule,
        MatIconModule,
        MatButtonModule,
        IfPermissionsModule,
        SearchFieldModule
    ],
    declarations: [NavbarComponent],
    exports: [NavbarComponent],
})
export class NavbarModule { }
