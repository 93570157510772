import { Model, Resource, modelConfig, Field, modelRelations } from '../data';
import { convertFromCents, formatUtcDate } from '../utils';

export class FirstdataChargeback extends Model {
    resourceName: Resource = 'firstdataChargeback';

    id: string;
    chargeback_unique_id: string;
    authorization_code: string;
    transaction_chargeback_type: string;
    merchant_id: string;
    dba_name: string;
    external_id: string;
    file_date: Date;
    status_date: Date;
    status_description: string;
    request_date: Date;
    chargeback_currency_code: string;
    reason_code_description: string;
    ids_case_number: string;
    dispute_amount_in_cents: number;
    due_date: Date;
    acquirer_reference_number: string;
    card_type: string;
    card_number: string;
    card_expiration_date: string;
    adjustment_date: Date;
    transaction_date: Date;
    batch_date: Date;
    batch_number: string;
    processed_transaction_amount_in_cents: string;
    receive_date: Date;
    sub_merchant_id: string;
    current_status: string;
    responded_on_date: Date;
    headnote_transaction_id: string;
    currency_transfer_id: string;
    created_at: Date;
    trace_id: string;

    constructor(attributes = {}) {
        super(attributes);
        super.update(attributes);


        this.overviewFields = [
            'id',
            {
                name: 'headnote_transaction_id',
                stayVisible: true,
                transformer: (headnote_transaction_id) => {
                    if (!headnote_transaction_id) return '-';
                    return `<a href="/crm/transactions/${headnote_transaction_id}">${headnote_transaction_id}</a>`;
                }
            },
            {
                name: 'currency_transfer_id',
                stayVisible: true,
                transformer: (currency_transfer_id) => {
                    if (!currency_transfer_id) return '-';
                    return `<a href="/crm/currency-transfers/${currency_transfer_id}">${currency_transfer_id}</a>`;
                }
            },
            'dba_name',
            'merchant_id',
            {
                label: 'Trace ID (Currency Transfer Hash)',
                name: 'trace_id',
                transformer: (trace_id) => {
                    return `<a href="/crm/currency-transfers;currency_transfer_hash=${trace_id};pageSize=25">${trace_id}</a>`;
                }
            },
            {
                name: 'transaction_chargeback_type',
                cssClasses: 'bold'
            },
            {
                name: 'authorization_code',
                cssClasses: 'bold'
            },
            {
                name: 'created_at',
                transformer: (value) => formatUtcDate(value, 'YYYY-MM-DD')
            },
            'transaction_date',
            {
                name: 'dispute_amount_in_cents',
                label: 'Dispute Amount',
                transformer: <FirstdataChargeback>(value, context) => convertFromCents(value, context.chargeback_currency_code),
                cssClasses: 'bold'
            },
            'chargeback_currency_code',
            {
                name: 'processed_transaction_amount_in_cents',
                label: 'Processed Transaction Amount',
                transformer: <FirstdataChargeback>(value, context) => convertFromCents(value, context.chargeback_currency_code )
            },
            {
                name: 'current_status',
                cssClasses: 'bold'
            },
            'external_id',
            'status_description',
            {
                name: 'request_date',
                transformer: (value) => formatUtcDate(value, 'YYYY-MM-DD')
            },
            {
                name: 'status_date',
                transformer: (value) => formatUtcDate(value, 'YYYY-MM-DD')
            },
            {
                name: 'adjustment_date',
                transformer: (value) => formatUtcDate(value, 'YYYY-MM-DD')
            },
            {
                name: 'due_date',
                transformer: (value) => formatUtcDate(value, 'YYYY-MM-DD')
            },
            {
                name: 'receive_date',
                transformer: (value) => formatUtcDate(value, 'YYYY-MM-DD')
            },
            {
                name: 'responded_on_date',
                transformer: (value) => formatUtcDate(value, 'YYYY-MM-DD')
            },
            {
                name: 'file_date',
                transformer: (value) => formatUtcDate(value, 'YYYY-MM-DD')
            },
            'card_type',
            'card_number',
            'card_expiration_date',
            'batch_number',
            {
                name: 'batch_date',
                transformer: (value) => formatUtcDate(value, 'YYYY-MM-DD')
            },
            'sub_merchant_id',
            'acquirer_reference_number',
            'reason_code_description',
            'ids_case_number',
            {
                name: 'file_date',
                transformer: (value) => formatUtcDate(value, 'YYYY-MM-DD')
            },
            {
                name: 'chargeback_unique_id',
                transformer: (value, model) => '<code>' + value + '</code>'
            },
        ];
        this.config = modelConfig([
            {
                type: 'input',
                name: 'id',
                readonly: true
            },
            {
                type: 'input',
                name: 'chargeback_unique_id',
                readonly: true
            },
            {
                type: 'input',
                name: 'authorization_code',
                readonly: true
            },
            {
                type: 'input',
                name: 'transaction_chargeback_type',
                readonly: true
            },
            {
                type: 'input',
                name: 'merchant_id',
                readonly: true
            },
            {
                type: 'input',
                name: 'dba_name',
                readonly: true
            },
            {
                label: 'Trace ID (Currency Transfer Hash)',
                type: 'input',
                name: 'trace_id',
                readonly: true
            },
            {
                type: 'input',
                name: 'external_id',
                readonly: true
            },
            {
                type: 'input',
                name: 'file_date',
                readonly: true
            },
            {
                type: 'input',
                name: 'status_date',
                readonly: true
            },
            {
                type: 'input',
                name: 'status_description',
                readonly: true
            },
            {
                type: 'input',
                name: 'request_date',
                readonly: true
            },
            {
                type: 'input',
                name: 'chargeback_currency_code',
                readonly: true
            },
            {
                type: 'input',
                name: 'reason_code_description',
                readonly: true
            },
            {
                type: 'input',
                name: 'ids_case_number',
                readonly: true
            },
            {
                type: 'input',
                name: 'dispute_amount_in_cents',
                readonly: true
            },
            {
                type: 'input',
                name: 'due_date',
                readonly: true
            },
            {
                type: 'input',
                name: 'acquirer_reference_number',
                readonly: true
            },
            {
                type: 'input',
                name: 'card_type',
                readonly: true
            },
            {
                type: 'input',
                name: 'card_number',
                readonly: true
            },
            {
                type: 'input',
                name: 'card_expiration_date',
                readonly: true
            },
            {
                type: 'input',
                name: 'adjustment_date',
                readonly: true
            },
            {
                type: 'input',
                name: 'transaction_date',
                readonly: true
            },
            {
                type: 'input',
                name: 'batch_date',
                readonly: true
            },
            {
                type: 'input',
                name: 'batch_number',
                readonly: true
            },
            {
                type: 'input',
                name: 'processed_transaction_amount_in_cents',
                readonly: true
            },
            {
                type: 'input',
                name: 'receive_date',
                readonly: true
            },
            {
                type: 'input',
                name: 'sub_merchant_id',
                readonly: true
            },
            {
                type: 'input',
                name: 'current_status',
                readonly: true
            },
            {
                type: 'input',
                name: 'responded_on_date',
                readonly: true
            },
            {
                type: 'input',
                name: 'created_at',
                readonly: true
            },
        ]);

        this.relations = modelRelations([
            {
                crmModule: 'firstdata-transactions',
                crmAlias: 'Find Similar Firstdata Transactions',
                params: {
                    merchant_id: this.merchant_id,
                    last4: this.card_number ? this.card_number.slice(-4) : ''
                },
                resource: 'firstdataTransaction'
            },
        ]);
    }

    get detailsDisplayName(): string {
        return `Firstdata Chargebacks`;
    }

    static filterFields(): Field[] {
        return modelConfig([
            {
                type: 'select',
                label: 'Source',
                name: 'source',
                cssClasses: 'col-sm-4 min-width-200',
                defaultValue: 'HEADNOTE_PAYMENTS',
                options: {
                    'HEADNOTE_PAYMENTS': 'HEADNOTE_PAYMENTS',
                    'HEADNOTE_COM': 'HEADNOTE_COM'
                }
            },
            {
                type: 'select',
                label: 'Filter by status',
                name: 'status_description',
                cssClasses: 'col-sm-4 min-width-200',
                options: {
                    '': 'All statuses',
                    'OPEN': 'OPEN',
                    'CLOSED': 'CLOSED',
                    'REVERSED': 'REVERSED'
                }
            },
            {
                type: 'input',
                name: 'authorization_code',
                label: 'Authorization Code',
                cssClasses: 'col-sm-4 min-width-200',
                pattern: '^[0-9A-Z]{6}$',
                minlength: 6,
                maxlength: 6
            },
            {
                type: 'select',
                label: 'Filter by chargeback type',
                name: 'transaction_chargeback_type',
                cssClasses: 'col-sm-4 min-width-200',
                options: {
                    '': 'All types',
                    'CHARGEBACK': 'CHARGEBACK',
                    'REFUND': 'REFUND',
                }
            },
            {
                type: 'input',
                name: 'dispute_amount_in_cents',
                label: 'Amount in cents',
                minlength: 3,
                maxlength: 10,
                cssClasses: 'col-sm-4 min-width-200',
            },
            {
                type: 'datepicker',
                name: 'created_at_from',
                label: 'Created from',
                cssClasses: 'filter-date-input',
            },
            {
                type: 'datepicker',
                name: 'created_at_to',
                label: 'Created to',
                cssClasses: 'filter-date-input',
            },
            {
                type: 'checkbox',
                name: 'unmatched_only'
            }
        ]);
    }
}

