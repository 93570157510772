import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs';

import { Field, FieldConfig, FieldJsonGroup } from '../../../../data';
import { Debounce } from '../../../../utils';

@Component({
    selector: 'aw-form-json',
    template: `
    <ng-container *ngFor="let field of fields"
      awDynamicField
      [field]="field"
      [group]="internalGroup">
    </ng-container>
  `
})
export class FormJsonGroupComponent implements OnInit, OnDestroy {
    field: Field;
    group: FormGroup;

    control: AbstractControl;
    fields: FieldConfig[];
    internalGroup: FormGroup;

    private value$: Subscription;

    constructor(private fb: FormBuilder) { }

    ngOnInit() {
        this.control = this.group.controls[this.field.name];
        this.fields = (this.field as FieldJsonGroup).group;
        this.internalGroup = this.createGroup();

        this.value$ = this.internalGroup.valueChanges.subscribe(
            value => this.patchForm(value)
        );
    }

    ngOnDestroy() {
        this.value$.unsubscribe();
    }

    private createGroup(): FormGroup {
        const json = this.control.value || this.field.defaultValue || {};
        const disabled = this.control.disabled;

        const group = this.fb.group({});

        this.fields.forEach((field: Field) => {
            const value = json[field.name];

            group.addControl(field.name, this.fb.control({ value, disabled: field.readonly }));
        });

        return group;
    }

  @Debounce()
    private patchForm(value: object) {
        this.control.patchValue(value);
        this.control.markAsDirty();
    }

}
