import axios from 'axios';
import { environment as environmentLocal } from '@environment';

export const DEFAULT_CURRENCY = 'USD';
export const FEE_CURRENCY = 'USD';

export const SUPERADMIN_ROLE = 'superadmin';
export const ADMIN_ROLE = 'admin';
export const READONLY_ROLE = 'readonly';

export const EVENT_BUS_EVENTS = {
    RELOAD_NOTES: 'RELOAD_NOTES',
    FORM_AUTOCOMPLETE_SELECTED_EVENT: 'FORM_AUTOCOMPLETE_SELECTED_EVENT',
    SIDENAV_COUNTER_UPDATE: 'SIDENAV_COUNTER_UPDATE',
    FORM_SELECT_OPTIONS_CHANGED_EVENT: 'FORM_SELECT_OPTIONS_CHANGED_EVENT'
};

export const ALLOWED_IMAGE_EXTENSIONS = '.jpg, .jpeg, .png, .gif, .pdf';
export const ALLOWED_DOCUMENT_EXTENSIONS = `${ALLOWED_IMAGE_EXTENSIONS}, .doc, .docx, .csv, .xls`;

export const FRAUD_ALERT_CENT_VALUES = [
    'volume',
    'amount'
];

export const FRAUD_ALERT_PERCENT_VALUES = [
    'percents'
];

export const FIRM_STATUSES = {
    UNVERIFIED: 'UNVERIFIED',
    UNDER_REVIEW: 'UNDER_REVIEW',
    VERIFIED: 'VERIFIED',
    DISABLED: 'DISABLED',
    DECLINED: 'DECLINED',
    PAUSED: 'PAUSED',
};

export const CURRENCY_TRANSFER_STATUS = {
    PENDING: 'PENDING',
    TRANSFERRED: 'TRANSFERRED',
    REVERSED: 'REVERSED',
    FAILED: 'FAILED',
    CANCELED: 'CANCELED',
    SENDING_NACHA: 'SENDING_NACHA'
};

export const RAPIDCONNECT_REQUEST_STATUS = {
    IN_PROCESS: 'IN_PROCESS',
    SUCCESS: 'SUCCESS',
    FAILED: 'FAILED'
};

export const MARKETPLACE_ORDER_STATUSES = {
    PENDING: 'PENDING',
    COMPLETED: 'COMPLETED',
    CANCELED: 'CANCELED'
};

export const NACHA_FILE = {
    STATUS: {
        PENDING: 'PENDING',
        SUCCESSFUL: 'SUCCESSFUL',
        FAILED: 'FAILED'
    },
    CATEGORY: {
        PAYMENT: 'PAYMENT',
        PAYOUT: 'PAYOUT',
        FEE: 'FEE',
        BATCH_FEES: 'BATCH_FEES',
        BATCH_PAYOUTS: 'BATCH_PAYOUTS',
        REFUND_DEDUCTION: 'REFUND_DEDUCTION',
        REFUND_DISBURSE: 'REFUND_DISBURSE',
        CREDIT: 'CREDIT',
        CHARGEBACK: 'CHARGEBACK',
        FEE_TRANSFER: 'FEE_TRANSFER'
    }
};

export const TRANSACTION = {
    STATUS: {
        SUCCESSFUL: 'SUCCESSFUL',
        FAILED: 'FAILED',
        PENDING: 'PENDING',
        PENDING_NACHA: 'PENDING_NACHA',
        CANCELED: 'CANCELED',
    }
};

export const MODEL_RESOURCE_NAME_TO_ROUTES = {
    'auditLog': 'audit-logs',
    'bank': 'banks',
    'client': 'clients',
    'clientBankAccount': 'client-bank-accounts',
    'clientCard': 'client-cards',
    'cardProfile': 'card-profiles',
    'currencyTransfer': 'currency-transfers',
    'firm': 'firms',
    'firmFee': 'firm-fees',
    'firmIssue': 'firm-issues',
    'firmPromo': 'firm-promos',
    'firmSettings': 'firm-settings',
    'firstdataChargeback': 'firstdata-chargebacks',
    'firstdataTransaction': 'firstdata-transactions',
    'fraudAlerts': 'fraud-alerts',
    'invoice': 'invoices',
    'nachaFile': 'nacha-files',
    'nachaTransaction': 'nacha-transactions',
    'partner': 'partners',
    'partnerApiKey': 'partner-api-keys',
    'partnerWebhook': 'partner-webhooks',
    'profile': 'profiles',
    'promo': 'promos',
    'rapidconnectRequest': 'rapidconnect-requests',
    'recurringPayment': 'recurring-payments',
    'recurringPaymentDate': 'recurring-payment-dates',
    'transaction': 'transactions',
    'headnoteBanks': 'headnote-banks',
};

export const NETWORK_FEES_CSV_TEMPLATE_FILE_NAME = 'Transaction_CSV_template.csv';

export const NETWORK_FEES_CSV_TEMPLATE = `data:@file/csv;base64,ZmlybV9pZCxjYXRlZ29yeSxzdWJfY2F0ZWdvcnksYW1vdW50X2luX2RvbGxhcnMscmVhc29uCmNk
NDYxZDFhLTFhMGMtNDQ5MC1iYjVlLTFiOWZlYzEzNGZlMyxGRUUsTkVUV09SS19GRUUsMTIuMzUs
VGVzdAo4MjVjNTA5NC1iZWRkLTQ0MTUtOTA2Yi03NGE1MWY5YzUwMDMsRkVFLE5FVFdPUktfRkVF
LDQ1LjY4LFRlc3Q=`;

export const DEFAULT_MCC = '8111';
export const DEFAULT_ORGANIZATION_TYPE = 'CORPORATION';

export const searchFirm = async (
    token: string,
    query: string = '',
    setCancelationHandler?: Function
): Promise<string[]> => {
    const CancelToken = axios.CancelToken;
    let data = [];

    try {
        const result = await axios.get(`${environmentLocal.apiUrl}/firms/search?q=${query}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            },
            cancelToken: new CancelToken((c) => setCancelationHandler && setCancelationHandler(c))
        });
        data = result.data;
    } catch (error) {
        // Do nothing here axios.isCancel(error))
        throw error;
    }
    return data;
};

export const getFirmSettingsDefaultFraudRules = async (token: string): Promise<string[]> => {
    const { data } = await axios.get(`${environmentLocal.apiUrl}/firm-settings/rules`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    return data;
};

interface FieldDecryptResponse {
    [key: string]: string;
}

export const getFirmFieldDecrypted = async (
    token: string,
    firmId: string,
    field: string
): Promise<FieldDecryptResponse> => {
    const { data } = await axios.get(`${environmentLocal.apiUrl}/firms/${firmId}/decrypt/${field}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    return data;
};

export const getProfileFieldDecrypted = async (
    token: string,
    profileId: string,
    field: string
): Promise<FieldDecryptResponse> => {
    const { data } = await axios.get(`${environmentLocal.apiUrl}/profiles/${profileId}/decrypt/${field}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    return data;
};
