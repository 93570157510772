import { Model, Resource, modelConfig } from '../data';

export interface NewUserParams {
    email: string;
    firstName: string;
    lastName: string;
    temporaryPassword: string;
    roleId: string;
    mfaEnabled: boolean;
}

export class User extends Model {
    resourceName: Resource = 'user';

    id: string;
    email: string;
    firstName: string;
    lastName: string;
    mfaEnabled: boolean;
    phone: string;
    role: {
        id: string;
        name: string
    };
    roleId: string;
    createdAt: Date;
    updatedAt: Date;

    constructor(attributes = {}) {
        super(attributes);
        super.update(attributes);

        this.configCreate = modelConfig([
            {
                type: 'input',
                name: 'firstName',
                label: 'First Name',
                pattern: '^.{2,100}$',
                required: true
            },
            {
                type: 'input',
                name: 'lastName',
                label: 'Last Name',
                pattern: '^.{2,100}$',
                required: true
            },
            {
                type: 'input',
                name: 'email',
                label: 'Email',
                pattern: '[a-zA-Z0-9.-_+]{1,}@[a-zA-Z0-9.-]{2,}[.]{1}[a-zA-Z]{2,}',
                required: true
            },
            {
                type: 'input',
                name: 'phone',
                label: 'Phone',
                pattern: '^[+][0-9]{11,13}$',
                required: true
            },
            {
                type: 'input',
                name: 'temporaryPassword',
                label: 'Temporary Password',
                pattern: '^.{8,100}$',
                required: true
            },
            {
                type: 'select',
                name: 'roleName',
                options: [],
                label: 'User Role',
                required: true
            },
            {
                type: 'checkbox',
                name: 'mfaEnabled',
                label: 'MFA Enabled',
                required: true
            }
        ]);
    }
}
