export const LocalStorageService = {
    setItem (key, value): void {
        if (!key || !value) {
            return;
        }

        if (typeof value === 'object') {
            value = JSON.stringify(value);
        }

        localStorage.setItem(key, value);
    },

    getItem (key): any {
        let value = localStorage.getItem(key);

        if (!value) {
            return;
        }

        // assume it is an object that has been stringified
        if (value[0] === '{') {
            value = JSON.parse(value);
        }

        return value;
    }
};
