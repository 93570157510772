export const environment = {
    title: 'Headnote Payments - Development',
    environmentName: 'development',
    production: false,
    apiUrl: '/api',
    mainWebsiteUrl: 'https://dev-admin.headnotepaymentssandbox.com',
    partnerUploadsUrl: 'https://partner-uploads.headnotepaymentssandbox.com/',
    staticResourcesURL: 'https://dev-static.headnotepaymentssandbox.com',
    AWS: {
        region: 'us-east-1',
        UserPoolId: 'us-east-1_wjSX9A4hf',
        ClientId: '1ps1153221frdl2fl8hoeau00t'
    },
};
