import { Model, Resource, modelConfig, modelRelations, Field } from '../data';
import { formatAmount } from '../utils';
import { getCurrentTimezone} from '@app/shared';

export class NachaTransaction extends Model {
    resourceName: Resource = 'nachaTransaction';

    id: string;
    firmId: string;
    bankId: string;
    nachaTransactionUniqueId: string;
    nachaCategory: string;
    errorMessage: string;
    totalInUsdCents: number;
    createdAt: Date;
    updatedAt: Date;
    cntTransactions: string;

    constructor(attributes = {}) {
        super(attributes);
        super.update(attributes);
        this.overviewFields = [
            'nachaTransactionUniqueId',
            {
                label: 'Firm',
                name: 'firmId',
                transformer: <NachaTransaction>(value, model) => `<a href="/crm/firms/${model.firm.id}">${model.firm.name}</a>`
            },
            'bankingPartnerId',
            {
                label: 'Bank',
                name: 'bankId',
                transformer: <NachaTransaction>(value, model) => {
                    const bank = model.bank;
                    const archived = bank.archived ? ' (archived)' : '';
                    return `<a href="/crm/banks/${bank.id}">${bank.bankAccountType} - ${bank.friendlyName} ${bank.last4}${archived}</a>`;
                }
            },
            'nachaCategory',
            'cntTransactions',
            {
                label: 'Total',
                name: 'totalInUsdCents',
                transformer: (value) => formatAmount(value / 100, 'USD')
            },
            'status',
            'errorMessage',
            'createdAt',
            {
                name: 'updatedAt',
                transformer: 'formatDate'
            }
        ];
        this.config = modelConfig([
            {
                type: 'select',
                name: 'bankId',
                options: {
                    url: `/banks/names?firmId=${this.firmId}&allowArchived=true&userTimezone=` + getCurrentTimezone()
                }
            },
            {
                type: 'select',
                name: 'status',
                options: {
                    url: '/nacha-transactions/statuses'
                }
            },
            {
                label: 'Error Message',
                type: 'input',
                name: 'errorMessage'
            },
            {
                label: 'Metadata',
                name: 'metadata',
                type: 'json',
                readonly: true,
                additional: true,
            }
        ]);
        this.relations = modelRelations([
            {
                crmModule: 'firms',
                crmAlias: 'firm',
                params: this.firmId,
                resource: 'firm'
            },
            {
                crmModule: 'banks',
                crmAlias: 'bank',
                params: this.bankId,
                resource: 'bank'
            },
            {
                crmModule: 'audit-logs',
                params: { entity_type: 'nacha_transaction', entity_id: this.id },
                resource: 'auditLog'
            },
            {
                crmModule: 'transactions',
                params: { batchTransactionId: this.id },
                resource: 'transaction'
            },
            {
                crmAlias: 'Currency Transfer',
                crmModule: 'currency-transfers',
                params: { reference_id: this.id },
                resource: 'currencyTransfer',
                hidden: this.nachaTransactionUniqueId.includes('N')
            },
            {
                crmAlias: 'Batch Transaction Issues',
                crmModule: 'firm-issues',
                params: { batchTransactionId: this.id },
                resource: 'firmIssue'
            },
        ]);
    }

    get detailsDisplayName(): string {
        return `Batch Transaction: ${this.nachaTransactionUniqueId}`;
    }

    static filterFields(): Field[] {
        return modelConfig([
            {
                // this field is hidden in filter
                type: 'input',
                name: 'firmId',
                hidden: true,
                cssClasses: 'hidden'
            },
            {
                // this field is hidden in filter
                type: 'input',
                name: 'nachaFileId',
                hidden: true,
                cssClasses: 'hidden'
            },
            {
                // this field is hidden in filter
                type: 'input',
                name: 'bankId',
                hidden: true,
                cssClasses: 'hidden'
            },
            {
                type: 'input',
                name: 'nachaTransactionUniqueId',
                label: 'Find by Unique ID',
                cssClasses: 'col-sm-4 min-width-200'
            },
            {
                type: 'select',
                name: 'nachaCategory',
                label: 'Filter by category',
                cssClasses: 'col-sm-4 min-width-200',
                options: {
                    // PAYMENT: 'PAYMENT',
                    // PAYOUT: 'PAYOUT',
                    // FEE: 'FEE',
                    BATCH_FEES: 'BATCH_FEES',
                    BATCH_PAYOUTS: 'BATCH_PAYOUTS',
                    // REFUND_DEDUCTION: 'REFUND_DEDUCTION',
                    // REFUND_DISBURSE: 'REFUND_DISBURSE',
                    // CREDIT: 'CREDIT',
                    // CHARGEBACK: 'CHARGEBACK',
                    // FEE_TRANSFER: 'FEE_TRANSFER',
                }
            },
            {
                type: 'select',
                name: 'status',
                label: 'Find by status',
                cssClasses: 'col-sm-4 min-width-200',
                options: {
                    url: '/nacha-transactions/statuses'
                }
            },
            {
                label: 'From',
                type: 'datepicker',
                name: 'createdFrom',
                cssClasses: 'filter-date-input',
            },
            {
                label: 'To',
                type: 'datepicker',
                name: 'createdTo',
                cssClasses: 'filter-date-input',
            }
        ]);
    }
}
