import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';

import {Field, List} from '../../../index';
import { ListFilterValues, ListFilterValue } from '../list.interfaces';
import { LIST_FILTER_EMPTY_VALUE } from '../list.constants';
import { combineLatest, Observable } from 'rxjs';
import { map, distinctUntilChanged, startWith, debounceTime, skip } from 'rxjs/operators';

@Component({
    selector: 'aw-list-filter',
    templateUrl: './list-filter.component.html',
    styles: [`
    .mat-mdc-form-field {
      font-size: 14px;
      width: 100%;
    }
    ::ng-deep aw-list-filter aw-form-checkbox .dynamic-field {
        padding-bottom: 0px;
    }
    ::ng-deep aw-list-filter aw-form-checkbox label.mat-checkbox-layout .mat-checkbox-label {
        color: gray;
        font-size: 14px;
        margin-right: 20px;
    }
  `]
})
export class ListFilterComponent implements OnInit {

    @Input() list: List;
    @Input() filterFields: Field[] = [];

    // ListFilterComponent emits filterValuesChanged event when filter values change
    @Input() filterValues: ListFilterValues = {};
    @Output() filterValuesChanged: EventEmitter<ListFilterValues> = new EventEmitter();

    filterForm: FormGroup;

    constructor(
        private fb: FormBuilder,
        protected route: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.filterForm = this.createFilterForm();
        this.getFilterFormObservable().subscribe(values => this.onFilterFormValuesChanged(values));
        this.showHideFields(this.filterValues);
    }

    get visibleFieldNames(): string[] {
        return this.filterFields ? this.filterFields.filter(field => !field.hidden).map(f => f.name) : [];
    }

    get hiddenFieldNames(): string[] {
        return this.filterFields ? this.filterFields.filter(field => field.hidden).map(f => f.name) : [];
    }

    get isFiltered(): boolean {
        return !!Object.values(this.filterValues).find(
            value => value !== undefined && value !== LIST_FILTER_EMPTY_VALUE
        );
    }

    /**
     * When user resets filters - we reset only visible fields
     * (fields which were changed by the user)
     */
    onReset(): void {
        const newFilterValues: ListFilterValues = {};
        this.filterFields.forEach((field) => {
            if (field.hidden) {
                newFilterValues[field.name] = this.filterValues[field.name];
            } else {
                newFilterValues[field.name] = LIST_FILTER_EMPTY_VALUE;
            }
        });
        this.filterForm.patchValue(newFilterValues);
    }

    /**
     * This function is called when filter form values change
     * @param newFilterValues - filter form values
     */
    private onFilterFormValuesChanged(newFilterValues: ListFilterValues): void {
        this.showHideFields(newFilterValues);
        this.emitFilterValues(newFilterValues);
    }

    /**
     * Shows/hides list filter fields in cases when some fields depend on others
     * For example, on Transactions page field subCategory is shown only if category is FEE
     */
    private showHideFields(filterValues: ListFilterValues): void {
        if (!this.filterFields) return;

        this.filterFields.forEach(field => {
            if (field.hideIf) {
                if (field.hideIf(filterValues)) {
                    delete filterValues[field.name];
                    field.cssClasses = 'hidden';
                } else {
                    field.cssClasses = '';
                }
            }
        });
    }

    /**
     * Emit new filter values
     * @param newFilterValues
     */
    private emitFilterValues(newFilterValues: ListFilterValues) {
        this.filterValues = newFilterValues;
        // important: we don't emit filter values if filter form is invalid
        if (!this.filterForm.invalid) {
            this.filterValuesChanged.next(newFilterValues);
        }
    }

    /**
     * Creates form group when list-filter component is loaded
     */
    private createFilterForm(): FormGroup {
        const filterForm = this.fb.group({});

        if (this.filterFields) {
            this.filterFields.forEach((field: Field) => {
                filterForm.addControl(field.name, this.fb.control({
                    value: this.filterValues[field.name] || LIST_FILTER_EMPTY_VALUE,
                    disabled: false
                }));
            });
        }

        return filterForm;
    }

    /**
     * Returns an observable which emits new filterValues when filter form values change
     */
    private getFilterFormObservable(): Observable<ListFilterValues> {

        const fieldNames: string[] = this.filterFields.map(field => field.name);

        const fieldObservables: Observable<ListFilterValue>[] = this.filterFields.map(
            (field) => this.getFieldObservable(field)
        );

        const reduceFieldValues = (values: ListFilterValue[]): ListFilterValues => fieldNames.reduce(
            (acc, fieldName, i) => {
                acc[fieldName] = values[i] || LIST_FILTER_EMPTY_VALUE;
                return acc;
            }, {});

        return combineLatest(fieldObservables).pipe(
            // convert array of values to the object
            map(fieldValues => reduceFieldValues(fieldValues)),
            // skip initial values
            skip(1),
            // debounce is necessary here because when we do "reset" we change multiple fields at the same time
            // and to not bomb backend with many requests we debounce for 10msec
            debounceTime(10)
        );
    }

    /**
     * Returns an observable which emits field value changes.
     * We apply debounce to text inputs so that we don't bomb backend with requests while user is typing
     */
    private getFieldObservable(field: Field): Observable<ListFilterValue> {
        const fieldObservable = this.filterForm.get(field.name).valueChanges.pipe(
            // start with empty value
            startWith(this.filterValues[field.name] || LIST_FILTER_EMPTY_VALUE),
            // convert dates to strings
            map(value => value instanceof Date ? value.toISOString() : value),
            // don't emit duplicates
            distinctUntilChanged(),
        );
        if (field.type === 'input') {
            // we add debounce on fields of type "input"
            return fieldObservable.pipe(debounceTime(1000));
        }
        return fieldObservable;
    }
}
