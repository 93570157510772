import { Injectable } from '@angular/core';
import { of as emptyObservable, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
    AuthenticationDetails,
    CognitoUserPool,
    CognitoUser,
    CognitoUserSession,
    CognitoRefreshToken, CognitoUserAttribute
} from 'amazon-cognito-identity-js';

import { environment as environmentLocal } from '@environment';

interface RawUserAttributes {
  email_verified?: boolean;
  phone_number_verified?: boolean;
  phone_number?: string;
  email?: string;
  sub?: string;
}

@Injectable()
export class CognitoService {

    private userPool: CognitoUserPool = new CognitoUserPool({
        UserPoolId: environmentLocal.AWS.UserPoolId,
        ClientId: environmentLocal.AWS.ClientId
    });
    private savedCognitoUser: CognitoUser;

    /**
   * Login
   */
    login(username: string, password: string): Observable<any> {
        const authDetails = new AuthenticationDetails({
            Username: username,
            Password: password
        });
        const cognitoUser: CognitoUser = new CognitoUser({
            Username: username,
            Pool: this.userPool
        });

        return new Observable(observer => {
            cognitoUser.authenticateUser(authDetails, {
                onSuccess() {
                    observer.next();
                    observer.complete();
                },
                onFailure(err: Error) {
                    observer.error(err);
                    observer.complete();
                },
                newPasswordRequired() {
                    // we dont get an error object back here, so construct our own
                    observer.error({ code: 'NewPasswordRequired', message: 'New password required.' });
                    observer.complete();
                },
                mfaRequired: (challengeName, challengeParameters) => {
                    this.savedCognitoUser = cognitoUser;

                    // we dont get an error object back here, so construct our own
                    observer.error({ code: 'MFARequired', message: 'SMS verification code sent' });
                    observer.complete();
                }
            });
        });
    }

    /**
   * Change password for authenticated user
   */
    changePassword(username: string, password: string, newPassword: string): Observable<any> {
        const authDetails = new AuthenticationDetails({
            Username: username,
            Password: password
        });
        const cognitoUser: CognitoUser = new CognitoUser({
            Username: username,
            Pool: this.userPool
        });

        const cognitoService = this;

        return new Observable(observer => {
            cognitoUser.authenticateUser(authDetails, {
                onSuccess() {
                    cognitoUser.getUserAttributes((err, attributes: CognitoUserAttribute[]) => {
                        observer.next(attributes);
                        observer.complete();
                    });
                },
                onFailure(err: Error) {
                    observer.error(err);
                    observer.complete();
                },
                mfaRequired(challengeName: any, challengeParameters: any) {
                    cognitoService.savedCognitoUser = cognitoUser;

                    // we dont get an error object back here, so construct our own
                    observer.error({ code: 'MFARequired', message: 'SMS verification code sent' });
                    observer.complete();
                },
                newPasswordRequired(rawUserAttributes, requiredAttributes) {
                    // the api doesn't accept these fields back
                    const { email_verified, phone_number, phone_number_verified, ...userAttributes } = rawUserAttributes;
                    userAttributes.email = username;

                    // passing 'this' just uses the same onSuccess and onFailure callbacks
                    cognitoUser.completeNewPasswordChallenge(newPassword, userAttributes, {
                        onSuccess() {
                            cognitoUser.getUserAttributes((err, attributes: CognitoUserAttribute[]) => {
                                observer.next(attributes);
                                observer.complete();
                            });
                        },
                        onFailure(err: Error) {
                            observer.error(err);
                            observer.complete();
                        },
                        mfaRequired(challengeName: any, challengeParameters: any) {
                            cognitoService.savedCognitoUser = cognitoUser;

                            // we dont get an error object back here, so construct our own
                            observer.error({ code: 'MFARequired', message: 'SMS verification code sent' });
                            observer.complete();
                        },
                    });
                }
            });
        });
    }

    /**
   * Initiate password reset for unauthenticated user
   */
    resetPassword(username: string): Observable<any> {
        const cognitoUser: CognitoUser = new CognitoUser({
            Username: username,
            Pool: this.userPool
        });

        return new Observable(observer => {
            cognitoUser.forgotPassword({
                onSuccess: function (result) {
                    observer.next();
                    observer.complete();
                },
                onFailure: function (err: Error) {
                    observer.error(err);
                    observer.complete();
                },
                inputVerificationCode() {
                    observer.error({ code: 'VerificationRequired', message: 'Email verification code sent' });
                    observer.complete();
                }
            });
        });
    }

    /**
   * Complete password reset for unauthenticated user
   */
    confirmResetPassword(username: string, verificationCode: string, newPassword: string): Observable<any> {
        const cognitoUser: CognitoUser = new CognitoUser({
            Username: username,
            Pool: this.userPool
        });
        const authDetails = new AuthenticationDetails({
            Username: username,
            Password: newPassword
        });

        return new Observable(observer => {
            cognitoUser.confirmPassword(verificationCode, newPassword, {
                onSuccess: function () {
                    observer.next();
                    observer.complete();
                },
                onFailure: function (err: Error) {
                    observer.error(err);
                    observer.complete();
                }
            });
        });
    }

    /**
   * Logout
   */
    logout(): void {
        const user: CognitoUser = this.userPool.getCurrentUser();
        if (user) {
            user.signOut();
        }
    }

    /**
   * sendMFACode
   */
    sendMFACode(verificationCode: string): Observable<any> {
        return new Observable(observer => {
            this.savedCognitoUser.sendMFACode(verificationCode, {
                onSuccess: function () {
                    observer.next();
                    observer.complete();
                },
                onFailure: function (err: Error) {
                    observer.error(err);
                    observer.complete();
                }
            });
        });
    }

    /**
   * Check if session needs to be refreshed
   */
    hasToBeRefreshed(): Observable<boolean > {
        return this.session()
            .pipe(map((session: CognitoUserSession) => session.isValid()));
    }

    /**
   * Refresh session
   */
    refreshSession(): Observable<CognitoUserSession> {
        return this.session()
            .pipe(switchMap((session: CognitoUserSession) => this.refreshUserSession(session.getRefreshToken())));
    }

    /**
   * Get access token
   */
    accessToken(): Observable<string> {
        return this.session()
            .pipe(map((session: CognitoUserSession) => session ? session.getAccessToken().getJwtToken() : null));
    }

    /**
   * Get cognito sub
   */
    sub(): Observable<string> {
        return this.session()
            .pipe(map((session: CognitoUserSession) => session ? session.getAccessToken().decodePayload().sub : null));
    }

    /**
   * Refresh session with given refreshToken
   */
    private refreshUserSession(refreshToken: CognitoRefreshToken): Observable<CognitoUserSession> {
        const user: CognitoUser = this.userPool.getCurrentUser();

        return new Observable(observer => {
            user.refreshSession(refreshToken, (err?, session?: CognitoUserSession) => {
                if (err) {
                    observer.error(err);
                } else {
                    observer.next(session);
                }
                observer.complete();
            });
        });
    }

    /**
   * Get cognito session
   */
    private session(): Observable<CognitoUserSession> {
        const user: CognitoUser = this.userPool.getCurrentUser();

        if (!user) {
            return emptyObservable(null); // empty observable
        }

        return new Observable(observer => {
            user.getSession((err: Error, session: CognitoUserSession) => {
                if (err) {
                    observer.error(err);
                } else {
                    observer.next(session);
                }
                observer.complete();
            });
        });
    }

}
