import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment as environmentLocal } from '@environment';
import { Observable } from 'rxjs';

interface HpPaymentCronParams {
    banking_partner_id: string;
    currency_transfer_categories: string[];
}

@Injectable()
export class CronJobsService {
    protected apiRoot = `${environmentLocal.apiUrl}/transactions`;

    constructor(protected http: HttpClient) {}

    runLegacyApiCron(name: string): Observable<object> {
        return this.http.post<object>(`${environmentLocal.apiUrl}/cron-jobs/run-legacy-api-cron`, {
            name: name
        });
    }

    runHpPaymentsCron(params: HpPaymentCronParams): Observable<object> {
        return this.http.post<object>(`${environmentLocal.apiUrl}/cron-jobs/run-hp-payments-cron`, params);
    }
}
