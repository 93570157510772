import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment as environmentLocal} from '@environment';
import { FileWithUploadProgress } from '@app/shared';
import { Observable } from 'rxjs';
import { mergeMap, map } from 'rxjs/operators';
import axios from 'axios';

/**
 * In admin panel we connect javascript from https://static.headnotepayments.com/js/hp-file-upload.js
 * to upload partner files and images to S3 directly from browser.
 * in the same way as partners do it.
 * That javascript creates global object Window.HPFileUpload with this interface:
 */
export interface HPFileUpload {
    loadResources: () => Promise<void>,
    getSignatureParams: (string) => Promise<object>,
    generateFileName: (string) => string
}

type UploadProgressCallback = (number) => void;

@Injectable()
export class FileUploadService {
    protected partnerUploadsUrl = environmentLocal.partnerUploadsUrl;

    constructor(protected http: HttpClient) {}

    get HPFileUpload(): HPFileUpload {
        return (window as any).HPFileUpload;
    }

    /**
     * Loads hp-file-upload.js javascript and makes all necessary initializations
     */
    initHPFileUpload() {
        const hpFileUploadURL = environmentLocal.staticResourcesURL + '/js/hp-file-upload.js';

        // load HPFileUpload script and all necessary resources
        this.loadScript(hpFileUploadURL, () => {
            this.HPFileUpload.loadResources();
        });
    }

    /**
     * Uploads file to partner-uploads S3 bucket using HPFileUpload
     * HPFileUpload is deployed to static.headnotepayments.com
     */
    uploadFileToS3(partnerId: string, file: FileWithUploadProgress): Observable<string> {
        // generate file name (it's currently timestamp + extension)
        const newFileName = this.HPFileUpload.generateFileName(file.name);

        return this.getPartnerFileUploadToken(partnerId).pipe(
            mergeMap((partnerFileUploadToken) => this.getUploadSignatureParams(partnerFileUploadToken)),
            mergeMap((signatureParams) => {
                const formData = new FormData();
                formData.append('success_action_status', '200');
                formData.append('key', signatureParams['key']);
                formData.append('Policy', signatureParams['policy']);
                formData.append('acl', signatureParams['acl']);
                formData.append('X-Amz-Date', signatureParams['x-amz-date']);
                formData.append('X-Amz-Credential', signatureParams['x-amz-credential']);
                formData.append('X-Amz-Algorithm', signatureParams['x-amz-algorithm']);
                formData.append('X-Amz-Signature', signatureParams['x-amz-signature']);
                formData.append('file', file, newFileName);

                return this.uploadFormToS3(formData, (progress) => {
                    // we update uploadProgress on the file so that progress is rendered
                    file.uploadProgress = progress;
                });
            })
        );
    }

    checkFileSize(file: File, maxSizeInBytes: number): boolean {
        const fileSize = file.size || 0;
        return fileSize <= maxSizeInBytes;
    }

    checkFileExt(file: File, expectedExtension: string): boolean {
        const fileName = file.name;
        const fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1);

        return fileExtension.toLocaleLowerCase() === expectedExtension.toLocaleLowerCase();
    }

    private loadScript(scriptURL: string, callback: () => void): void {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = scriptURL;
        script.onload = callback;
        document.getElementsByTagName('head')[0].appendChild(script);
    }

    /**
     * Get token for file upload for the given partnerId
     */
    private getPartnerFileUploadToken(partnerId: string): Observable<string> {
        return this.http.get<{ token: string }>(`${environmentLocal.apiUrl}/partners/${partnerId}/file-upload-token`).pipe(
            map(data => data.token)
        );
    }

    /**
     * Get signature params which allow to upload file directly from browser to S3
     */
    private getUploadSignatureParams(partnerFileUploadToken: string): Observable<object> {
        return new Observable(observer => {
            this.HPFileUpload.getSignatureParams(partnerFileUploadToken).then((signatureParams) => {
                observer.next(signatureParams);
                observer.complete();
            });
        });
    }

    /**
     * Upload file to S3 and return full file path (partnerId/filename.extension)
     */
    private uploadFormToS3(formData: FormData, progressCallback?: UploadProgressCallback): Observable<string> {
        return new Observable((observer) => {
            axios.request({
                url: this.partnerUploadsUrl,
                method: 'POST',
                onUploadProgress: (event) => {
                    if (progressCallback) {
                        progressCallback(Math.floor(100 * event.loaded / event.total));
                    }
                },
                data: formData
            }).then(() => {
                const s3key = (formData.get('key') as string);
                const fileName = (formData.get('file') as File).name;
                const s3filename = s3key.replace('${filename}', fileName);

                observer.next(s3filename);
                observer.complete();
            });
        });
    }
}
