import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { AuthService } from 'ngx-auth';
import { AccessControl, Permission } from 'accesscontrol';

import { CognitoService } from './cognito.service';
import { environment as environmentLocal } from '@environment';

export interface AdminProfile {
  email: string;
  phone?: string;
  mfaEnabled?: boolean;
  firstName: string;
  lastName: string;
  id: string;
  headnoteUserId: string;
  headnoteFirmId: string;
  permissions: object;
  role: string;
  roleId: string;
  createdAt: string;
}

export type OperationType = 'readAny' | 'updateAny';

@Injectable()
export class AuthenticationService implements AuthService {
    profile: AdminProfile;
    private apiRoot = `${environmentLocal.apiUrl}/users`;
    private userEmail: string;

    constructor(
    private cognitoService: CognitoService,
    private http: HttpClient
    ) {}

    /**
   * Check, if user already authorized.
   */
    isAuthorized(): Observable<boolean> {
        return this.cognitoService
            .accessToken()
            .pipe(
                map(token => !!token),
                catchError((e) => {
                    this.logout();

                    return throwError(() => e);
                })
            );
    }

    /**
   * Get access token.
   */
    getAccessToken(): Observable<string> {
        return this.cognitoService.accessToken()
            .pipe(catchError((e) => {
                this.logout();

                return throwError(() => e);
            }));
    }

    /**
   * Function, that should perform refresh token verifyTokenRequest
   */
    refreshToken(): Observable<any> {
        return this.cognitoService.refreshSession()
            .pipe(
                catchError((e) => {
                    this.logout();

                    return throwError(() => e);
                })
            );
    }

    /**
   * Function, checks response of failed request to determine,
   * whether token be refreshed or not.
   * TODO: check session failure response / status
   */
    refreshShouldHappen(response: HttpErrorResponse): boolean {
        if (response instanceof HttpErrorResponse &&
      response.error.code === 'NotAuthorizedException' &&
      response.error.message === 'Refresh Token has expired') {
            this.logout();

            return false;
        }


        if (response instanceof HttpErrorResponse) {
            return response.status === 401 || (response.status === 400 && response.error.code === 'NotAuthorizedException');
        }

        return false;
    }

    /**
   * Verify that outgoing request is refresh-token,
   * so interceptor won't intercept this request
   *
   * NOTE: refreshing token goes via cognito api, outside of angular,
   * so every request is definitely not a refresh token request
   */
    verifyTokenRequest(): boolean {
        return false;
    }

    /* App Specific */

    /**
   * Signup
   * */
    signUp(data: object): Observable<any> {
        return this.http.post<object>(this.apiRoot, data)
            .pipe(
                tap((profile: AdminProfile) => this.profile = profile),
                catchError((response: HttpErrorResponse) =>
                    throwError(() => new Error(response.error.message))
                )
            );
    }

    /**
   * Login
   */
    login(email: string, password: string): Observable<any> {
        return this.cognitoService.login(email, password);
    }

    /**
   * Logout
   */
    logout(): void {
        this.cognitoService.logout();
        location.reload();
    }

    /**
   * sendMFACode
   */
    sendMFACode(verificationCode: string): Observable<any> {
        return this.cognitoService.sendMFACode(verificationCode);
    }

    /**
   * Change password for authenticated user
   */
    changePassword(email: string, password: string, newPassword: string): Observable<any> {
        return this.cognitoService.changePassword(email, password, newPassword);
    }

    saveEmailTemporary(email: string) {
        this.userEmail = email;
    }

    getTemporaryEmail(): string {
        const email: string = this.userEmail;
        this.userEmail = null;
        return email;
    }

    getCognitoSub(): Observable<string> {
        return this.cognitoService.sub();
    }

    /**
   * Initiate password reset for unauthenticated user
   */
    resetPassword(email: string): Observable<any> {
        return this.cognitoService.resetPassword(email);
    }

    /**
   * Initiate password reset for unauthenticated user
   */
    confirmResetPassword(email: string, verificationCode: string, newPassword: string): Observable<any> {
        return this.cognitoService.confirmResetPassword(email, verificationCode, newPassword);
    }

    getProfile(): Observable<AdminProfile> {
        if (this.profile) {
            return of(this.profile);
        }

        return this.http.get<AdminProfile>(this.apiRoot)
            .pipe(tap((profile: AdminProfile) => this.profile = profile || {} as AdminProfile));
    }

    can(operation: OperationType = 'readAny', resource = '$feature', attributes: string[] = []): boolean {
        if (!this.profile.permissions || !this.profile.role) {
            return false;
        }

        const ac: AccessControl = new AccessControl(this.profile.permissions || {});
        const permission: Permission = ac.can(this.profile.role)[operation](resource);
        const attributesIncluded = attributes ? attributes.every(a =>
            permission.attributes.includes(a)
        ) : true;

        return permission.granted && attributesIncluded;
    }

    getPermissions(): Observable<object> {
        return this.http.get(`${this.apiRoot}/permissions`);
    }

}
