import { formatDate } from '../utils';
import { Model, Resource, modelConfig, Field, modelRelations } from '../data';

export class FraudAlerts extends Model {
    resourceName: Resource = 'fraudAlerts';

    id: string;
    sk: string;
    firm_id: string;
    client_id: string;
    status: string;
    event_name: string;
    alerts: object;
    firm_name: string;
    event_data: object;
    total_risk_score: number;
    updated_at: number;

    constructor(attributes = {}) {
        super(attributes);
        super.update(attributes);


        this.overviewFields = [
            {
                label: 'Created At',
                name: 'sk',
                cssClasses: 'bold',
                transformer: (value) => formatDate(
                    value,
                    'YYYY-MM-DD HH:mm:ss zz'
                ),
            },
            'firm_name',
            'status',
            {
                label: 'Firm ID',
                name: 'firm_id',
                transformer: () => `<a href='/crm/firms/${this.firm_id}'>${this.firm_id}</a>`,
            },
            {
                label: 'Client ID',
                name: 'client_id',
                transformer: () => `<a href='/crm/clients/${this.client_id}'>${this.client_id}</a>`,
            },
            {
                label: 'Updated At',
                name: 'updated_at',
                transformer: (value) => formatDate(
                    value,
                    'YYYY-MM-DD HH:mm:ss zz'
                ),
            },
            {
                label: 'Total Risk Score',
                name: 'total_risk_score'
            },
            {
                hideLabel: true,
                name: 'alerts',
                transformer: (value: []) => {
                    if (!value || value.length === 0) return;

                    const titlesFragment = `
                        <tr>
                            <th class="fraud-alert-eventData-table-cell">Rule Name</th>
                            <th class="fraud-alert-eventData-table-cell">Alert Description</th>
                            <th class="fraud-alert-eventData-table-cell">Alert Start Date</th>
                            <th class="fraud-alert-eventData-table-cell">Risk Score</th>
                        </tr>
                    `;

                    const getAlertsFragment = ({
                        rule,
                        description,
                        risk_score,
                        datetime,
                    }) => `
                        <tr class="fraud-alert-eventData-table-line">
                            <td class="fraud-alert-eventData-table-cell">${rule}</td>
                            <td class="fraud-alert-eventData-table-cell fraud-alert-eventData-table-cell__width30">${description}</td>
                            <td class="fraud-alert-eventData-table-cell">${
    formatDate(
        datetime,
        'YYYY-MM-DD HH:mm:ss zz'
    )
}</td>
                            <td class="fraud-alert-eventData-table-cell">${risk_score}</td>

                        </tr>
                    `;

                    const reducer = (prev, current) => {
                        const { rule, description, event_data: eventData, risk_score } = current;
                        const params = { rule, description, risk_score, datetime: eventData._metadata.datetime };
                        const htmlFragment = getAlertsFragment(params);
                        return prev + htmlFragment;
                    };

                    return `
                        <div class="semibold">Events:</div>
                        <div class="fraud-alert-eventData-table-container">
                            <div>
                                <table class="fraud-alert-eventData-table">
                                    <thead>
                                        ${titlesFragment}
                                    </thead>
                                    <tbody>
                                        ${value.reduce(reducer, '')}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    `;
                },
                cssClasses: 'overflow-auto',
            }
        ];
        this.config = modelConfig([
            {
                type: 'input',
                name: 'id',
                readonly: true,
            },
            {
                type: 'input',
                name: 'sk',
                readonly: true,
            },
            {
                type: 'input',
                name: 'firm_id',
                readonly: true,
            },
            {
                type: 'input',
                name: 'client_id',
                readonly: true,
            },
            {
                type: 'input',
                name: 'firm_name',
                readonly: true,
            },
            {
                type: 'select',
                name: 'status',
                options: ['ACTIVE', 'CLOSED'],
            },
            {
                type: 'input',
                name: 'updated_at',
                readonly: true,
            },
        ]);
        this.relations = modelRelations([
            {
                crmModule: 'firms',
                crmAlias: 'firm',
                params: this.firm_id,
                resource: 'firm',
            },
            {
                crmModule: 'clients',
                crmAlias: 'client',
                params: this.client_id,
                resource: 'client'
            },
            {
                crmModule: 'firm-settings',
                params: this.firm_id,
                resource: 'firmSettings',
            },
            {
                crmModule: 'transactions',
                crmAlias: 'firmTransactions',
                params: { firmId: this.firm_id },
                resource: 'transaction',
            },
            {
                crmModule: 'audit-logs',
                params: { entity_type: 'fraud_alerts', entity_id: this.sk },
                resource: 'auditLog'
            },
        ]);
    }

    get detailsDisplayName(): string {
        return `Fraud Alert: ${this.sk}`;
    }

    static filterFields(): Field[] {
        return modelConfig([

            {
                type: 'input',
                name: 'firmId',
                hidden: true,
                cssClasses: 'hidden'
            },
            {
                type: 'select',
                label: 'Filter by fraud alert status',
                name: 'status',
                cssClasses: 'col-sm-4 min-width-200',
                options: {
                    '': 'All types',
                    ACTIVE: 'ACTIVE',
                    CLOSED: 'CLOSED',
                },
                defaultValue: 'ACTIVE',
            },
        ]);
    }
}
