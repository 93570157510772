import { Component, OnInit } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';
import { FileUploader } from 'ng2-file-upload';

import { Field, FieldImage } from '../../../../data';
import { getFileName } from '../../../../utils';
import { FILE_UPLOADER_CONTROL_PREFIX } from './form-image.constants';

@Component({
    selector: 'aw-form-image',
    template: `
    <div class="dynamic-field form-input" class="{{ field.cssClasses }}"
      [formGroup]="group">
      <div class="mat-form-field mat-form-field-wrapper">
        <span class="mat-form-field-label-wrapper mat-form-field-appearance-legacy">
          <label class="mat-form-field-label mat-empty mat-form-field-empty">
            {{ field.label }}
          </label>
        </span>

        <div class="margin-top-30" *ngIf="!currentValue && !uploadedFileName">
          <p>
              Not uploaded yet
          </p>
        </div>

       <div class="margin-top-30" *ngIf="currentValue && !uploadedFileName">
           <aw-file-preview [src]="currentValue"></aw-file-preview>
        </div>

        <div class="margin-top-30" *ngIf="uploadedFileName">
            <p>
                Just selected: <b>{{ uploadedFileName }}</b><br/><br/>
                <span *ngIf="uploadedFileName" class="image-upload-warning">
                  Please, click "Save changes" to store this new file to the server.
              </span>
            </p>
        </div>

        <button type="button"
          mat-stroked-button
          (click)="file.click()"
          *ngIf="!fileUploaderControl.disabled"
        >
          <span class="margin-right-20">{{ uploadButtonText }}</span>
          <mat-icon *ngIf="!uploadedFileName">cloud</mat-icon>
          <mat-icon *ngIf="uploadedFileName" color="accent">cloud_done</mat-icon>
        </button>
        <input type="file"
          ng2FileSelect
          [uploader]="fileUploader"
          [accept]="field.allowedExtensions"
          hidden
          (change)="update()"
          #file/>
      </div>
    </div>
  `,
})
export class FormImageComponent implements OnInit {
    field: FieldImage & Field;
    group: FormGroup;
    fileUploader = new FileUploader({ url: '' });

    ngOnInit() {
        this.group.controls[this.field.name].valueChanges.subscribe((data) => {
            this.fileUploader.clearQueue();
        });
    }

    /**
     * For "image" inputs we create hidden control with name starting with $ sign
     * For example, if form has barCard input, there will be $barCard control
     * These controls emit FileUploader as its value
     */
    get fileUploaderControl(): AbstractControl {
        return this.group.controls[`${FILE_UPLOADER_CONTROL_PREFIX}${this.field.name}`];
    }

    get currentValue(): string {
        return this.group.value[this.field.name];
    }

    get fileName(): string {
        return getFileName(this.currentValue);
    }

    get isPdf(): boolean {
        return this.fileName && this.fileName.toLowerCase().indexOf('.pdf') !== -1;
    }

    get uploadedFileName(): string {
        if (this.lastUploadedFile) {
            return this.lastUploadedFile.name;
        }
        return null;
    }

    get lastUploadedFile(): File {
        if (this.fileUploader.queue.length > 0) {
            return this.fileUploader.queue[this.fileUploader.queue.length - 1]._file;
        }
        return null;
    }

    get uploadButtonText(): string {
        if (this.uploadedFileName) {
            return 'Upload another image';
        }
        if (this.currentValue) {
            return 'Upload new image';
        }
        return 'Upload image';
    }

    update() {
        this.fileUploaderControl.patchValue(this.lastUploadedFile);
        this.fileUploaderControl.markAsDirty();
    }
}
