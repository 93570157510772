import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SUPERADMIN_ROLE, ADMIN_ROLE, DynamicFormValues } from '@app/shared';

import { AuthenticationService } from '../../authentication';
import { Relation, Model } from '../../data';

// There's no need to export this, but without export I get error
// " Reference to a non-exported function."
export function relationsDropdown(label, dropdownRelations = 'relations', classNames = '') {
    return `<button mat-button class="${classNames}"
      [matMenuTriggerFor]="relationsMenu"
      *ngIf="model && ${dropdownRelations} && ${dropdownRelations}.length">
      <mat-icon>share</mat-icon> ${label}
    </button>
    <mat-menu #relationsMenu="matMenu">
      <a mat-menu-item
        *ngFor="let relation of ${dropdownRelations}"
        [routerLink]="relation.routerLink">
        {{relation.name}}
      </a>
    </mat-menu>`;
}

@Component({
    selector: 'aw-details',
    template: `
    <aw-page-heading>
      <h1>{{model && model.detailsDisplayName}}</h1>
      <div class="actions d-flex flex-row">
        <ng-content select=".actions"></ng-content>
        ${relationsDropdown('Relations')}
      </div>
    </aw-page-heading>

    <div *ngIf="!model" class="p-5">
      <mat-spinner></mat-spinner>
    </div>

    <mat-tab-group [selectedIndex]="selectedTab" *ngIf="model">
      <mat-tab label="Overview">
        <div class="panel-body">
          <mat-card class="transition">
          <div class="row">
            <div class="col-6 border-right">
              <aw-overview [model]="model"></aw-overview>
              <ng-content select=".action-buttons"></ng-content>
              <button mat-button class="mat-button-dark-border"
                *ngFor="let relation of primaryRelations"
                [routerLink]="relation.routerLink"
                [disabled]="relation.disabled">
                {{relation.name}}
                <span *ngIf="relation.count">({{relation.count}})</span>
              </button>
            </div>
            <div class="col-6"
              *awIfPermissions="{resource: '$feature', attributes: ['notes']}">
              <aw-notes [model]="model"></aw-notes>
            </div>
          </div>
          </mat-card>
        </div>
      </mat-tab>
      <mat-tab *ngIf="isSuperadmin" label="Edit">
        <div class="panel-body">
          <mat-card class="transition">
            <div class="mt-3"></div>
            <aw-dynamic-form
              *ngIf="model"
              [model]="model"
              [additionalFields]="false"
              [isLoading]="isSaving"
              (submitted)="save.emit($event)"
              (fieldValueChanged)="fieldValueChanged.emit($event)">
            </aw-dynamic-form>
          </mat-card>
        </div>
      </mat-tab>
      <mat-tab *ngIf="isSuperadmin && !hideDetails" label="{{detailsTitle}}">
        <div class="panel-body">
          <mat-card class="transition">
            <div class="mt-3"></div>
            <aw-dynamic-form
              *ngIf="model"
              [model]="model"
              [additionalFields]="true"
              [isLoading]="isSaving"
              (submitted)="save.emit($event)"
              (fieldValueChanged)="fieldValueChanged.emit($event)">
            </aw-dynamic-form>
          </mat-card>
        </div>
      </mat-tab>
    </mat-tab-group>
  `
})
export class DetailsComponent implements OnInit {
    _detailsDisplayName: string;
    @Input() model: Model;
    @Input() detailsTitle = 'Details';
    @Input() relationCounts: Model;
    @Input() isSaving = false;
    @Input() hideDetails = false;
    @Output() save: EventEmitter<DynamicFormValues> = new EventEmitter<DynamicFormValues>();
    @Output() saveSpecials: EventEmitter<object> = new EventEmitter<object>();
    @Output() fieldValueChanged: EventEmitter<object> = new EventEmitter<object>();

    selectedTab = 0;
    isSuperadmin = false;

    constructor(
        private route: ActivatedRoute,
        private authenticationService: AuthenticationService
    ) { }

    ngOnInit() {
        this.isSuperadmin = this.authenticationService.profile.role === SUPERADMIN_ROLE
            || this.authenticationService.profile.role === ADMIN_ROLE;

        const tab = this.route.snapshot.params.tab;

        this.selectedTab = tab === 'data' ? 1 : 0;
    }

    get relations(): Relation[] {
        if (this.model) {
            return (this.model.relations || [])
                .filter((relation: Relation) => !this.isHidden(relation, this.model) &&
                    relation.routerLink &&
                    relation.routerLink[1] &&
                    this.authenticationService.can('readAny', relation.resource)
                ).map((relation: Relation) => {
                    relation.disabled = this.isDisabled(relation, this.model);
                    return relation;
                });
        }
    }

    get detailsDisplayName() {
        return this._detailsDisplayName;
    };

    isHidden(relation, model) {
        if (relation.hidden === true) {
            return true;
        }
        if (typeof relation.hidden === 'function') {
            return relation.hidden(model);
        }
        return false;
    }
    isDisabled(relation, model) {
        if (relation.disabled === true) {
            return true;
        }
        if (typeof relation.disabled === 'function') {
            return relation.disabled(model);
        }
        return false;
    }

    /**
     * Returns true if model has less than 3 relations
     * then we consider all relations as primary
     */
    hasNoManyRelations() {
        return this.relations.length <= 30;
    }

    /**
     * Gets relations which we show as buttons
     */
    get primaryRelations(): Relation[] {
        if (this.hasNoManyRelations()) {
            return this.relations; // all are primary
        }
        const primaryRelations = this.model.primaryRelations || [];
        return this.relations.filter((r: Relation) => primaryRelations.includes(r.resource))
            .sort((a, b) => primaryRelations.indexOf(a.resource) - primaryRelations.indexOf(b.resource));
    }

    /**
     * Gets relations which we show in dropdown
     */
    get secondaryRelations(): Relation[] {
        if (this.hasNoManyRelations()) {
            return []; // all are primary
        }
        const primaryRelations = this.model.primaryRelations || [];
        return this.relations.filter((r: Relation) => !primaryRelations.includes(r.resource));
    }
}
