import { Component, OnInit } from '@angular/core';
import { FileUploadService } from '@app/shared';

@Component({
    selector: 'aw-root',
    providers: [FileUploadService],
    template: `
    <router-outlet></router-outlet>
    <aw-loading></aw-loading>
  `
})
export class AppComponent implements OnInit {

    constructor(protected fileUploadService: FileUploadService) {}

    ngOnInit(): void {
        this.fileUploadService.initHPFileUpload();
    }
}
