import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, mergeMap, startWith } from 'rxjs/operators';
import { NgEventBus } from '@lib/eventbus';

import { Field } from '../../../../data';
import { AuthenticationService } from '../../../../authentication/authentication.service';
import { EVENT_BUS_EVENTS } from '@app/shared';

interface FormAutocompleteOption {
    value: number | string;
    label: string;
}

@Component({
    selector: 'aw-form-autocomplete',
    styleUrls: ['./form-autocomplete.component.scss'],
    template: `
        <div class="dynamic-field form-autocomplete" class="{{ field.cssClasses }}"
            [formGroup]="group">
            <mat-form-field class="custom_form__field" appearance="fill">
                <mat-label>{{field.label}}</mat-label>
                <input type="text"
                    matInput
                    #searchAutocomplete
                    (keydown.enter)="patchValue(searchAutocomplete.value);$event.stopPropagation();false"
                    [formControlName]="field.name"
                    [matAutocomplete]="auto">
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" [autoActiveFirstOption]="autoActiveFirstOption">
                    <mat-option *ngFor="let option of filteredOptions | async" [value]="option.value">
                        {{option.label}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
            <mat-icon *ngIf="!isLoading">search</mat-icon>
            <mat-spinner *ngIf="isLoading" diameter="24"></mat-spinner>
        </div>
    `,
})
export class FormAutocompleteComponent implements OnInit {
    field: Field;
    group: FormGroup;
    getOptions: Function;
    options: FormAutocompleteOption[] = [];
    filteredOptions: Observable<FormAutocompleteOption[]>;
    isLoading = false;
    lastSearchedValue = '';
    autoActiveFirstOption = true;
    cancelSearchRequest: Function;

    @ViewChild('searchAutocomplete') searchAutocomplete;

    constructor(
        private authenticationService: AuthenticationService,
        private eventBus: NgEventBus
    ) { }

    ngOnInit() {
        const selectedValue = this.group.controls[this.field.name].value;

        // initialize value
        if (selectedValue) {
            setTimeout(() => {
                this.searchAutocomplete.nativeElement.value = selectedValue;
            }, 0);
        }

        this.filteredOptions = this.group.controls[this.field.name].valueChanges
            .pipe(
                startWith(selectedValue),
                map(value => value || ''),
                mergeMap(searchQuery => this.search(searchQuery))
            );
    }

    setCancelationHandler = (cancelSearchRequest) => {
        this.cancelSearchRequest = cancelSearchRequest;
    };

    async search(q: string): Promise<FormAutocompleteOption[]> {
        const getOptionsAsync = this.field['getOptions'];

        if (this.cancelSearchRequest) {
            this.cancelSearchRequest('Search request canceled');
        }

        if (q.length >= 3 && q !== this.lastSearchedValue) {
            this.isLoading = true;
            const token = await this.authenticationService.getAccessToken().toPromise();
            const result = await getOptionsAsync(token, q, this.setCancelationHandler);
            this.options = result.map(item => ({ value: item.value, label: `${item.label}: ${item.value}` }));
            this.isLoading = false;
        }

        this.lastSearchedValue = q;
        return this.options.slice();
    }

    displayFn = (selectedValue: string): string => {
        this.eventBus.cast(EVENT_BUS_EVENTS.FORM_AUTOCOMPLETE_SELECTED_EVENT, { [this.field.name]: selectedValue });

        const option = this.options.find(({ value }) => value === selectedValue);
        return option ? option.label : '';
    };

    patchValue = (value) => {
        this.group.controls[this.field.name].patchValue(value, { emitModelToViewChange: false });
    };
}
