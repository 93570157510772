import { Model, Resource, modelConfig, modelRelations } from '../data';

export class ClientCard extends Model {
    resourceName: Resource = 'clientCard';

    id: string;
    clientId: string;
    name: string;
    cardProfileId: string;
    createdAt: Date;
    updatedAt: Date;

    client: {
        firstName: string;
        lastName: string;
    };

    cardProfile?: {
        cardDebitCredit: string;
    };

    constructor(attributes = {}) {
        super(attributes);
        super.update(attributes);

        this.overviewFields = [
            'id',
            {
                label: 'Client Name',
                name: 'name',
                transformer: <ClientCard>(value, model) => `<a href='/crm/clients/${model.client.id}'>` +
                        ` ${model.client.firstName} ${model.client.lastName}</a>`
            },
            'name',
            {
                label: 'Card Type',
                name: 'cardProfileId',
                stayVisible: true,
                transformer: <ClientCardProfile>(value, model) => {
                    if (model.cardProfile) {
                        return model.cardProfile.cardDebitCredit;
                    }
                    return `<i>No Card Profile</i>`;
                }
            },
            'archived',
            'createdAt',
            'updatedAt'
        ];
        this.config = modelConfig([
            {
                type: 'input',
                name: 'id',
                readonly: true
            },
            {
                type: 'input',
                name: 'name',
                readonly: true
            },
            {
                type: 'checkbox',
                name: 'archived',
                readonly: true
            },
            {
                type: 'input',
                name: 'createdAt',
                readonly: true,
                additional: true
            },
            {
                type: 'input',
                name: 'updatedAt',
                readonly: true,
                additional: true
            }
        ]);
        this.relations = modelRelations([
            {
                crmModule: 'clients',
                crmAlias: 'client',
                params: this.clientId,
                resource: 'client'
            },
            {
                crmModule: 'audit-logs',
                params: { entity_type: 'client_card', entity_id: this.id },
                resource: 'auditLog'
            },
            {
                crmModule: 'transactions',
                params: { cardId: this.id },
                resource: 'transaction'
            },
            {
                crmModule: 'card-profiles',
                params: this.cardProfileId,
                crmAlias: 'Card Profile',
                resource: 'cardProfile',
                hidden: (model) => model.cardProfileId === null
            }
        ]);
    }

    get detailsDisplayName(): string {
        return `Client Card`;
    }
}
